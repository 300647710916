@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.extend-due-date{
    float: right;
    
    .btn-extend{
      font-size: 16px;
      color: $colorNavyBlue;
      font-weight: 700;
      cursor: pointer;
    }
    .extend-due-date-date-picker{
      width: 0;
      margin-top: -35px;
      input{
        width: 0;   
        border: none !important;     
      }
      div::after, div::before{
        display: none;
      }
    }
  }