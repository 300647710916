@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.intersting-card {
  padding-top: 45px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 24px;
  border-top-left-radius: 0;
  @media screen and (max-width: 424px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  .left-0px {
    left: 0;
    @media screen and (max-width: 767px) {
      right: 0;
    }
  }

  .right-0px {
    right: 0;
    @media screen and (max-width: 767px) {
      left: 0;
    }
  }
  .intersting-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    font-size: 14px;
    padding: 8px 16px 4px 16px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;
    background-color: #4B70A6;
    span {
      line-height: 17px;
    }
  }
  .title-border {
    position: relative;
    margin: 10px 0 30px 0;
    .categories-title {
      font-size: 24px;
      color: #343A40;
      background-color: $colorWhite;
      font-family: HelveticaNeueBold;
    }
  }
  
}
.warning-card{
  padding-top: 27px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 24px;
  border-top-left-radius: 0;
  margin-top: 57px;
  @media screen and (max-width: 424px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  .left-0px {
    left: 0;
    @media screen and (max-width: 767px) {
      right: 0;
    }
  }

  .right-0px {
    right: 0;
    @media screen and (max-width: 767px) {
      left: 0;
    }
  }
  .warning-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    font-size: 14px;
    padding: 8px 16px 4px 16px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;
    background-color: #4B70A6;
    span {
      line-height: 17px;
    }
  }
  .warning-letter-title {
    font-size: 18px;
    font-family: HelveticaNeueMedium;
    color: #343A40;
    margin-bottom: 27px;
    margin-left: 10px;
  }
}
.button {
  border-radius: 28px;
  width: 150px;
  height: 48px;
  margin-top: 30px;
  font-family: HelveticaNeueMedium;
  font-size: 18px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 0;
  padding-left: 0;
}

.save-changes {
  background-color: #1E4C90 !important;
  color: white !important;
  margin-left: 30px;
  border: none !important;
  padding: 14px 24px 9px;
 
}
.back {
  color: #E10000;
  &:hover {
    color: #E10000;
  }
}

.save-btn {
  background-color: #1E4C90;
  color: $colorWhite;
  border-radius: 56px;
  font-family: HelveticaNeueRoman;
  padding: 14px 24px 9px;
  margin-top: 30px;
  &:hover {
    color: $colorWhite;
    background-color: $colorGreen;
  }
  &:focus {
    color: $colorWhite;
    background-color: $colorGreen;
  }
}
.skip-btn {
  color: #1E4C90;
  font-family: HelveticaNeueRoman;
  padding: 8px 15px 4px;
  margin-top: 30px;
  margin-right: 10px;
  &:hover {
    color: $colorDarkBlue;
  }
}

.loading-box {
  height: 46px;
  text-align: center;
  .loading {
    width: 36px;
    height: 36px;
    margin-left: 30px;
    margin-top: 10px;
  }
}

