@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.sort-container {
  .sort-box {
    border-bottom: 1px solid $colorGray2;
    margin-top: 50px;
  }
  .total-result {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.7px;
  }
  .status {
    color: $colorNavyBlue;
    font-size: 14px;
    font-family: HelveticaNeueRoman;
    padding-left: 0;
    &:focus {
      box-shadow: none;
    }
  }
}
