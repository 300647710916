@import "../../../styles/colors.scss";


  .card {
    background-color: $colorWhite;
    border-radius: 8px;
    border-top-left-radius: 0px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    border: 1px solid #D6D8D9;
  }