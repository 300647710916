@import "../../../styles/colors.scss";

.single-input-box {
    height: 83px;
    position: relative;
    display: grid;
}

.single-input {
    font-family: HelveticaNeueRoman;
    height: 41px;
    width: 100%;
    border: none;
    border: 1.5px solid #1E4C90;
    border-radius: 8px;
    padding-left: 52px;
    padding-top: 4px;
    font-size: 16px;

    //  margin-bottom: 35px;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: transparent;
    }
}

.info {
    display: none;
}

.single-input:focus~.single-input-label,
.single-input:not(:placeholder-shown)~.single-input-label {
    top: -7px;
    font-size: 12px;
    color: #1E4C90;
    background-color: $colorWhite;
    pointer-events: visible;
    cursor: pointer;
    left: 52px;

    .info {
        display: inline-block;
    }
}

.single-input:-internal-autofill-selected {
    background-color: $colorWhite;
}

.single-input-label {
    color: #85898c;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 52px;
    top: 10px;
    transition: 300ms ease all;
    background-color: $colorWhite;
    height: 16px;
    padding: 0 5px;
}

.single-input-error {
    font-size: 10px;
    color: $colorred;
}

.toopltip {
    border-radius: 4px;
    color: #fff;
    background-color: #369cbb;
    font-family: HelveticaNeueMedium;
    text-align: center;
    max-width: 500px;
}

.verify-icon {
    color: $colorGreen;
    width: 9px;
    height: 9px;
    position: absolute;
    right: 10px;
    top: 16px;
    background-color: $colorWhite;
}

.social-input-icon{
    position: absolute;
    top: 4px;
    left: 5px;
}