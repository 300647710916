.section-container {
	background: url(../../../assets/images/register-background.svg) no-repeat;
	height: 100%;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;

	& > .form-container {
		width: 793px;
		max-width: 100vw;
		min-height: 523px;
		margin: 76px auto;
		background: rgba(255, 255, 255, 0.5);
		border-width: 1px 0px 0px 1px;
		border-style: solid;
		border-color: rgba(201, 201, 201, 0.3);
		box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;

		& > .title {
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			font-family: HelveticaNeueBold;
			margin-bottom: 40px;
		}
		& .recaptcha{
			margin-bottom: 30px;
			margin-left: -25px;
		}

		& > form {
			width: 259px;
			display: flex;
			flex-direction: column;
			& > .submit {
				background: #1e4c90;
				border-radius: 76px;
				text-align: center;
				color: #fff;
				font-size: 16px;
				font-family: HelveticaNeueBold;
				padding: 14px;
				margin-top: 5px;
				margin-bottom: 50px;
			}

			& > .desc {
				& > span {
					color: #343a40;
					font-size: 16px;
					font-family: HelveticaNeueBold;
				}
				& > a {
					color: #1e4c90;
					font-size: 16px;
					font-family: HelveticaNeueBold;
					margin-left: 4px;
				}
			}
		}
	}
}
