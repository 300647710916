@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.trustpilot {
  background: rgba(209, 182, 197, 0.19);
  padding: 60px 0;
  .title-without-icon{

  }
  .prevBtn {
    display: block;
    left: 10px;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1000;

    &:before {
      content: "\276E";
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #4A4A4A;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .nextBtn {
    display: block;
    right: 10px;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:before {
      content: "\276F";
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #4A4A4A;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  & .trustpilot-title {
    text-align: center;
    color: #4A4A4A;
    font-family: "HelveticaNeue";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px; /* 109.375% */
  }

  .tp-logo {
    text-align: center;
    margin: auto;
    color: #474546;
    margin-top: 30px;

    & a{
      color: #64a774;
    }
    & svg {
      width: 138px;
    }
  }

  .rating {
    text-align: center;
    padding: 10px 0;

    & span {
      color: #000;
      // font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 138.889% */
    }
  }

  @media screen and (max-width: 840px) {
    & .trustpilot-title {
      font-size: 26px;
    }
    .rating {
      & span {
        font-size: 14px;
      }
    }


  }
  @media screen and (max-width: 620px) {
    & .trustpilot-title {
      font-size: 20px;
    }
    .rating {
      & span {
        font-size: 10px;
      }
    }


  }

  .tp-card-container {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    width: 639px;
    height: 260px;
    padding: 24px 40px;
    align-items: center;
    gap: 56px;
    margin: 20px auto;

    & .tp-img {
      width: 140.866px;
      padding-top: 5px;
      margin: auto;
    }

    & h3 {
      color: #3A3A3A;
      text-align: center;
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px; /* 178.571% */
      padding-top: 20px;
    }

    & .text-wrapper{
      display: flex;
      align-items: center;
      height: 100%;
    }
    & p {
      color: #808080;
      font-family: "HelveticaNeue";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 840px) {
      width: 540px;
      & h3 {
        font-size: 14px;
      }

      & p {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 690px) {
      width: 460px;
      height: 220px;
      & h3 {
        font-size: 11px;
      }

      & p {
        font-size: 11px;
      }
      & .tp-img {
        width: 100px;
        padding-top: 5px;
        margin: auto;
      }
    }

    @media screen and (max-width: 574px) {
      height: fit-content;
      min-height: 334px;
      width: 80%;
      padding-right: 10px;
      padding-left: 10px;
      & .tp-img {
        width: 85px;
        padding-top: 5px;
        margin: auto;
      }
    }
    @media screen and (max-width: 480px) {
      width: 250px;
      min-height: 334px;
      padding-right: 10px;
      padding-left: 10px;
      & h3 {
        font-size: 8px;
      }

      & p {
        font-size: 10px;
      }
      & .tp-img {
        width: 50px;
        padding-top: 5px;
        margin: auto;
      }
    }

  }

  .detail{
    text-align: center;
    color: #445267;;
  }
}

