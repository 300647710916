.chart-container {
  border-radius: 20px;
  border: 1px solid #D6D8D9;
  margin-top: 50px;
  padding-bottom: 60px;
  height: 450px;

  .chart-title {
    color: #1E4C90;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 27.37px;
    text-align: left;
    padding-left: 30px;

  }

  .chart-total-description {
    color: #34A853;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 20px;
    text-align: right;
    line-height: normal;
    padding-right: 30px;


    span {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}