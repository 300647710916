@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.section {
  padding-top: 42px;
  padding-bottom: 75px;
  background: url(../../../assets/images/background/freelancer-bg.svg) no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .card {
    padding: 44px 100px;
    border-color: rgba(201, 201, 201, 0.3);
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;

    .title {
      font-size: 16px;
      color: #1E4C90;
      font-weight: 500;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      color: #1E4C90;
    }

    .custom-input-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1E4C90;
      margin-top: 39px;
      margin-bottom: 14px;
    }

    .custom-input-label-category {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1E4C90;
      margin-bottom: 14px;
    }

    .line {
      border-bottom: 1px solid rgba(120, 148, 188, 0.29);
      margin-bottom: 38px;
    }

    .Certificates {
      font-size: 12px;
      margin-bottom: 10px;

      span {
        font-family: HelveticaNeueMedium;
      }
    }

  }

  .submit {
    border-radius: 76px;
    background-color: #1E4C90;
    color: $colorWhite;
    font-family: HelveticaNeueMedium;
    // width: 170px;
    // padding: 10px 0 5px;
    padding: 14px 24px 9px;
    margin-top: 40px;

    @media screen and (max-width: 575px) {
      margin-top: 20px;
    }

    &:hover {
      color: $colorWhite;
    }
  }

  .loading-box {
    text-align: center;
    max-height: 35px;

    .loading {
      width: 30px;
      height: 30px;
      margin-right: 65px;
      margin-top: 5px;
    }
  }
}

.invoice-form {
  margin-top: 20px;
  text-align: center;
  color: #403F3F;

  .form-check-label {
    font-size: 14px;
  }

  .form-check-input {
    margin-top: 2px;
    border: 2px solid #343A40;
    border-radius: 2px;
    float: none;
    margin-right: 15px;

    &:checked {
      border: none;
      background-color: #1E4C90;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .privacy-terms {
    // font-family: HelveticaNeueItalic;
    color: #1E4C90;
  }

  .invoice-submit {
    margin-top: 15px;
    background-color: $colorDarkBlue;
    border-radius: 50px;
    color: #ffffff;
    display: block;
    padding: 10px 25px 5px;
    font-family: HelveticaNeueMedium;

    &:focus {
      box-shadow: none;
    }
  }
}
