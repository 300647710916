
@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.checkbox-custom {
    opacity: 0;
    position: absolute;   
}
.checkbox-custom-group{
    margin-bottom: 16px;
}
// .checkbox-custom, .checkbox-custom-label {
//     display: inline-block;
//     vertical-align: middle;
//     margin: 2px;
//     cursor: pointer;
// }

// .checkbox-custom-label {
//     position: relative;
//     font-size: 14px;
//     font-family: HelveticaNeueRoman;
//     margin-bottom: 20px;
// }

.checkbox-custom-label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #1E4C90;
}
.checkbox-custom-label:before{
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #1E4C90;
    border-radius: 3px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    transform: translateY(-2px);
}
.checkbox-custom-group input:checked + label:after{
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 9px;
    border: solid #1E4C90;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
// .checkbox-custom + .checkbox-custom-label:before {
//     content: '';
//     background: #fff;
//     border: 1.5px solid $colorDarkBlue;
//     display: inline-block;
//     vertical-align: middle;
//     border-radius : 3px;
//     width: 17px;
//     height: 17px;
//     padding: 1px;
//     margin-right: 10px;
//     text-align: center;
   
// }

// .checkbox-custom:checked + .checkbox-custom-label:before {
//     // background: $colorLightBlue;
//     // box-shadow: inset 0px 0px 0px 2.5px $colorWhite;
//     top: -7px;
//     font-size: 12px;
//     color: #343A40;
//     background-color: $colorWhite;
//     pointer-events: visible;
//     cursor: pointer;
//     left: 11px;
//     .info {
//       display: inline-block;
//     }
// }
.checkbox-custom {
    font-family: HelveticaNeueRoman;
    height: 41px;
    width: 100%;
    border: none;
    border: 1.5px solid #1E4C90;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 4px;
    font-size: 16px;
    //  margin-bottom: 35px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: transparent;
    }
  }
.checkbox-custom:focus ~ .checkbox-custom-label,
  .checkbox-custom:not(:placeholder-shown) ~ .checkbox-custom-label {
    top: -7px;
    font-size: 14px;
    color: #1E4C90;
    background-color: $colorWhite;
    pointer-events: visible;
    cursor: pointer;
    left: 11px;
    .info {
      display: inline-block;
    }
  }
  .checkbox-custom:-internal-autofill-selected {
    background-color: $colorWhite;
  }

  .checkbox-custom {
    color: #1E4C90;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 10px;
    transition: 300ms ease all;
    background-color: $colorWhite;
    height: 16px;
    padding: 0 5px;
  }
