@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.explore-all-link {
  color: $colorDarkBlue;
  font-size: 18px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  font-family: HelveticaNeueMedium;
  border: 1px solid $colorDarkBlue;
  box-sizing: border-box;
  border-radius: 21px;
  padding: 10px 90px 8px; 
  @media screen and (max-width: 991px) {
   width: 90%;
   padding: 10px 0px 8px;
  }
}
