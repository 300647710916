@import "../../styles/devicesize.scss";
@import "../../styles/colors.scss";

.carousel-item-container {
  min-height: 250px;

}

.active-item-content-container {
  display: none;

  &.selected {
    display: block;
  }
}

.carousel-item {
  border-radius: 20px;
  border: 1px solid #1E4C90;
  width: 150px;
  height: 150px;
  transition: border-color 0.3s ease;

  .item-title {
    color: #1E4C90;
    padding: 4px 0;
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.carousel-item.selected {
    background-color: #EDFBFF;
    border: 3px solid #22BDE8;
    border-bottom: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .carousel-item__image {
      & img {
        width: 55px;
        margin: auto;
        filter: invert(50%) sepia(82%) saturate(1578%) hue-rotate(155deg) brightness(94%) contrast(92%);
      }
    }

    .item-title {
      color: #22BDE8;

    }

    .right-bottom {
      width: 83px;
      height: 174px;
      position: absolute;
      transform: translateX(39px) rotate(340deg);
      border: 3px none #22BDE8;
      border-bottom-style: solid;
      bottom: 87px;
      border-bottom-right-radius: 15px;

      &::before {
        display: block;
        content: " ";
        width: 78px;
        border-radius: 20px;
        height: 14px;
        background: #EDFBFF;
        position: absolute;
        bottom: 0px;
        z-index: -1;
      }

      &::after {
        display: block;
        content: " ";
        width: 62px;
        border-radius: 20px;
        transform: rotate(40deg);
        height: 16px;
        background: #EDFBFF;
        position: absolute;
        bottom: 16px;
        right: 68px;
        z-index: -1;
      }
    }


    .left-bottom {
      width: 83px;
      height: 174px;
      position: absolute;
      transform: translateX(20px) rotate(20deg);
      border: 3px none #22BDE8;
      border-bottom-style: solid;
      bottom: 87px;
      border-bottom-left-radius: 15px;

    }

  }


}


.carousel-item__image {
  padding-top: 30px;

  & img {
    width: 55px;
    margin: auto;
    filter: invert(80%) sepia(82%) saturate(820%) hue-rotate(182deg) brightness(90%) contrast(94%);
  }
}
