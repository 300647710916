@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.appointment {
  padding: 100px 30px;

  .contact_content {
    border-radius: 20px;
    background: linear-gradient(214deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    width: 100%;
    padding: 40px 90px;
    @media screen and (max-width: 992px) {
      padding: 40px 40px;
    }
    margin: auto auto;
    max-width: 1210px;

    .contact-button {
      &:hover {
        transform: scale(0.9);
      }
      
      transition-duration: .3s;
      transition-property: transform;
      color: var(--neutrals-white, #FFF);
      font-family: "HelveticaNeue";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      width: 229px;
      height: 55px;
      padding: 18px 24px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 76px;
      border: 1px solid #FFF;
      background: #21BCE7;
      box-shadow: 0px 4px 8px 0px #21BCE7;
      cursor: pointer;

      .disable {
        background: #AEB0B3;
        box-shadow: none;
        cursor: auto;
      }
    }


    .contact {
      h3 {
        color: #801D54;
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 30px;
      }

      p {
        color: #85898C;
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

    }


  }

  & img {
    width: 419.083px;
    @media screen and (max-width: 480px) {
      width: 300px;


    }

  }

  .appointment-col {
    // text-align: center;
  }

  @media screen and (max-width: 1200px) {
    .contact_content {
      width: 100%;
    }

  }
}
