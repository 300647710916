.card{
    border-radius: 20px;
    padding: 24px 31px 49px 41px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-top: 42px;
    min-height: 330px;
    &.bg-right{
        background-image: url('../../../assets/images/background/card-bg-plan-right.svg');
        background-position-x: right;
        
    }
    &.bg-left{
        background-image: url('../../../assets/images/background/card-bg-plan-left.svg');
    }
}
