.card-container {
	// width: 192px;
	width: 100%;
	height: 172px;
	border: 1px solid #ffffff;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 40px !important;
	padding: 30px 15px;
	transition: all ease-in-out 100ms;
	&:hover {
		border: solid 2px #fff;
		transform: scale(1.08);
		& > .chevron {
			display: block;
		}
	}
	& > .icon {
		width: 50px;
		height: 40px;
		& > img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	& > .title {
		display: flex;
		align-items: center;
		gap: 2px;
		& > h4 {
			font-weight: 700;
			font-size: 18px;
			color: #ffffff;
			text-align: center;
			margin-bottom: 0;
		}
		& > .chevron {
			// display: none;
			color: #fff;
		}
	}
}
