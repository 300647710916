@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.partnership-row {
  display: flex;
  flex-wrap: wrap; /* allows wrapping if there are too many items to fit in one row */
  justify-content: space-between; /* distributes space between items */
  gap: 20px; /* adds spacing between items */
  padding: 2%;
}

.partnership {
  // background: rgba(76, 110, 166, 0.05);
  background-image: url('../../assets/images/background/client-background-home.svg');

  ;
  padding: 26px;

  .prevBtn {
    display: block;
    left: 10px;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    z-index: 1000;
    outline: none;

    &:before {
      content: "\276E";
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #4A4A4A;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .nextBtn {
    display: block;
    right: 10px;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:before {
      content: "\276F";
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #4A4A4A;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .partnership-title {
    color: #204B90;
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
  }

  .partner {
    flex: 1 1 calc(16.66% - 20px); /* adjust the width of each item */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    & img {
      // width: 112px;
      // height: 48px;
      margin: auto;
      max-width: 100%;
      height: auto;
    }

    & h3 {
      color: #1E4C90;
      text-align: center;
      font-family: "HelveticaNeue";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 20px;
    }
  }
}