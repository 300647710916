@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "./styles/colors.scss";
@import "./styles/fonts.scss";
@import "animate.css";

* {
	margin: 0;
	padding: 0;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	& > main {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 225px - 82px);
		& > * {
			flex: 1 1 0;
		}
	}
}

body {
	font-family: "HelveticaNeue";
	font-size: 16px;
	color: $colorNavyBlue;
	position: relative;
	// margin: 0 0 0 0;
	// padding: 0 0 0 0;
	background-color: #ffff;
	overflow-x: hidden;
	min-width: 315px;
	// display: flex;
	// min-height: 100vh;
	// flex-direction: column;
}

main {
	position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

a {
	text-decoration: none;
	color: inherit;
	&:hover {
		color: inherit;
	}
}

ul {
	list-style: none;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-34 {
	margin-bottom: 34px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mr-20 {
	margin-right: 20px;
}
.ml-10 {
	margin-left: 10px;
}
.mt-30 {
	margin-top: 30px;
}
.w-170 {
	width: 170px;
}
.f-14 {
	font-size: 14px;
}
.f-16 {
	font-size: 16px;
}
.min-100 {
	min-height: 100px;
}
.h-70 {
	height: 70px;
}
b {
	font-family: HelveticaNeueMedium;
}
.btn {
	border: none !important;
	&:hover {
		border: none !important;
	}
	&:focus {
		box-shadow: none;
		border: none !important;
	}
}
.out-line {
	border: 1px solid !important;
	&:hover {
		border: 1px solid !important;
	}
	&:focus {
		box-shadow: none;
		border: 1px solid !important;
	}
	&:active {
		border: 1px solid !important;
	}
}
.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
	border: none !important;
}
.ml-auto {
	margin-left: auto !important;
}
.mr-auto {
	margin-right: auto !important;
}

.animate__animated.animate__slideInLeft {
	display: flex !important;
}

.ReactModal__Overlay{
	z-index: 9999;
	background-color: rgba(68, 82, 103, 0.3) !important;
}