@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";



.submit {
  border-radius: 28px;
  background-color: $colorNavyBlue;
  color: $colorWhite;
  font-family: HelveticaNeueMedium;
  max-width: 276px;
  width: 100%;
  padding: 3px 0 5px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    max-width: none;
  }

  &:hover {
    color: $colorWhite;
  }
}



.payment-banner {
  background-image: url(../../assets/images/businessman-pharmatching.jpg);
  background-position: center;
  background-size: cover;
  height: 350px;
}

.payment-message {
  background: #fff;
  padding: 24px;
  margin-bottom: 30px;
  border: 1px solid #D6D8D9;
  border-radius: 8px;
  margin-top: 100px;
  color: #343A40;

  & h4 {
    font-family: "HelveticaNeueBold";
    margin-bottom: 30px;
    font-size: 36px;
  }

  & .text {
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: "HelveticaNeueBold";
  }

  & .text-link {
    font-size: 16px;
    margin-bottom: 10px;

    & a {
      font-weight: bold;
      color: $colorNavyBlue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .cancel {
    color: $colorred;
    font-size: 14px;

    & b {
      font-weight: bold;
    }
  }
}

.payment-free {
  border: 1px solid #ECECEC;
  background: #902F65;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  text-align: center;
}

.payment-invoice {
  border: 1px solid #D6D8D9;
  background: #fff;
  padding: 48px;
  border-radius: 8px;
  position: relative;
  margin-top: 40px;
  border-top-left-radius: 0;


  & .section-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    font-size: 14px;
    padding: 5px 15px 2px;
    width: 130px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;
    background-color: #4B70A6;
  }

  & h4 {
    font-weight: bold
  }

  & .detail {
    background-color: #F5F6F6;
    color: #343A40;
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
    padding: 11px 10px 9px;
    text-align: center;
    border-radius: 8px;

    & b {
      font-weight: bold;
      font-size: 19px;
    }
  }

  & .btn-wrapper {
    font-family: "HelveticaNeueMedium";
    display: flex;
    justify-content: center;
    
    & .btn-navyblue {
      font-size: 16px;
      border-radius: 76px;
      padding: 14px 128px 10px 128px;
      margin-right: 10px;
      color: white;
      background-color: $colorDarkBlue;
      width: 70%;
      border: none !important;
    }

    & .btn-decline {
      font-size: 16px;
      color: #E10000;
      text-align: left;
      border: none !important;
    }
  }
}

.payment-table-wrapper {
  background: rgba(255, 255, 255, 0.8);
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 30px;
  border: 1px solid #D6D8D9;

  & .table-title {
    font-size: 20px;
    margin-bottom: 40px;
    padding: 0px 8px;
    color: #343A40;
    font-family: "HelveticaNeueBold";

    & .table-title-left {
      font-weight: 800;
    }

    & .table-title-right {
      float: right;
      font-size: 14px;
    }
  }

  & .main-table {
    padding: 10px;
  }

  & table {
    & th {
      font-weight: bold;
    }

    & th,
    & td {
      font-size: 20px;
      color: #343A40;
      padding: 5px;

      //text-align: center;
      & div {
        background-color: #E4EAF5;
        padding: 13px 24px 11px;
        border-radius: 50px;

        & b {
          font-weight: bold;
        }

        &.mid {
          border-radius: 50px 0 0 50px;
        }

        &.end {
          border-radius: 0 50px 50px 0;
          font-weight: bold;
        }

      }
    }
  }

  & .del-price {
    color: rgba(0, 26, 92, 0.5);
    display: inline-block;
    width: 130px;
  }


}