@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.card {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #D6D8D9;
  border-radius: 8px;
  .header{
    display: flex;
  }
  .letter-image {
    height: 45px;
    width: 76px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    @media screen and (max-width: 424px) {
      height: 60px;
      width: 60px;
    }
  }
  .letter-title-container{
    align-content: center;
    padding-left: 12px;
    padding-top: 5px;
    max-width: 85%;
  }
  .letter-title {
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    color: #343A40;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
    max-height: 36px;
  }
  .letter-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 17px;
    .letter-source {
      font-family: HelveticaNeueMedium;
      margin-left: 5px;
      font-size: 16px;
      color: #1E4C90;
      font-weight: 400;
      line-height: 21px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .letter-date {
      font-family: HelveticaNeueRoman;
      font-size: 12px;
      color: #85898C;
      line-height: 15px;
    }
  }
}
