@import "../../../../styles/colors.scss";
@import "../../../../styles/fonts.scss";
@import "../../../../styles/devicesize.scss";


.login-link {
  color: #1E4C90;
  font-family: HelveticaNeueRoman;
  font-size: 16px;
  padding: 1rem 1rem 0.5rem;

  &:hover {
    color: #1E4C90;
  }

  &:focus {
    color: #1E4C90;
    border: none;
  }

}


.create-request-link, .register-link {
  position: relative;
  z-index: 1;
  background: linear-gradient(115.28deg, #21bce7 28.97%, #1e4c90 164.19%);
  border: 1px solid #ffffff;
  border-radius: 76px;
  padding: 13px 20px 9px;
  color: var(--neutrals-white, #FFF);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 991px) {
    font-weight: 700;
    font-size: 16px;
    padding: 10px 25px;
    padding-top: 15px;
    // margin-top: -25.3px;
  }
  @media screen and (max-width: $widthMobileMax) {
    font-weight: 700;
    font-size: 12px;
    padding: 10px 25px;
    padding-top: 15px;
    // margin-top: -22.3px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(268.8deg, #204b90 7.35%, #22bde8 109.57%);
    box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
    border-radius: 76px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
  }
}

.selecet-role {
  background-color: #902F65;
  border-radius: 50px;
  color: $colorWhite;
  width: 150px;
  height: 48px;
  padding: 14px 25px 9px;
  font-family: HelveticaNeueRoman;

  &:hover {
    color: $colorWhite;
    background-color: #902F65;
  }

  &:focus {
    color: $colorWhite;
    background-color: #902F65;
  }
}
