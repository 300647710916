@import "../../../styles/colors.scss";
.parent:first-child{
  margin-top: 30px;
  
}
.parent{
  border-bottom: 1px solid #D6D8D9;
  &:last-child{
    border: none;
  }
}
.card {
  width: 100%;
  min-height: 75px;
  padding: 25px 20px 10px 20px;
  margin-top: 30px;
  .title {    
    color: #1E4C90;
    font-size: 16px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    color: $colorNavyBlue;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .show-more{
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    color: #4dd0e1;
  }
  .body {
    font-size: 14px;
    font-weight: 300;
    overflow: hidden;
    color: #000000;
    padding: 19px 10px;
    .item {
      border-radius: 47px;
      padding: 6px 12px 3px;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
      height: 28px;
      color: #000000;
      &:hover {
        color: inherit;
      }
    }
  }
  .link {
    border-radius: 47px;
    padding: 3px 2px 3px 10px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 28px;
    &:hover {
      color: inherit;
    }
    span {
      transform: translateY(2px);
    }
    .download {
      background: white;
      display: inline-block;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      margin-left: 10px;
      img {
        width: 14px;
        transform: translate(5px, 3px);
      }
    }
  }
  .certificate-btn {
    text-align: center;
    padding: 16px 14px 9px;
    border: 1px solid #1E4C90;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    max-width: 190px;
    margin-left: 50px;
    color: #1E4C90;
    margin-top: 23px;
    img{
        margin-bottom: 12px;
    }
}
}
