@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.react-datepicker-wrapper {

    width: 100%;
    input {
        background-color: transparent;
        border: 1px solid #1E4C90;
        color: #343A40;
        outline: none;
        font-size: 14px;
        cursor: pointer;
        width: 100%;
        border-radius: 8px;
        padding: 14px 10px 9px;
        max-height: 41px;
        
    }
    input::placeholder{
        color: #85898C !important;
    }
    input:-ms-input-placeholder{
        color: #85898C !important;
    }
}

.react-datepicker__tab-loop {
    position: absolute;
}

.react-datepicker-popper{
    z-index: 11 !important;
}