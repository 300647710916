@font-face {
    font-family: "HelveticaNeueLight";
    src: url("../assets/fonts/HelveticaNeueLTPro-Lt.otf");
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "HelveticaNeueMedium";
    src: url("../assets/fonts/HelveticaNeueLTPro-Md.otf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaNeueRoman";
    src: url("../assets/fonts/HelveticaNeueLTPro-Roman.otf");
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "HelveticaNeueBold";
    src: url("../assets/fonts/HelveticaNeueLTPro-Bd.otf"); 
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaNeueItalic";
    src: url("../assets/fonts/HelveticaNeueLTPro-LtIt.otf"); 
    font-weight: normal;
    font-style: normal;
  }

  /* HelveticaNeue-regular - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/HelveticaNeueLTPro-Roman.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-500 - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/HelveticaNeueLTPro-Md.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-600 - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/HelveticaNeueLTPro-Bd.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-700 - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/HelveticaNeueLTPro-Bd.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-800 - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/HelveticaNeueLTPro-Hv.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-900 - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/HelveticaNeueLTPro-Blk.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-100italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/HelveticaNeueLTPro-UltLtIt.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-200italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/HelveticaNeueLTPro-ThIt.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-300italic - latin */

/* HelveticaNeue-italic - latin */

/* HelveticaNeue-500italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/HelveticaNeueLTPro-MdIt.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-600italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/HelveticaNeueLTPro-BdIt.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-700italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/HelveticaNeueLTPro-BdIt.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-800italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/HelveticaNeueLTPro-Hv.otf'); /* IE9 Compat Modes */
}
/* HelveticaNeue-900italic - latin */
@font-face {
  font-family: 'HelveticaNeue';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/HelveticaNeueLTPro-BlkIt.otf'); /* IE9 Compat Modes */
}