@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";

.dropdown-item {
  font-size: 12px;
  white-space: normal;

  .categories-icon {
    margin-right: 1rem;
  }
}
.categories-header {
  font-size: 14px;
  font-family: HelveticaNeueMedium;
  font-weight: 500;
  background-color: $colorLightBlue2;
  color: $colorDarkBlue;
  padding: 10px;
  &:hover {
    background-color: $colorLightBlue2;
    color: $colorDarkBlue;
  }
}

.dropdown-menu {
  width: 660px;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: auto;
    position: absolute !important;
  }
}

.nav-link {
  // font-family: HelveticaNeueRoman;
  color: #1E4C90;
  font-size: 16px;
  font-weight: 600;
  padding-top: 16px;
  padding-bottom: 0;
  border-radius: 2px;
  transition: none;
  border: none;
  background-color: inherit;
  display: flex;
  height: 100%;
  @media screen and (max-width: 991px) {
    height: 50px;
  }

  &:hover {
    color: #1E4C90;
  }
  &::after {
   border: none;
  }
  &:focus {
    box-shadow: none;
    color: #1E4C90;
  }
}
.active-link {
  border-bottom: 4px solid #18c3e6;
}
