@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.toast-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  .toast {
    transition: 0.3s ease;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    margin-bottom: 15px;
    width: 700px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid;
    color: $colorNavyBlue;
    font-family: HelveticaNeueRoman;
    @media screen and (max-width: 767px) {
      width: 500px;
    }
    @media screen and (max-width: 575px) {
      width: 400px;
    }
    @media screen and (max-width: 424px) {
      width: 350px;
    }
    @media screen and (max-width: 374px) {
     width: 300px;
    }
    .notification-title {
      font-weight: 700;
      font-size: 16px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 6px;
      width: 300px;
      height: 18px;
    }
    .toast-message {
      margin: 0;
      //   text-align: left;
      //   height: 18px;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      padding-top: 5px;
      width: 85%;
    }
    .icon-container {
      border-radius: 19px;
      width: 50px;
      height: 50px;
      margin-right: 15px;
      justify-content: center;
      display: flex;
      align-items: center;
      .icon {
        width: 32px;
        height: 32px;
        color: $colorWhite;
      }
    }
    .close-btn {
      width: 20px;
      height: 20px;
      padding: 0;
      img {
        width: 20px;
        height: auto;
      }
    }
  }
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
  @media screen and (max-width: 575px) {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
