@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.step-card {
    background: #FFFFFF;
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: rgba(201, 201, 201, 0.3);
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    padding: 44px 99px;
}

.section-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1E4C90;
}

.section-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1E4C90;
    margin-top: 7px;
}
.input-container{
    max-width: 676px;
    margin: 0 auto;    
}
.line{
    border: 1px solid rgba(120, 148, 188, 0.29);
    margin-top: 25px;
    margin-bottom: 38px;
}
.company-size-container{
    max-width: 825px;
    margin: 0 auto;
}
.submit {
    border-radius: 28px;
    background-color: $colorDarkBlue;
    color: $colorWhite;
    font-family: HelveticaNeueMedium;
    width: 115px;
    padding: 15px 0 10px;
    margin-top: 40px;
    border: none;

    @media screen and (max-width: 575px) {
        margin-top: 20px;
    }

    &:hover {
        color: $colorWhite;
        background-color: $colorDarkBlue;
        border: none !important;
    }

    &:focus {
        color: $colorWhite;
        background-color: $colorDarkBlue;
        border: none;
    }
}

.Certificates {
    font-size: 12px;
    margin-bottom: 10px;

    span {
        font-family: HelveticaNeueMedium;
    }
}