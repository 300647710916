@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

.not-found-wrapper{
    margin-bottom: 134px;
    &>.not-found-left{
        &>img{
            position: absolute;
            top : 1%;
            z-index: -1;
        }
        &>div{
            margin-top: 90px;
            &>h1{
                font-family: "HelveticaNeueBold";
                font-size: 144px;
                line-height: 1.5;
                color: $colorDarkBlue;
            }
            &>h2{
                font-family: "HelveticaNeueBold";
                font-size: 48px;
                line-height: 1.5;
                color: $colorNavyBlue;
                padding-top: 38px;
            }
            &>h3{
                font-family: "HelveticaNeueLight";
                font-size: 24px;
                line-height: 1.5;
                color: #C8DEF4;
            }
            &>a{
                width: 275px;
                background-color: $colorDarkBlue;
                font-family: "HelveticaNeueMedium";
                font-size: 18px;
                color: $colorLightDark;
                border-radius: 74px;
                padding: 13px 25px;
                margin-top: 79px;
            }
        }
    }
    &>.not-found-right{
        margin-top: 40px;
        &>img{
            width: 100%;
            height: 100%;
        }
    }
}