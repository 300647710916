@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.businessOutreach {
  text-align: center;
  padding: 20px;
  // background-color: #f5f9ff;
  background-image: url('../../assets/images/background/outreach-background.svg');

  h2 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: "HelveticaNeue";
    color: rgba(74, 74, 74, 1);
    padding-top: 4%;
  }

  .subtitle {
    font-size: 32px;
    color: rgba(47, 207, 111, 1);
    font-weight: 500;
    font-family: "HelveticaNeue";
    margin-bottom: 30px;
  }

  .outreachCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    }


    .outreachCard {
      display: flex;
      background: linear-gradient(271.47deg, rgba(31, 77, 145, 0.8) 0.81%, rgba(9, 23, 43, 0.8) 97.5%);
      color: white;
      border-radius: 10px;
      width: 80%;
      padding: 20px;
      margin: 10px 0;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      align-items: center;

      .cardHeader {
        display: table-column;
        justify-content: space-between;
        // align-items: center;
        text-align: left;
        max-width: 50%;

        h3 {
          font-size: 48px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          // margin: 0;
          font-family: "HelveticaNeue";
        }

        p {
          font-size: 18px;
          font-weight: 700;
          font-family: "HelveticaNeue";
          color: rgba(30, 188, 232, 1);
          // margin: 0;
        }
      }

      .firstCardP {
        font-size: 20px;
        font-weight: 400;
        font-family: "HelveticaNeue";
        color: rgba(191, 240, 255, 1);
        text-align: left;
        margin-top: 10px;
        padding-left: 20;
        // width: 100%;
        // margin-left: 20px;
        

      ul {
        
        text-align: left;
        margin-top: 10px;
        list-style: none;
        // padding-left: 10;
        list-style-type: disc;
        width: 90%;
        // margin-left: -5%;

         li {
          text-align: left;
          font-size: 18px;
          font-weight: 400;
          line-height: 35px;
          font-family: "HelveticaNeue";
          color: rgba(255, 255, 255, 1);
          margin-bottom: 10px;
          // margin-left: 0;
        }
      }}

      // .icon {
      //   position: relative;
      //   display: table-column;
      //   align-items: baseline;
      //   top: -100px;
        
      //   img {
          
          
      //   }
      // }
    }}
    
    @media screen and (max-width: 768px) {
      .outreachCard {
        max-width: 100%; // Ensures full width for mobile devices
      }
    }
