.card {
  margin-top: 20px;
  padding: 28px 24px;
  border: 1px solid rgba(201, 201, 201, 0.3);
  border-radius: 10px;
  box-shadow: none;
}

.warning-letter-filter-wrapper {
  // margin-bottom: 14px;
}

.filter-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  .filter-box-title {
    display: flex;
    justify-content: start;
    align-items: center;
    color: #1E4C90;
    font-size: 16px;
    font-weight: 500;

    & span {
      margin-left: 7px;
      margin-top: 4px;
    }
  }

  .filter-box-reset {
    display: flex;
    justify-content: end;
    align-items: center;

    .reset-button {
      color: #801D54;
      font-weight: 500;
      font-size: 14px;
      margin-right: 14px;
      margin-top: 6px;
      cursor: pointer;
    }
  }
}

.line {
  border-top: 1px solid #D6D8D9;
  margin: 18px 0 22px;
}