.header{
  width: 100%;
  min-height: 130px;
  background: linear-gradient(89.96deg, rgba(30, 76, 144, 0.5) 0.05%, rgba(34, 189, 232, 0.5) 100.78%);
}

.section {
  margin-top:50px;
  margin-bottom: 50px;
}

  .min-h {
    min-height: 400px;
  }

  .loading {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .line{
    border-top: 1px solid #D6D8D9;
    margin-bottom: 52px; margin-top: 20px;
  }