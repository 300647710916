@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  z-index: 10000;
  overflow: hidden;
  @media screen and (max-width: 575px) {
    width: 90%;
  }
  .header-modal {
    padding: 20px 20px 15px;
    .warning {
      color: #AD0000;
      font-family: HelveticaNeueMedium;
      font-size: 16px;
    }
    .success {
      color: $colorGreen;
      font-family: HelveticaNeueMedium;
      font-size: 16px;
    }
  }
  .modal-message {
    font-size: 14px;
    color: $colorNavyBlue;
    padding: 20px;
    margin: 0;
    font-family: HelveticaNeueRoman;
  }
  .buttons-box {
    padding: 0 20px 20px;
      text-align: right;
      .revoke {
        font-size: 16px;
        font-family: HelveticaNeueRoman;
        // min-width: 118px;
        padding: 14px 24px 9px;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 73px;
      }
      .cancel {
        // border: 1px solid $colorDarkBlue;
        border: none !important;
        border-radius: 21px;
        color: #E10000;
        font-size: 16px;
        // min-width: 118px;
        padding: 9px 0px 4px;
        font-family: HelveticaNeueRoman;
        margin-right: 24px;
      }
  }

}
