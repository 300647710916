
.public-profile{
    margin-top: 55px;
    margin-bottom: 24px;
    height: 40px;
.view-profile{
    background-color: #ffffff ;
    border: 2px solid #1E4C90 !important;
    border-radius: 75px;
    color: #1E4C90;
    width: 210px;
    height: 48px;
    padding: 16px 25px 12px;
    
}
}