@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";

.menu-wrp {
  max-height: 80vh;
  overflow-y: scroll;
  width: 206px;
  border-right: 1px solid $colorGray2;

  @media screen and (max-width: 992px) {
    border-right: none;
    ;
  }

  float: left;

  & ul {
    padding: 0;

    & li {
      list-style: none;
    }
  }

  & .menu-item {
    color: $colorNavyBlue;
    padding: 10px 10px 0 !important;
    font-family: HelveticaNeueRoman;

    & a {
      font-size: 12px;
      display: block;
      font-weight: 500;

      &:hover {
        color: inherit;
      }

    }

    &:hover {
      color: $colorLightBlue;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 3px solid #fff;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgba(201, 201, 201, 0.3);
  }
}

.loading {
  text-align: center;
  margin: 20px auto;
  width: 36px;
  height: 36px;
  display: block;
}

.wait {
  text-align: center;
  font-size: 14px;
  color: $colorLightBlue;
}