.cookie-banner{
    
    justify-content: center;
}
.privacy-btn{
    padding: 8px 10px 5px;
    border-radius: 4px;
    // margin: 15px 0 15px 40px;
    font-size: 13px;
    text-decoration: underline;
}
.cookie-content{
    flex: unset !important;
    text-align: center;
}