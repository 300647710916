.card {
    margin-top: 50px;
    padding: 30px;
    border: 1px solid #D6D8D9;
    border-radius: 20px;
    .title{
        font-size: 16px;
        color: #1E4C90;
    }
    .body{
        padding-left: 5px;
        .body-title{
            color: #85898C;
            font-size: 14px;
            margin-top: 34px;
            margin-bottom: 12px;
        }
        .body-value{
            font-weight: 300;
            color: #000000;
            overflow-wrap: break-word;
        }
        .spec-tem{
            color: #801D54;
            font-size: 16px;            
        }
        .social-wrapper{
            display: flex;
            justify-content: start;
            div:last-child{
                margin-left: 24px;
            }
        }
    }
}