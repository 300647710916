@import "../../../../styles/colors.scss";

.navbar-nav {
  margin: 0 auto;
  height: 50px;
  @media screen and (max-width: 991px) {
    height: auto;
  }

  .nav-link {
    color: #1E4C90;
    // font-family: HelveticaNeueRoman;
    font-weight: 600;
    font-size: 16px;    
    padding-top: 16px;
    padding-bottom: 0;
    @media screen and (max-width: 991px) {
      height: 50px;
    }

    display: inline-block;
    &:hover {
      color: #1E4C90;
    }
  }
}
.active-link {
  border-bottom: 4px solid #21BCE7;
}
