.card {
    margin-top: 24px;
    padding: 23px 75px 39px 45px;
    border-radius: 20px;

    .card-title {
        font-weight: 500;
        font-size: 16px;
        color: #1E4C90;
        margin-top: 16px;
    }

    .invite-label {
        font-size: 14px;
        font-weight: 400;
        margin-top: 13px;
    }

    .submit {
        position: absolute;
        right: 4px;
        top: 4px;
        border-radius: 4px;
        background-color: #1E4C90;
        padding: 4px;
        padding-top: 2px !important;
        border: none !important;
        //  padding: 2px 11px;
        height: 32px;

        &:focus {
            box-shadow: none;
        }

        span {
            color: white;
            padding-left: 10px;
            padding-right: 10px;
            font-family: HelveticaNeueMedium;
        }
    }

    .table-users {
        width: 100%;

        thead tr th {
            border-bottom: 1px solid #D6D8D9;
            font-weight: 400;
            font-size: 14px;
            color: #B4B4B4;
            padding-top: 13px;
            padding-bottom: 13px;
        }

        td,
        th {
            padding-left: 38px;
            text-align: center;
        }

        tr td:last-child,
        tr th:last-child {
            padding-right: 38px;
        }

        td {
            padding-top: 21px;
            padding-bottom: 21px;
            font-weight: 400;
            font-size: 14px;
            color: #343A40;
        }

        tr td:first-child {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 300px;
        }

        tr td:first-child,
        tr th:first-child {
            text-align: left;
        }

        .show-more-btn {
            border: none !important;
        }

        .admin-menu {
            height: 96px;
            width: 207px;
            right: -20px !important;
            margin-left: auto !important;
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border: 1px solid #ececec;

            .close-btn {
                width: 12px;
                cursor: pointer;
                float: right;
            }

            .remove-admin {
                font-size: 14px;
                color: #1E4C90;
                padding: 0 0 10px 0;
                line-height: 17px;
            }

            .delete-user {
                font-size: 14px;
                color: #FF0000;
                padding: 0;
            }
        }

        .deactive-company {
            color: #FF0000;
            font-size: 14px;
        }

        .cencel-btn {
            font-size: 14px;
            color: #FF0000;
            padding: 0;
            margin-top: 10px;
        }

        .deny-btn {
            color: #FF0000;
            font-size: 14px;
            padding: 0;
        }

        .grant-btn {
            color: #1E4C90;
            font-size: 14px;
            padding: 0;
            margin-top: 10px;
            // margin-left: 20px;

            @media screen and (max-width: 767px) {
                margin-left: 0;
                margin-top: 5px;
                white-space: nowrap;
            }
        }

        .request-status {
            font-size: 14px;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 3px;
            padding-right: 10px;
            padding-left: 10px;
            border-radius: 14px;
            line-height: 16px;
            width: max-content;
            margin: 0 auto;
        }
    }

}