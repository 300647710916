@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.content {
  background: url("../../assets/images/solution/table-bg.png");
  background-size: 100% 100%;
}
.loading-wrapper{ 
  position: absolute;
  background-color: #45454599;
  top: 0;
  height: 100%;
  width: 100%; 
  z-index: 1000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading{
    opacity: 1;
    width: 50px;    
  }
}

.section {
  margin-top: 68px;
  margin-bottom: 50px;

  .title {
    font-family: 'HelveticaNeue';
    font-size: 24px;
    margin-bottom: 0px;
    color: #343A40;
  }

  .loading {
    width: 60px;
    height: 36px;
  }

  .min-h {
    min-height: 400px;
  }
}

.contact_content {
  border-radius: 20px;
  background: linear-gradient(214deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
  box-shadow: 0px 4px 14px 4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  width: 90%;
  padding: 50px 0;
  margin: auto auto 60px;
  max-width: 1210px;

  .contact-button {
    &:hover {
      transform: scale(0.9);
    }
    margin-left: 80px;
    transition-duration: .3s;
    transition-property: transform;
    color: var(--neutrals-white, #FFF);
    font-family: "HelveticaNeue";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 229px;
    height: 55px;
    margin-top: 20px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 76px;
    border: 1px solid #FFF;
    background: #21BCE7;
    box-shadow: 0px 4px 8px 0px #21BCE7;
    cursor: pointer;

    .disable {
      background: #AEB0B3;
      box-shadow: none;
      cursor: auto;
    }
  }


  .contact {
    h3 {
      color: #801D54;
      font-family: "HelveticaNeue";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: #85898C;
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

  }

}


.table-title {
  text-align: center;

  h2 {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    background: #1E4C90;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    margin: 58px auto 0 auto;
  }

}

