@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.invoice-main {
	background: $colorLightDark;
	box-shadow: 0px 0px 10px #0000000d;
	border-radius: 8px;
	position: relative;
	padding: 50px 20px 50px 20px;
	margin-top: 67px;
	min-height: 500px;
	& > .title {
		background: linear-gradient(90deg, $colorNavyBlue -43.21%, $colorLightBlue 171.6%);
		box-shadow: 0px 0px 10px #0000000d;
		border-radius: 8px;
		color: $colorLightDark;
		font-family: "HelveticaNeueMedium";
		font-size: 14px;
		line-height: 20px;
		padding: 5px 34px;
		position: absolute;
		top: -15px;
	}
	& > .not-found {
		margin-top: 100px;
		margin-bottom: 50px;
	}
	& > .loading {
		display: flex;
		width: 60px;
		height: 60px;
		margin: 70px auto;
	}
}
.filter {
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	padding: 50px 20px 50px 20px;
	margin-top: 50px;
	.btn-datepicker {
		background-color: $colorDarkBlue;
		font-family: "HelveticaNeueRoman";
		border-radius: 56px;
		color: white;
		font-size: 14px;
		padding: 12px 20px 9px 20px;
		max-width: 172px;
		cursor: pointer;
	}
	.date-range-picker {
		position: absolute;
		z-index: 10;
		border-radius: 8px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
		margin-left: 20px;
	}
	.range-picker {
		display: flex;
		height: 100%;

		.range-text {
			flex: 2;
			align-items: center;
			display: flex;
			padding-right: 10px;
		}
		.input-range{
			flex: 8;
			align-items: center;
			display: flex;
		}
	}
	.imgDateFilter {
		float: right;
		margin-top: -5px;
	}
}
.my-company {
	padding-bottom: 20px;
	.company-logo {
		width: 171px;
		height: 59px;
		border-radius: 8px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@media screen and (max-width: 767px) {
			margin: 0 auto 15px;
		}
	}
	.company-name {
		font-size: 18px;
		font-family: "HelveticaNeueMedium";
		color: $colorNavyBlue;
	}

	.company-address {
		font-size: 14px;
		color: $colorNavyBlue;
		margin-top: 12px;
	}
}
.back-to-dashboard {
    display: flex;
    justify-content: start;
    align-items: center;

    img {
        margin-top: -3px;
        margin-right: 17px;
    }
}