@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.card {
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0535948);
    border-radius: 8px;
    border: 1px solid #F5F6F6;
    max-width: 132px;
    margin: 0 auto;
    cursor: pointer;

    .cardContext {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .sizeIcon {
        width: 68px;
        margin: 0 auto;
        background-color: #E4EAF5;
        color: #21BCE7;
        font-size: 20px;
        line-height: 24px;
        font-family: HelveticaNeueBold;
        text-align: center;
        border-radius: 6px;
        padding-top: 6px;
        
    }

    .title {
        color: #343A40;
        font-family: HelveticaNeueMedium;
        font-size: 13px;
        line-height: 16px;
        max-width: 94px;
        text-align: center;
        margin: 0 auto;
        margin-top: 21px;
    }
}
.selected {
    border: 1px solid #F5F6F6;
    box-shadow: 0px 0px 10px rgba(30, 188, 232, 0.3);
}
