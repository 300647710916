@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.scroll-button {
  position: fixed;
  width: 100%;
  left: calc(100% - 60px);
  bottom: 60px;
  height: 20px;
  font-size: 3rem;
  z-index: 1000;
  cursor: pointer;
  color: #22bee8;
}
