.card {
    padding: 30px 20px;
    margin-bottom: 57px;
    border-radius: 20px;

    @media screen and (max-width: 767px) {
        margin-top: 0;
    }

    @media screen and (max-width: 576px) {
        padding: 25px 15px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            color: #1E4C90;
            font-size: 16px;
        }

        .edit {
            color: #801D54;
            font-size: 14px;
            font-weight: 400;
        }

    }

    .company-logo {
        width: 214px;
        height: 80px;
        border-radius: 8px;
        margin-right: 12px;
    }
    .company-name.pending{
        min-height: 305px;
    }
    .company-name {
        font-size: 16px;
        color: #000;
        font-family: HelveticaNeueBold;
        width: 300px;

        .info-title {
            font-family: HelveticaNeue;
            color: #85898C;
            font-size: 16px;
            font-weight: 400;
            margin-top: 64px;

            &.department {
                margin-top: 42px;
            }
        }
        
        .company-info {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .freelancer-txt {
        font-size: 14px;
        color: #343A40;
        width: 75%;
        min-height: 415px;

        @media screen and (max-width: 991px) {
            width: 65%;
        }

        @media screen and (max-width: 767px) {
            width: auto;
            margin-bottom: 15px;
        }
    }

    .btn-edit {
        padding: 14px 24px 9px;
        border: 2px solid #1E4C90 !important;
        border-radius: 76px;
        font-size: 16px;
        font-family: HelveticaNeueMedium;
        color: #1E4C90;        
    }
    .btn-detail {
        padding: 14px 24px 9px;
        border: 2px solid #1E4C90 !important;
        background-color: #1E4C90;
        border-radius: 76px;
        font-size: 16px;
        font-family: HelveticaNeueMedium;
        color: white;   
        margin-top: 10px;     
    }
    .btn-revoke{
        margin-top: 42px;
    }
    .revoke-company{
        display: flex;
        flex-direction: column;
    }
}