.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #1E4C90;

    .renew {
      font-weight: 400;
      font-size: 12px;
      color: #85898C;
    }
  }

  .change-plan {
    font-weight: 400;
    font-size: 14px;
    color: #801D54;
    cursor: pointer;
  }


}

.emptyTitle {
  color: #85898C;

}

.solutionBtn {
  background-color: #1E4C90;
  color: white;
  border-radius: 40px;
  font-family: HelveticaNeueMedium;
  width: 100%;
  max-width: 160px;
  padding: 12px 24px 8px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    max-width: none;
  }

  &:hover {
    // color: $colorWhite;
  }
}


.current-plan {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  background: linear-gradient(94.61deg, #21BCE7 -60.61%, #1E4C90 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  div {
    height: 20px;
    margin-left: 10px;
  }
}

.info {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #21BCE7;
  margin-top: 30px;

  img {
    margin-bottom: 6px;
  }
}

.info-second {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #21BCE7;
  margin-top: 48px;

  img {
    margin-bottom: 6px;
  }
}