@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.search-box {
  // margin-bottom: 20px;
  form {
    position: relative;
    display: flex;
    .search-input {
      height: 35px;
      width: 100%;
      border: none;
      border: 1px solid #D6D8D9;
      border-radius: 8px;
      padding-left: 10px;
      padding-top: 8px;
      font-size: 14px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: transparent;
        padding-top: 20px;
      }
    }
    .search-input:focus ~ .label-search,
    .search-input:not(:placeholder-shown) ~ .label-search {
      top: -7px;
      font-size: 12px;
      color: $colorDarkBlue;
      background-color: $colorWhite;
      left: 11px;
    }

    .search-input:-internal-autofill-selected {
      background-color: $colorWhite;
    }
    .label-search {
      color: #85898C;;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 10px;
      transition: 300ms ease all;
      background-color: $colorWhite;
      height: 16px;
    }
    .btn-submit {
      position: absolute;
      right: 4px;
      top: 3px;
      border-radius: 4px;      
      padding: 4px;
      padding-top: 2px !important;
      //  padding: 2px 11px;
      height: 32px;     
      &:focus {
        box-shadow: none;
      }
      span {
        color: $colorWhite;
        padding-left: 10px;
        padding-right: 10px;
        font-family: HelveticaNeueMedium;
      }
    }
  }
}
