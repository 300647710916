
.header{
    flex: none !important;
    width: 100%;
    height: 50px;
    background: linear-gradient(89.96deg, rgba(30, 76, 144, 0.5) 0.05%, rgba(34, 189, 232, 0.5) 100.78%);
}

.menu-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #D6D8D9;
    margin: 0;

    .nav-item{
        text-align: center;
        a{
            padding: 19px 29px;
        }

        a.active-link{            
            background: rgba(32, 75, 144, 0.15);
            border-bottom: 2px solid #1E4C90;;
        }
    }
}