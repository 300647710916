.profile-page-title{
	color: #343A40;
	font-size: 24px;
	font-family: "HelveticaNeueBold";  
	margin-bottom: 26px;
}

.recieved-application {
  padding: 50px 40px;
}
.min-h {
    min-height: 400px;
  }
.not-found{
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .loading {
    width: 60px;
    height: 36px;
    margin-top: 60px;
  }