@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.buttons-group {
  border: 1px solid #1E4C90;
  height: 38px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  .nav-item {
    width: 50%;
    display: inline-block;
    text-align: center;
    height: 100%;
    padding-top: 8px;
    font-family: HelveticaNeueRoman;
    color: #1E4C90;
  }
}
.active-link {
  background-color: #D2DBE9;
  border-radius: 8px;
}
