.accordion *{
    font-size: 14px;
    line-height: 17px;
}
.accordion-item{
    margin-bottom: 40px;
    background-color: transparent;
    border: 1px solid #d9d9d9;
    border-radius: 8px !important;
}
.accordion-button{
    background-color: transparent;
}
.accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: transparent;
    color: #902f65;
}
.accordion-button:focus{
    border: none;
    box-shadow: none;
}
.accordion-body{
    color: #343A40;
    padding-top: 5px;
}
.accordion-item:not(:first-of-type){
    border-top: 1px solid #d9d9d9;
}