@import "../../../styles/colors.scss";
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  padding: 20px;
  z-index: 10000;
  overflow: hidden;
  @media screen and (max-width: 575px) {
   width: 370px;
  }
  @media screen and (max-width: 374px) {
    width: 310px;
   }
  .title-box {
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 20px;
    }
    .close {
      cursor: pointer;
      width: 16px;
    }
  }

  .btn-crop {
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    padding: 7px 0px 5px;
    background-color: $colorGreen;
    margin-top: 10px;
    color: $colorWhite;
    margin-right: 10px;
    width: 150px;
    @media screen and (max-width: 374px) {
      width: 120px;
     }
  }
  .btn-restore {
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    padding: 7px 0px 5px;
    background-color: $colorWhite;
    margin-top: 10px;
    color: $colorDarkBlue;
    border-color: $colorDarkBlue;
    width: 150px;
    @media screen and (max-width: 374px) {
      width: 120px;
     }
  }
}
