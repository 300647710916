@import "../../../../styles/fonts.scss";
@import "../../../../styles/colors.scss";

.banner-team-projects-wrapper {
  display: flex;
  margin-top: 50px;
  padding: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  &> div {
    min-width: 320px;
  }
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > h3 {
      font-family: "HelveticaNeueMedium";
      margin-bottom: 0px;
    }
    & > a {
        font-family: "HelveticaNeueMedium";
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0px;
    }
  }
}

.loading {
    width: 60px;
    height: 36px;
    margin-top: 60px;
  }

.not-found{
  margin-top: 100px;
  margin-bottom: 50px;
}