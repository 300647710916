@import "../../styles/colors.scss";

.profile-avatar {
  background: url(../../assets/images/Icons/profile-user.svg);
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  @media screen and (max-width: 424px) {
    height: 72px;
    width: 72px;
  }
}

.edit-avatar {
  width: 38px;
  height: 38px;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0px;
  @media screen and (max-width: 424px) {
    width: 32px;
    height: 32px;
  }
  &:hover{
    border: none;
  }
  &:focus {
    
  }
  img {
    width: 32px;
    height: 32px;
    @media screen and (max-width: 424px) {
      width: 18px;
      height: 18px;
    }
  }
}
