
.desc-bg {
    //background: linear-gradient(90deg, #10497d -43.21%, #4dd0e1 171.6%);
    background-color: #4B70A6;

}
.service-categories-title {
    //background: linear-gradient(90deg, #2FCF6F -43.21%, #4DD0E1 171.6%);
    background-color: #7894BC;
}
.service-categories-item {
    background: rgba(33, 188, 231, 0.2);
    color: #000;
}
.key-services-title {
    //background: linear-gradient(270deg, #3F74CB -75.93%, #4DD0E1 118.21%);
    background: #7AD7F1;
    span{
    color: #1E4C90;
    }
}
.key-services-item {
    background: rgba(30, 76, 144, 0.2);
    color: #000;
    
}
.specialities-title {
    //background: linear-gradient(90deg, #FFB800 -43.21%, #FF5C00 167.13%);
    background: #FFD569;
    span{
        color: #664900;
    }
}
.specialities-item {
    background: #FFF8E6;
    color: #664900;
}
.certificates-title {
    //background: linear-gradient(90deg, #FFDEDE -77.47%, #D52E2E 124.71%);
    background: #A65984;
}
.certificates-item {
    background: #E9D5E0;
    color: #801D54;
}