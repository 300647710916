@import "../../../styles/colors.scss";

.accordion {
  // margin-bottom: 34px;
}

.accordion-button {
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #1E4C90 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  height: 25px;
  &:focus-visible{
    border: none !important;
    outline: none;
  }

  .has-selected {
    width: 100%;
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    margin-right: 14px;

    .selected-count {
      position: relative;
      color: #1E4C90;
      padding: 5px 11px 0 7px;
      background: #E8EDF4;
      border-radius: 16px;

      img {
        position: absolute;
        right: -5px;
        top: 5px;
      }
    }

    .filter-name {
      padding-top: 4px;
    }
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:active {
    border: none !important;
  }

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    color: #85898C;
  }

  &:after {
    margin-top: 4px;
    margin-left: 5px;
    color: #85898C;
    display: inline-block;
    vertical-align: 0.255em;
    content: url(../../../assets/images/arrow/accordion-arrow.svg);
  }
}

.accordion-button[aria-expanded="true"] {
  &:after {
    transform: rotate(180deg);
    width: 13px;
    height: 13px
  }
}

.accordion-list {
  padding: 16px 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    left: 0;
    right: 0;
    min-width: auto;
  }

  form {
    position: relative;
  }

  .accordion-items {
    max-height: 169px;
    overflow-y: auto !important;
    width: 100%;
    &::-webkit-scrollbar {
      width: 8px;
    }  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 3px solid #fff;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: rgba(201, 201, 201, 0.3);
    }
  }

  .search-box {
    display: flex;
    position: sticky;
    justify-content: space-between;
    top: 0;
    z-index: 9;
    background: $colorWhite;
    margin-bottom: 10px;

    img {
      position: absolute;
      right: 13px;
      top: 8px;
    }

    @media screen and (max-width: 374px) {
      display: block;
      text-align: center;
    }

    .search-input {
      border: 1px solid #D6D8D9;
      border-radius: 8px;
      height: 36px;
      width: 100%;
      padding-left: 10px;
      padding-top: 5px;
      color: $colorNavyBlue;
      font-size: 14px;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 374px) {
        width: 100%;
      }
    }


  }

  .input-box {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    padding: 7px 20px;

    &:hover {
      background-color: #f8f9fa;
    }

    .checkbox {
      margin-right: 10px;
      margin-top: 2px;
      transform: translateY(2px);
      cursor: pointer;
    }

    .checkbox-label {
      font-size: 14px;
      font-weight: 300;
      color: $colorNavyBlue;
      line-height: 14px;
      cursor: pointer;
      background-color: inherit;

      &:hover {
        background-color: #f8f9fa;
      }
    }

  }


}

.single-input-error {
  font-size: 10px;
  color: $colorred;
  margin-left: 14px;
}

.life-science-custom-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #5D6166;
  margin-top: 4px;
  margin-left: 14px;
}