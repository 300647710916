@import "../../../styles/colors.scss";

.sidebar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 220px;
  background-color: $colorWhite;
  border-radius: 0px 8px 8px 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 991px) {
    width: 70px;
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
  .user-information {
    margin: 16px auto;
    height: 118px;
    @media screen and (max-width: 991px) {
      height: auto;
     }
    .profile-avatar {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 12px auto;
      @media screen and (max-width: 991px) {
       width: 50px;
       height: 50px;
       }
    }
    .user-name {
      font-size: 18px;
      font-family: HelveticaNeueMedium;
      color: #343A40;
      @media screen and (max-width: 991px) {
       display: none;
      }
    }
  }

  .link-list {
    .link {
      color: $colorNavyBlue;
      font-size: 16px;
      display: flex;
      align-items: center;
      margin: 16px 0 16px 8px;
      padding: 0;
      position: relative;
      @media screen and (max-width: 991px) {
        justify-content: center;
      }
      .icon-box {
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }
      .text {
        line-height: 17px;
        padding-top: 6px;
        color: #343A40;
        font-size: 16px;
        font-family: HelveticaNeueMedium;
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  .seprate-line {
    border: 0.5px solid #99a3be;
    margin: 0 8px;
  }
  .active-link {
    background-color: #E4EAF5;
    border-right: 4px solid #21BCE7;
    border-radius: 4px;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   background-color: $colorLightBlue;
    //   width: 4px;
    //   height: 100%;
    //   right: 0;
    //   border-radius: 3px;
    //   margin-right: 2px;
    //   @media screen and (max-width: 991px) {
    //     width: 2px;
    //   }
    // }
  }
}
