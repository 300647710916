.section-container {
	background: url(../../assets/images/register-background.svg) no-repeat;
	background-size: cover;
	position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	& > .box-container {
		width: 793px;
		max-width: 100vw;
		min-height: 523px;
		margin: 76px auto;
		background: rgba(255, 255, 255, 0.5);
		border-width: 1px 0px 0px 1px;
		border-style: solid;
		border-color: rgba(201, 201, 201, 0.3);
		box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 45px 100px;

		& > .title {
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			color: #1f4d91;
			font-family: HelveticaNeueBold;
			margin-bottom: 40px;
		}

		& > .desc {
			color: #343a40;
			font-size: 16px;
			margin-bottom: 74px;
		}

		& > .roles {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 0;
			margin-bottom: 45px;
			gap: 46px;
			cursor: pointer;

			& > .role {
				width: 161px;
				height: 141px;
				border: 1px solid #1e4c90;
				border-radius: 20px;
				padding: 20px 10px;
				display: flex;
				flex-direction: column;
				gap: 24px;
				align-items: center;
				& > p {
					font-family: HelveticaNeueBold;
					font-size: 16px;
					margin-bottom: 0;
				}
			}
		}

		& > .guest {
			font-family: HelveticaNeueBold;
			font-size: 16px;
			line-height: 20px;
			color: #1e4c90;
		}

		& > .welcome-image {
			margin-bottom: 32px;
			margin-top: 50px;
		}

		& > .welcome-title {
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			font-family: HelveticaNeueBold;
			background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			margin-bottom: 20px;
		}

		& > .welcome-desc {
			font-size: 16px;
			letter-spacing: 0.005em;
			color: #343a40;
			margin-bottom: 30px;
		}

		& > .welcome-start {
			background: #1e4c90;
			border-radius: 76px;
			padding: 14px 64px 11px;
			color: #fff;
		}
	}
}
