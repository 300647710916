@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  padding: 24px;

  .application-title {
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    color: #343A40;
    margin-bottom: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title-border {
    border-bottom: 1px solid $colorGray2;
    position: relative;
    margin: 20px 0 20px 0;

    .categories-title {
      font-size: 14px;
      color: $colorLightBlue;
      background-color: $colorWhite;
      left: 0;
      top: -11px;
      padding-right: 10px;
      position: absolute;
    }
  }

  .title-section {
    font-family: HelveticaNeueBold;
    font-size: 18px;    
    color: #343A40;
    margin-bottom: 8px;
  }

  .application-desc {
    color: #343A40;
    font-size: 14px;
    font-family: HelveticaNeueLight;
    margin-bottom: 16px;
  }

  .link {
    background-color: #e9edf4;
    border-radius: 47px;
    padding: 3px 2px 3px 10px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 28px;

    &:hover {
      color: inherit;
    }

    span {
      transform: translateY(2px);
    }

    .download {
      background: white;
      display: inline-block;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      margin-left: 10px;

      img {
        width: 14px;
        transform: translate(5px, 3px);
      }
    }
  }

  .no-data {
    border-radius: 47px;
    padding: 6px 12px 3px;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 28px;
  }

  .more-less {
    color: $colorLightBlue;
    font-size: 14px;
  }

  .waiting-text {
    color: $colorDarkBlue;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 10px;
    border-top: 1px solid $colorGray2;
    margin-top: 10px;
  }

  .seprate-line {
    // border: 0.5px solid $colorGray2;
    margin-bottom: 20px;
  }

  .decline {    
    border-radius: 21px;
    color: $colorred;
    font-size: 18px;
    padding: 9px 0px 4px;
    font-family: HelveticaNeueRoman;
    border: none !important;
  }

  .accept {
    width: 101px;
    border-radius: 78px;
    color: $colorWhite;
    font-size: 18px;
    min-width: 101px;
    padding: 14px 0px 9px;
    font-family: HelveticaNeueRoman;
    background-color: #1E4C90;;
    margin-left: 30px;
    border: none !important;
  }
}