.title {
    font-weight: 500;
    font-size: 16px;
    color: #1E4C90;
    padding-left: 49px;

}

.bill-info {
    padding: 55px 37px 0 125px;
    @media only screen and (max-width: 992px){
        padding-left: 0;
    }
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #21BCE7;
    }

    .edit-bill {
        font-weight: 400;
        font-size: 14px;
        color: #801D54;
      cursor: pointer;

    }
}

.info-wrapper {
    padding-left: 100px;
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    margin-top: 25px;

    @media only screen and (max-width: 992px){
        padding-left: 50px;
    }

    .key {
        color: #85898C;
        min-width: 200px;
    }

    .val {
        color: #1E4C90;
    }
}
.line{
    border-bottom: 1px solid #D6D8D9;
    padding: 42px 0 32px 0;
}