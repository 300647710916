@import "../../styles/devicesize.scss";
@import "../../styles/colors.scss";

.carousel-section {
  margin: auto;
  text-align: center;
  text-align: -webkit-center;
  background-image: url('../../assets/images/background/background-carousel-section.svg');
  background-repeat: no-repeat;
  background-position: 100% 100%;

  & h3 {
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #1F4D91 -82.05%, #22BDE8 32.2%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  & .description {
    color: #1E4C90;
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 0;
  }
}

.carousel-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prevBtn {
  display: block;
  left: -25px;
  position: absolute;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 25%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  &:before {
    content: "\276E";
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #1E4C90;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.nextBtn {
  display: block;
  right: -25px;
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 25%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  &:before {
    content: "\276F";
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #1E4C90;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.carousel-item-keyservice {
  list-style: disc;
  text-align: left;
  color: #1E4C90;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 7px 0;
  cursor: pointer;

  & span {
    margin-right: 30px;
  }

  & img {
    margin-left: -20px;
    color: #22BDE8;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
}

.category-sample-image {
  width: 380px;

  @media screen and (max-width: 1200px) {
    width: 300px;
  }
}

.keyservice-square-top {
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
  transform: translateX(-20px) translateY(-30px);
  border: 1px dashed #21BCE7;
}

.keyservice-square-bottom {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  border: 1px dashed #21BCE7;
  transform: translate(270px, -100px);
  z-index: -1;
  position: absolute;
  padding: 100px 5px 0 5px;
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 1200px) {
    transform: translate(170px, -100px);
  }


}


.carousel-item-keyservice-explore {
  list-style: none;
  text-align: left;
  color: #801D54;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
  margin-left: -10px;


  cursor: pointer;

  & span {
    padding-left: 7px;
    color: #22BDE8;
  }
}

.active-item-content-container {
  // min-height: 390px;
}

.item-wrapper {
  min-height: 290px;
}

.create-request-link {
  width: 100%;
  @media screen and (max-width: 980px) {
    display: flex;
    justify-content: center;
  }

  .select-btn {
    flex-shrink: 0;
    float: left;
    bottom: 0;
    display: flex;
    margin-left: -10px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    max-width: 287px;
    height: 52px;

    &:hover {
      transform: scale(0.9);
    }

    transition-duration: .3s;
    transition-property: transform;
    cursor: pointer;
    margin: 24px;
    padding: 16px 12px 12px;
    text-align: center;
    background: linear-gradient(115.28deg, #21BCE7 28.97%, #1E4C90 164.19%);
    border-radius: 76px;
    background: white !important;
    border: 1px solid !important;
    box-shadow: 0px 2px 8px 0px #21BCE7 !important;
    color: #1E4C90;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    a {
      width: 100%;
    }

    &.deselect {
      background-color: #D2DBE9;
    }
  }

  .disable {
    background: #AEB0B3;
    box-shadow: none;
    cursor: auto;
  }

}

.keyservice-image-col {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}