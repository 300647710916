@import "../../../../styles/colors.scss";

.sorting-box {
  .dropdown-menu {
    width: 135px;
    border: 1px solid $colorDarkBlue;
    top: 25px !important;
    right: 0 !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: auto;
    padding: 0;
    .dropdown-item {
      font-size: 16px;
      color: #5D6166;
      padding: 10px 6px;
      height: 35px;
      cursor: pointer;
      span {
        height: 28px;
      }
    }
  }
  .sort-btn {
    color: #343A40;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    border: none;
    &:focus {
      box-shadow: none;
      border: none;
    }
    &:after {
      margin-left: 5px;
      color: #343A40;
      transform: translateY(4px);
      content: url(../../../../assets/images/arrow/arrow-down.svg);
      border: none;
    }
    &:hover {
      color: #343A40;
    }
  }
  .dropdown-toggle[aria-expanded="true"] {
    &:after {
      transform: rotate(180deg);
    }
  }
}