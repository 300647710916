.container {
    border-radius: 20px;
    border: 1px solid #D6D8D9;
    padding: 36px 30px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            color: #1E4C90;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 34px;

            span {
                margin-left: 18px;
                color: #801D54;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .filter{
            min-width: 180px;
        }
    }

}