@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.company-inventory-card {
  height: 100%;
  @media screen and (max-width: 991px) {
    height: auto;
    margin-bottom: 50px;
  }
  .header-company-inventory {
    background-color: #E9D5E0;
    color: #343A40;
    margin-bottom: 30px;
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .header-txt {
      
      font-size: 20px;
      font-family: HelveticaNeueBold;
    }
  }

  .inventory-item {
    text-align: center;
    padding: 10px;
    margin: 0 10px;
    width: 90px;
    color: #902F65;
    
    @media screen and (max-width: 424px) {
      margin: 0 5px;
     }
    .inventory-count {
      font-size: 36px;
      display: block;
      line-height: 43px;
      font-family: HelveticaNeueBold;      
    }
    .inventory-txt {
      font-size: 14px;
      display: block;
      font-family: HelveticaNeueBold;
      margin-top: 8px;
    }
  }
  
  .solution-manager-box {
    padding: 0 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    .solution-managar {
      font-size: 16px;
      font-family: HelveticaNeueRoman;
      border: none !important;
      background-color: #902F65;
      color: white;
      border-radius: 22px;
      width: 100%;
      height: 48px;
      padding: 14px 12px 11px;
    }
  }
  .manage-payment-box {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 25px;
    margin-top: 30px;
    .manage-payment {
      color: #1E4C90;
      font-family: HelveticaNeueRoman;
      font-size: 16px;
      border: 2px solid #1E4C90 !important;
      border-radius: 73px;
      padding: 14px 0 10px;
      width: 47%;
    }
  }
}
