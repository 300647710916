@import "../../../../styles/colors.scss";
@import "../../../../styles/fonts.scss";

.banner-user-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px ;
  height: 233px;
  
}

.company-banner-card {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  & > img {
    display: block;
    border-radius: 8px;
    width: 122px;
    height: 46px;
  }
  & > h3 {
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 22px;
    padding-left: 12px;
    color: #343A40;
    margin: 0;
    & > span {
      font-family: "HelveticaNeueMedium";
    }
  }
 
}
.welcome-msg {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  margin-bottom: 24px;
  & > h3 {
    font-family: "HelveticaNeueRoman";
    color: #5D6166;
    padding-left: 12px;
    margin-bottom: 0px;
    font-size: 18px;
    margin-top: 5px;
    .hi{
      color: #343A40;
      font-family: "HelveticaNeueMedium";
    }
  }
}
.avatar {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.user-cart-btn {
  padding-top: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: "HelveticaNeueRoman";
  font-size: 18px;
  line-height: 22px;
  border-color: #902F65 !important;
  color: #902F65;
  border-radius: 45px;
  padding: 14px 0px 10px;
  position: absolute;
  margin: 24px;
  bottom: 0;
  left: 0;
  right: 0;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    color: #902F65;
  }
  &.filled {
    font-family: "HelveticaNeueRoman";
    font-size: 18px;
    line-height: 22px;
    background-color: #902F65;
    color: $colorWhite;
    border-radius: 45px;
    height: 48px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: $colorWhite;
    }
  }
}