@import "../../../styles/colors.scss";

.menus {
  width: 100%;
  border: 1.5px solid #1E4C90 !important;
  top: 1px !important;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate(0, 40px) !important;
  padding: 0;
  min-width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 3px solid #fff;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgba(201, 201, 201, 0.3);
  }
  .items {
    font-size: 14px;
    color: #343A40;
    padding: 0.5rem 0.75rem;
    line-height: 16.7px;
    cursor: pointer;
    white-space: normal;
    &:active {
      background-color: inherit;
    }
    span {
      display: flex;
      transform: translateY(3px);
    }
  }
}
.dropdown {
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  .dropdown-button {
    border: 1.5px solid #1E4C90 !important;
    border-radius: 8px;
    padding: 20px 10px 17px 10px;
    font-size: 16px;
    font-weight: 400;
    height: 38px;
    color: #85898C;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: #343A40;
    }
    &:after {
      margin-left: 5px;
      color: #1E4C90;
      border: none;
      content: url(../../../assets/images/arrow/arrow-down.svg);
    }
  }
  .dropdown-button[aria-expanded="true"] {
    &:after {
      transform: rotate(180deg);
      width: 13px;
      height: 13px
    }
  }
 
}
.single-input-error {
  font-size: 10px;
  color: $colorred;
}