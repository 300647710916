@import "../../../styles/colors.scss";

.pagination-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 500px){
    flex-direction: column;
    .pagination{
      justify-content: center;
      padding-top: 10px;
    }
  }
  .pagination {
    margin: 0;
  }
  .found-count{
    color: #85898C;
    font-weight: 400;
    font-size: 14px;
  }
}

.page-link {
    border: 1px solid #1E4C90 !important;
    background-color: transparent;
    border-radius: 4px;
    margin-right: 5px !important;
    min-width: 28px;
    height: 28px;
    font-size: 14px;
    padding: 5px;
    color: #343A40;
    cursor: pointer;
    @media screen and (max-width: 374px) {
      min-width: 25px;
      height: 25px;
       }
}
.page-item.active {
    & .page-link {
      border: 1px solid $colorDarkBlue;
      border-radius: 4px !important;
      background-color: #D2DBE9 !important;
      color: #343A40 !important;
      box-shadow: none !important;
    }
  }
  
  .next-button , .previous-button {
    cursor: pointer;
    display: block;
    padding: 0;
    transform: translateY(-1px);
    margin-top: 3px;
  }
  .previous-button {
    margin-right: 12px;
  }
  .next-button{
    margin-left: 6px;
  }
  .disabled-button {
    pointer-events: none;
  }
