.wrapper-assets {
    display: flex;
    justify-content: end;

    @media only screen and (max-width: 992px) {
        justify-content: center;
    }

    .asset-wrapper {
        text-align: center;
        margin-left: 50px;
        cursor: pointer;

        @media only screen and (max-width: 600px) {
            margin-left: 20px;
        }

        .asset-count {
            font-size: 24px;
            font-weight: 500;
        }

        .asset-title {
            font-size: 14px;
            font-weight: 400;
        }
    }
}