@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";
@import "../../styles/devicesize.scss";

.news-container {
	padding-top: 88px;
	padding-bottom: 60px;
	background: radial-gradient(
		62.4% 260.32% at 73.51% 25.39%,
		rgba(34, 189, 232, 0.19) 0%,
		rgba(30, 76, 144, 0.19) 100%
	);
	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		& > .title {
			font-weight: 700;
			font-size: 32px;
			line-height: 39px;
			font-family: HelveticaNeueBold;
			background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			margin: 0;
			@media screen and (max-width: $widthMobileMax) {
				font-size: 20px;
			}
		}
		& > p {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 40px;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
				text-align: justify;
			}
		}

		& > .news-box {
			& > div {
				max-width: 290px;
			}
			& > div > a {
				width: 100%;
				padding: 0;
				height: 374px;
				background: linear-gradient(
					360deg,
					#ffffff 71.97%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
				border-radius: 20px;
				display: grid;
				grid-template-rows: 65% 35%;
				gap: 12px;
				overflow: hidden;
				&:hover {
					& > div > img {
						transform: scale(1.04);
					}
				}
				& > div {
					& > img {
						transition: all ease-in 100ms;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				& > .items {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 5px;
					padding: 6px 16px;
					& > h2 {
						font-weight: 700;
						font-family: HelveticaNeueBold;
						font-size: 16px;
						line-height: 20px;
						text-align: center;
						color: #445267;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
						@media screen and (max-width: $widthTabletMax) {
							font-size: 14px;
						}
						@media screen and (max-width: $widthMobileMax) {
							font-size: 12px;
						}
					}
					& > h4 {
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
						color: #902f65;
						@media screen and (max-width: $widthMobileMax) {
							font-size: 12px;
						}
					}
					& > p {
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
						text-align: right;
						color: #85898c;
					}
				}
			}
		}

		& > .explore-link {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #1e4c90;
			font-family: HelveticaNeueBold;
			padding: 1rem;
			&:hover {
				color: #22bde8;
			}
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}

		& > .loading-box {
			height: 300px;
			display: flex;
			align-items: center;
			.loading {
				margin: 0px auto;
				width: 36px;
				height: 36px;
				display: block;
			}
		}
	}
}
