@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.company-activity-card {
  height: 100%;

  @media screen and (max-width: 991px) {
    height: auto;
  }

  .header-company-activity {
    background-color: #E4EAF5;
    margin-bottom: 30px;
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .header-txt {
      font-size: 20px;
      font-family: HelveticaNeueBold;
    }
  }

  .activity-item {
    border-radius: 16px;
    text-align: center;
    padding: 25px 10px 10px;
    margin: 0 5px;
    color: #1E4C90;

    @media screen and (max-width: 424px) {
      margin: 0;
      padding: 25px 5px 10px;
    }

    .activity-count {
      font-size: 36px;
      display: block;
      line-height: 43px;
      font-family: HelveticaNeueBold;
    }

    .activity-txt {
      font-size: 14px;
      display: block;
      font-family: HelveticaNeueBold;
    }
  }


  .matchmaking-box {
    margin: 30px 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    background-color: #EBFAF1;
    color: #04622A;
    height: 110px;

    .activity-item {
      padding: 15px 10px 10px;
      .activity-count {
        font-size: 36px;
        display: block;
        line-height: 43px;
        font-family: HelveticaNeueBold;
        color: #04622A;
      }

      .activity-txt {
        font-size: 14px;
        display: block;
        font-family: HelveticaNeueBold;
        color: #04622A;
      }
    }

  }
}