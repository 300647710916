.section {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #343A40;
  }
  .filter-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-box-title {
      display: flex;
      justify-content: start;
      align-items: center;
      color: #1E4C90;
      font-size: 16px;
      font-weight: 500;

      & span {
        margin-left: 7px;
        margin-top: 4px;
      }
    }

    .filter-box-reset {
      display: flex;
      justify-content: end;
      align-items: center;

      .reset-button {
        color: #801D54;
        font-weight: 500;
        font-size: 14px;
        margin-right: 14px;
        margin-top: 6px;
        cursor: pointer;
      }
    }
  }

  .line {
    border-top: 1px solid #D6D8D9;
    margin: 18px 0 22px;
  }
}

.loading {
  width: 60px;
  height: 36px;
  margin-top: 60px;
}

.min-h {
  min-height: 400px;
}

.mt-50 {
  margin-top: 50px;
}

.search{
    margin-top: -25px;
    margin-left: 75%;
    @media screen and (max-width: 840px) {
      & {
        width: 50%;
      }
    }
  }