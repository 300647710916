@import "../../styles/fonts.scss";

.wrapper {
    border: 1px solid #D6D8D9;
    border-radius: 20px;
    padding: 30px 36px;

    .title {
        margin-bottom: 30px;

        &.second {
            margin-top: 50px;
        }
    }

    .item {
        border-radius: 47px;
        padding: 8px 12px 5px;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 12px;
        margin-right: 12px;
        height: 31px;
        background: rgba(33, 188, 231, 0.2);
        color: #000000;
        font-size: 14px;
        &.keyservice{
            background: rgba(30, 76, 144, 0.2);
        }

        &:hover {
            color: inherit;
        }
    }

    .show-more {
        display: inline;
        cursor: pointer;
        color: #1E4C90;
    }
}