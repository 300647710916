@import "../../styles/colors.scss";

.company-card {


  background-image: url(../../assets/images/background/request-card-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;


  border-radius: 8px;
  border: 1px solid #D6D8D9;
  padding: 24px;
  position: relative;
  max-height: 455px;
  display: block;
  cursor: pointer;
  margin-bottom: 14px;

  &:hover {
    color: inherit;
  }

  .company-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 374px) {
      height: 30px;
    }

    .company-logo {
      width: 220px;
      height: 73px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (max-width: 374px) {
        width: 170px;
        height: 60px;
        top: -30px;
      }
    }
  }

  & .title {
    font-size: 16px;
    color: #343A40;
    padding-bottom: 10px;
    width: 100%;
    margin-top: 10px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16.7px;
    font-family: HelveticaNeueRoman;

    @media screen and (max-width: 374px) {
      margin-top: 5px;
      height: auto;
    }

    span {
      padding-top: 5px;
    }
  }

  & .specialities-wrapper {
    min-height: 24px;
    color: #801D54;
    font-size: 14px;
    font-weight: 500;
  }

  .card-info {
    font-size: 12px;
    font-family: HelveticaNeueRoman;
    height: 110px;
    overflow: hidden;
    text-align: left;
    margin-top: 10px;

    

    .key {
      color: #85898C;
      line-height: 13.8px;
      margin-bottom: 10px;
    }

    .value {
      color: #000000;
      line-height: 13.8px;
      display: inline-block;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      background: rgba(33, 188, 231, 0.2);
      border-radius: 30px;
      padding: 9px 10px 5px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .lss-key {
      @media screen and (max-width: 374px) {
        width: 50%;
      }
    }

    .lss-value {
      background-color: #FFF8E6;
      color: #664900;
    }

    .more-value {
      font-weight: bold;
      color: #21BCE7;
      line-height: 13.8px;
    }
  }
  .company-size{
    text-align: left;
    .key {
      color: #85898C;
      font-size: 12px;
      line-height: 13.8px;
      margin-bottom: 7px;
    }
    .company-size-val{
      font-size: 14px;
      color: #1E4C90;
    }
  }
}