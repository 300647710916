@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.card {
  padding: 10px;
  margin-top: 40px;
 // height: 262px;
  .request-box {
    position: relative;
    height: 25px;
    .request-image {
      background-size: cover;
      height: 45px;
      width: 130px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -20px;
      border-radius: 8px;
    }
    .request-header-box {
      position: absolute;
      right: 0px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      .request-status {
        font-size: 12px;
        width: max-content;
      }
      .request-date {
        color: rgba(0, 26, 92, 0.4);
        margin-bottom: 10px;
        font-size: 10px;
      }
    }
  }
  .title {
    font-size: 14px;
    font-family: HelveticaNeueRoman;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    height: 27px;
    overflow: hidden;
    border-bottom: 1px solid #99a3be;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $colorNavyBlue;
  }
  .decription {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    padding-top: 3px;
    overflow: hidden;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-box-align: start;
    color: $colorNavyBlue;
    margin-bottom: 10px;
    height: 49px;
    position: relative;
  }
  .card-info {
    padding-top: 10px;
    border-top: 1px solid #99a3be;
  
    .key-value-box {
     // font-family: HelveticaNeueRoman;
      font-size: 12px;
      margin-bottom: 10px;
      .key {
        color: rgba(0, 26, 92, 0.4);
      }
      .value {
        color: $colorNavyBlue;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .more-value {
        color: rgba(0, 26, 92, 0.4);
      }
    }
  }
}
