@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.search-box {
    border-bottom: 1px solid #1E4C90;;
  // margin-bottom: 20px;
  form {
    position: relative;
    display: flex;
    .search-input {
      height: 35px;
      width: 100%;
      border: none;      
      padding-left: 30px;
      padding-top: 3px;
      font-size: 14px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #1E4C90;
        padding-top: 20px;
      }
    }   

    .search-input:-internal-autofill-selected {
      background-color: $colorWhite;
    }
    
    .btn-submit {
      position: absolute;
      left: 0;
      top: 3px;
      border-radius: 4px;      
      padding: 4px;
      padding-top: 2px !important;
      //  padding: 2px 11px;
      height: 32px;     
      &:focus {
        box-shadow: none;
      }
      span {
        color: $colorWhite;
        padding-left: 10px;
        padding-right: 10px;
        font-family: HelveticaNeueMedium;
      }
    }
  }
}
