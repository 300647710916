@import "../../../styles/colors.scss";

.header {
  background: $colorWhite;
  border-radius: 4px;
  //position: relative;
  position: sticky;
  top: 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  z-index: 9999;
  .navbar {
    z-index: 99;
    padding: 0rem 1rem;
    @media screen and (max-width: 424px) {
     padding: 0.6rem 0;
    }
    .profile-btn {
      background-color: #E4EAF5;;
      border-radius: 34px;
      height: 42px;
      padding: 0;
      width: 75px;
      display: inline-flex;
      align-items: center;
      margin-left: 10px;
     
      &::after {
        content: url(../../../assets/images/arrow/arrow-down.svg);
        border: 0;
        padding-top: 5px;
        margin-left: 10px;
      }
    }
    .profile-btn[aria-expanded="true"] {
      &:after {
        transform: rotateZ(180deg);
      }
    }
    .avatar {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
    .navbar-toggler {
      
      &:focus {
        box-shadow: none;
      }
      .menu {
        color: $colorDarkBlue;
      }
    }
    .desktop-menu {
      right: 0;
      left: auto !important;
    }
  }
  .header-logo {
    width: 190px;
    @media screen and (max-width: 374px) {
      width: 150px;
    }
  }
  .banner {
    background-color:rgba(128, 29, 84, 1);
    color: white;
    padding: 10px 20px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-family: "HelveticaNeue";
    width: 100%;
    align-self: center;
    
  }
  
  .banner-content {
    margin-left: 25%;
    display: flex;
    align-items: center;
    
  }
  
  .icon {
    color: white;
    margin-right: 10px;
    font-size: 20px;
  }
  
  .banner-text {
    font-size: 16px;
    margin-right: 5px;
    font-weight: 700;
    font-family: "HelveticaNeue";
  }
  .banner-text strong{
    color:rgba(255, 238, 87, 1);
    ;
  }
  
  .banner-subtext {
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    font-family: "HelveticaNeue";
  }
  
  .learn-more-btn {
    background-color: white;
    color: rgba(128, 29, 84, 1);
    border: 1px;
    padding: 5px 30px;
    border-radius: 76px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }
  
  .learn-more-btn:hover {
    background-color: #f2f2f2;
  }
}
