@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";


.card {
  width: 140%;
  padding: 24px;
  margin-bottom: 20px;
  min-height: 180px;
  max-width: 844px;
  border: none;
  border-bottom: 1px solid #D6D8D9;

  @media screen and (max-width: 840px) {
    width: 140%;
    & {
      padding: 0;
    }

  }

  .news-box {
    //position: relative;
    //height: 48px;
    //display: flex;
    .news-image {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
      background-size: cover;
      height: 141px;
      width: 141px;
      //min-width: 48px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;

      @media screen and (max-width: 1440px) {
        & {
          height: 100px;
          width: 100px;
        }
      }

      @media screen and (max-width: 1280px) {
        & {
          height: 80px;
          width: 80px;
        }
      }

      @media screen and (max-width: 840px) {
        & {
          height: 115px;
          width: 115px;
        }
      }
      @media screen and (max-width: 480px) {
        & {
          height: 95px;
          width: 95px;
        }
      }
      @media screen and (max-width: 420px) {
        & {
          height: 75px;
          width: 75px;
        }
      }
    }
  }

  .news-header-box {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    color: #85898C;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news-date {
    }

    .news-category {

    }
  }

  .title {
    font-size: 24px;
    font-family: "HelveticaNeue";
    font-weight: 500;
    line-height: 30px;
    margin-top: 6px;
    //padding-left: 12px;
    //max-width: 85%;
    //height: 40px;
    overflow: hidden;
    color: #343A40;
    @media screen and (max-width: 480px) {
      & {
        font-size: 16px;

      }
    }

    span {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .decription {
    font-size: 14px;
    padding-top: 8px;
    height: 78px;
    font-family: "HelveticaNeue";
    color: #343a40;
    line-height: 22px;
    overflow: hidden;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .news-details {
    @media screen and (max-width: 840px) {
      & {
        margin-left: 0;

      }
    }
  }


}


.card_slider {
  padding: 0;
  margin-bottom: 20px;
  min-height: 180px;
  max-width: 844px;

  .news-box-slider {
    //position: relative;
    //height: 48px;
    //display: flex;
    .news-image-slider {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
      background-size: cover;
      height: 95px;
      width: 95px;
      //min-width: 48px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
      @media screen and (max-width: 1000px) {
        & {
          height: 75px;
          width: 75px;
        }
      }

    }
  }

  .news-header-box {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    color: #85898C;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news-date {
    }

    .news-category {

    }
  }

  .title {
    font-size: 18px;
    font-family: HelveticaNeueMedium;
    line-height: 20px;
    margin-top: 6px;
    //padding-left: 12px;
    //max-width: 85%;
    //height: 40px;
    overflow: hidden;
    color: #343A40;
    @media screen and (max-width: 480px) {
      & {
        font-size: 16px;

      }
    }

    span {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .decription {
    font-size: 14px;
    padding-top: 8px;
    height: 68px;
    font-family: HelveticaNeueRoman;
    color: #343a40;
    line-height: 20px;
    overflow: hidden;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .news-details-slider {
    margin-left: 0;
  }


}

