@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.free-banner {
    background-color: rgba(31, 77, 145, 1);

    color: white;
    padding: 30px;
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
    // background-image: url('../../assets/images/background/greatnews.svg');
  }
  
  /* Header Styling */
  .banner-title {
    font-size: 32px;
    font-weight: 700;
    font-family: "HelveticaNeue";
  }
  
  .banner-text {
    font-size: 24px;
    font-weight: 400;
    margin: 10px 0;
    font-family: "HelveticaNeue";
  }
  
  /* Highlight for 'Completely Free' */
  .highlighted {
    color: white; /* Similar to the dark pink/maroon in the image */
    background: rgba(128, 29, 84, 1);
    padding: 3px 6px;
    border-radius: 5px;
  }
  
  /* Feature List Styling */
  .features {
    // margin-top: 40%;
    // margin-left: 50%;
    // width: 50%;
  }
  
  .feature-item {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 1% 20%;
    font-size: 18px;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 24px;
  }
  
  .free-tag {
    background-color: #8faccb;
    color: white;
    padding: 3px 8px;
    margin-left: 10px;
    border-radius: 5px;
  }
  
  /* 'Free' Graphic Styling */
  .free-graphic {
    width: 50%;
    position: absolute;
    right: 30px;
    top: 40%;
    transform: translateY(-50%);
  }
  
  .free-bubble {
    background-color: white;
    color: #83245f;
    font-size: 40px;
    font-weight: bold;
    padding: 20px 30px;
    border-radius: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Footer Text Styling */
  .footer-text {
    font-size: 24px;
    font-weight: 400;
    color:rgba(255, 255, 255, 1);
    margin-top: 5%;
    font-family: "HelveticaNeue";
  }
  