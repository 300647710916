@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.modal-title {
    color: #1E4C90;
    font-size: 16px;
    font-family: HelveticaNeue;
    font-weight: 500;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description-wrapper {   
    max-height: 70vh;
    overflow-y: scroll;

    .step-card{
        margin: 10px;
    }
    .company-size-container{
        max-width: 825px;
        margin: 0 auto;
    }
    .line{
        border-top: 1px solid rgba(120, 148, 188, 0.29);;
        margin-top: 25px;
        margin-bottom: 38px;
    }
    .section-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1E4C90;
    }
    
    .section-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #1E4C90;
        margin-top: 7px;
    }
    .input-container{
        max-width: 696px;
        margin: 0 auto; 
        padding: 0;   
        padding-top: 26px;
      }
      .input-container-social{
        padding-top: 38px;
      }
      @media only screen and (min-width: 1200px) {
        .admin-phone-code{
          padding-right: 0 !important;
          min-width: 132px;
        }
        .admin-phone-container{
          padding-left: 10px !important;
          max-width: 215px;
        }
      }

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 3px solid #fff;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: rgba(201, 201, 201, 0.3);
    }
}
.action-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .cancel{
        background-color: white;
        border: none;
        color: #1E4C90;
        font-size: 16px;
        font-weight: 500;
    }
    .submit {
        border-radius: 28px;
        font-size: 16px;
        background-color: $colorDarkBlue;
        color: $colorWhite;
        font-family: HelveticaNeueMedium;
        width: 145px;
        padding: 15px 0 10px;
        border: none;
    
        @media screen and (max-width: 575px) {
            margin-top: 20px;
        }
    
        &:hover {
            color: $colorWhite;
            background-color: $colorDarkBlue;
            border: none !important;
        }
    
        &:focus {
            color: $colorWhite;
            background-color: $colorDarkBlue;
            border: none;
        }
    }
}

.min-h {
    min-height: 80vh;
}

.loading {
    width: 50px;
    height: 50px;
    padding: 0;
}

.loading-box {
    text-align: right;
    height: 23px;
    display: inline-block;
    margin-left: 10px;
    padding-top: 7px;

    .loading {
        width: 23px;
        height: 23px;
    }
}