.container {
  // width: 100%;
  // position: absolute;
  // height: 551px;
  // padding: 40px 20px;
  // background-color: #f9fafc;
}

.latest_news_item {
  margin-left: 20%;
  margin-right: 30%;

  .news-image {
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #343A40;
  // margin-top: 58px;
}

.newsGrid {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  margin-top: 58px;
  // margin-left: 100px;
  // margin-right: 146px;

}

.newsItemLink {
  text-decoration: none;
  color: inherit;
  // width: 47%;
}

.newsItem {
  // max-width: 564px;
  background: #fff;
  border-radius: 1px;
  border-color: #B4B4B4;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 93px;
  margin-top: 30px;
  // gap: 2px;
  // margin-left: 111px;
}

.newsTitle {
  font-size: 24px;
  font-weight: 500;
  color: #343A40;
  margin-bottom: 10px;
  margin-left: 13px;
  margin-top: 70px;
}

.newsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsDescription {
  font-size: 16px;
  color: #555;
  flex: 1;
  margin-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 6em;
  margin-left: 13px;
}

.newsImage {
  margin-right: 23px;
  margin-left: 10px;
  // margin-bottom: 2px;
  border-radius: 4px;
  object-fit: fill;
}

.newsMeta {
  display: flex;
  justify-content: space-between;
  // margin-top: 20px;
  font-size: 14px;
  margin-left: 13px;
  color: #85898C;
  font-weight: 400;
}

// .newsDate {
//   font-style: italic;
// }

.newsSource {
  // font-weight: bold;
  margin-right: 185px;
  margin-bottom: 28px;
}

@media (max-width: 1440px) {
  .newsGrid {
    flex-wrap: wrap;
  }

  .newsItem {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 24px;
  }

  .newsTitle {
    font-size: 20px;
  }

  .newsDescription {
    font-size: 14px;
  }

  .newsImage {
    height: 100px;
  }

  .newsGrid {
  }
}

@media screen and (max-width: 575px) {
  .title {
    font-size: 24px;
  }

  .newsTitle {
    font-size: 20px;
  }

  .newsDescription {
    font-size: 14px;
  }

  .newsImage {
    height: 100px;
  }

  .newsGrid {
  }
}