@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.experience-section {
  // padding: 160px 0;
  margin: auto;

  .experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: auto !important;

    &>h2 {
      font-weight: 700;
      font-size: 32px;
      background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-family: "HelveticaNeueBold";

      @media screen and (max-width: $widthTabletMax) {
        font-size: 32px;
      }

      @media screen and (max-width: $widthMobileMax) {
        font-size: 20px;
      }
    }

    &>p {
      font-weight: 700;
      font-size: 20px;
      color: #1e4c90;

      @media screen and (max-width: $widthTabletMax) {
        font-size: 16px;
      }

      @media screen and (max-width: $widthMobileMax) {
        font-size: 12px;
      }
    }

    &>.steps-container {
      display: flex;

      &>.image {
        // background-image: url(../../assets/images/home/howwork-bg.png);
        // padding-bottom: 70px;
        // padding-top: 30px;
        position: relative;
        // width: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        &>img {
          width: 100%;
          min-height: 522px;
        }
      }

      &>.steps-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 40px;
        padding-left: 0;

        &>li,
        div {
          width: 100%;
          height: max-content;
          border-radius: 27px;
          border: solid 1px #22bde8;
          padding: 16px 33px 16px 99px;
          transition: all ease-in-out 200ms;
          position: relative;

          &>h3 {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 8px;
            color: #1e4c90;
          }

          &>p {
            display: none;
            font-weight: 400;
            font-size: 16px;
            color: #445267;
            margin-bottom: 0;

            @media screen and (max-width: $widthTabletMax) {
              font-size: 14px;
            }

            @media screen and (max-width: $widthMobileMax) {
              display: block;
              font-size: 12px;
            }
          }

          &>span {
            width: 57px;
            height: 57px;
            border-radius: 50%;
            text-align: center;
            position: absolute;
            top: -25px;
            left: 30px;
            background-color: #fff;
            font-weight: 700;
            font-size: 55px;
            color: #1e4c90;
            font-family: "HelveticaNeueBold";
          }

          &.active {
            min-height: 120px;
            height: max-content;
            border-radius: 60% 27px 27px 60% / 185% 27px 27px 185%;

            &>p {
              display: block;
            }

            @media screen and (max-width: $widthMobileMax) {
              border-radius: 27px;
              min-height: unset;
            }
          }
        }
      }
    }
  }

  .create-request-link {

    width: 287px;
    height: 52px;
    flex-shrink: 0;
    float: left;
    bottom: 0;
    display: flex;
    margin-left: -10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;



    .select-btn {
      &:hover {
        transform: scale(0.9);
      }

      transition-duration: .3s;
      transition-property: transform;
      cursor: pointer;
      margin: 24px;
      padding: 16px 12px 12px;
      width: 100%;
      text-align: center;
      background: linear-gradient(115.28deg, #21BCE7 28.97%, #1E4C90 164.19%);
      border-radius: 76px;
      background: white !important;
      border: 1px solid !important;
      box-shadow: 0px 2px 8px 0px #21BCE7 !important;
      color: #1E4C90;
      font-family: "HelveticaNeue";
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      a {
        width: 100%;
      }

      &.deselect {
        background-color: #D2DBE9;
      }
    }

    .disable {
      background: #AEB0B3;
      box-shadow: none;
      cursor: auto;
    }

  }

  .how-it-works-btn {
    z-index: 10;
    margin-top: -80px;

    @media screen and (max-width: $widthMobileMax) {
      margin-top: -10px;
    }
  }

}