@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";
@import "../../../styles/devicesize.scss";

.banner-section {
  & > .banner-container {
    position: relative;

    & > .banner-svg-normal {
      display: block;
      @media screen and (min-width: 2001px) {
        display: none;
      }

      & > svg {
        display: block;
        width: 100vw;
        padding: 0;
      }
    }

    & > .banner-svg-xxl {
      & > img {
        display: block;
        width: 100vw;
        padding: 0;
      }

      @media screen and (max-width: 2000px) {
        display: none;
      }
    }

    & > .title {
      position: absolute;
      max-width: 854px;
      margin: 0 8px;
      height: 100%;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      & > h1 {
        color: #ffffff;
        font-weight: 700;
        font-size: 3rem;
        line-height: 61px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "HelveticaNeue";
        font-style: normal;
        @media screen and (max-width: $widthTabletMax) {
          font-weight: 700;
          font-size: 2rem;
          line-height: 41px;
        }
        @media screen and (max-width: $widthMobileMax) {
          font-weight: 700;
          font-size: 1rem;
          line-height: 18px;
        }

        & > span {
          display: block;
          color: #22bde8;
        }
      }

      & > p {
        color: #FFF;
        text-align: center;
        font-family: "HelveticaNeue";
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: $widthTabletMax) {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        @media screen and (max-width: $widthMobileMax) {
          font-weight: 700;
          font-size: 9px;
          line-height: 16px;
        }
      }
    }
  }

  & > .link {
    position: relative;
    z-index: 1;
    background: linear-gradient(115.28deg, #21bce7 28.97%, #1e4c90 164.19%);
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 8px #21bce7;
    border-radius: 76px;
    font-weight: 700;
    font-size: 20px;
    padding: 20px 50px;
    padding-top: 26px;
    color: #ffffff;
    margin-top: -38.3px;
    @media screen and (max-width: $widthTabletMax) {
      font-weight: 700;
      font-size: 16px;
      padding: 10px 25px;
      padding-top: 15px;
      margin-top: -25.3px;
    }
    @media screen and (max-width: $widthMobileMax) {
      font-weight: 700;
      font-size: 12px;
      padding: 10px 25px;
      padding-top: 15px;
      margin-top: -22.3px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(268.8deg, #204b90 7.35%, #22bde8 109.57%);
      box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
      border-radius: 76px;
      color: #fff;
      opacity: 0;
      transition: opacity 0.4s;
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
    }
  }
}

// .banner {
//   .title {
//     color: #343A40;
//     font-family: 'HelveticaNeueBold';
//     margin-bottom: 30px;
//     font-size: 36px;
//     @media screen and (max-width: 767px) {
//       font-size: 20px;
//     }
//   }
//   .banner-desc {
//     font-size: 18px;
//     font-weight: 400;
//     margin-bottom: 30px;
//     color: #343A40;
//     font-family: 'HelveticaNeueMedium';
//   }
//   .video-box {
//     position: relative;
//     text-align: right;
//     margin-bottom: 20px;
//     .video-frame {
//       width: 85%;
//       height: auto;
//     }
//     .video-cover {
//       position: absolute;
//       right: 0;
//       left: 0;
//       top: 0;
//       transform: translate(8%, -5%);
//       border-radius: 8px;
//       width: 90%;
//       height: 100%;
//     }
//     .play-button {
//       cursor: pointer;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-30%, -60%);
//       width: 84px;
//       height: 84px;
//       background: #3c629e;
//       border-radius: 50%;
//       opacity: 0.7;
//       display: none;
//       @media screen and (max-width: 767px) {
//         width: 64px;
//       height: 64px;
//       }
//       &:hover {
//         opacity: 1;
//       }
//     }
//     .play-icon {
//       position: absolute;
//       color: white;
//       font-size: 40px;
//       top: 50%;
//       left: 50%;
//       transform: translate(-40%, -50%);
//       @media screen and (max-width: 767px) {
//         font-size: 30px;
//       }
//     }
//     iframe {
//       position: absolute;
//       right: 0;
//       left: 0;
//       top: 0;
//       transform: translate(8%, -5%);
//       border-radius: 8px;
//       width: 90%;
//       height: 100%;
//     }
//   }
// }
