.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;

    .title {
        font-weight: 500;
        font-size: 16px;
        color: #1E4C90;
    }

    .history {
        font-weight: 400;
        font-size: 14px;
        color: #801D54;
    }
}

.plan {
    font-weight: 400;
    font-size: 16px;
    color: #21BCE7;
    margin-bottom: 32px;
}

.details {
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 26px;

    

    .key {
        color: #85898C;
        min-width: 160px;
    }

    .val {
        color: #1E4C90;
    }
}

.pay-btn-wrapper {
    position: absolute;
    right: 48px;
    bottom: 27px;

    .pay-btn {
        background: #1E4C90;
        border-radius: 76px;
        color: white;
        padding: 12px 24px 8px;
    }
}