@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.wrapper {
  background: linear-gradient(89.99deg, rgba(30, 75, 144, 0.3) 25.99%, rgba(33, 188, 231, 0.189) 90.33%);
  flex: none !important;

  .request-status {
    display: flex;
    justify-content: start;
    @media only screen and (max-width: 991px) {
      justify-content: center;
    }
    align-items: center;
    height: 100%;
    min-height: 120px;

    .status-badge {
      padding: 7px 10px 1px 10px;
      border-radius: 14px;
      font-size: 14px;
      margin-right: 5px;
    }

    .request-title {
      color: #343A40;
      font-weight: 700;
      font-size: 20px;
      font-family: 'HelveticaNeue';
      padding-top: 5px;
    }

  }

  .buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;

    @media only screen and (max-width: 991px) {
      justify-content: center;
      margin-bottom: 20px;
    }

    button,
    a {
      padding: 0.57rem 16px 0.25rem;
      color: $colorWhite;
      border-radius: 45px;
      font-size: 16px;
      font-weight: 400;
      font-family: 'HelveticaNeue';
      height: 48px;
      line-height: 33px;

      &:focus {
        box-shadow: none;
      }
    }

    .app-remain {
      font-size: 12px;
      display: block;
      margin-top: 7px;
      color: #902F65;
      font-weight: 500;
      font-family: 'HelveticaNeue';
      margin-right: 16px;
    }
  }

  .send-button {
    background-color: #1E4C90;
  }

  .recieved-app {
    font-size: 12px;
    font-family: HelveticaNeueMedium;
    color: #902F65;
    margin-top: 8px;
    margin-right: 15px;
  }


  .header-card {
    position: relative;

    .company-logo {
      border-radius: 8px;

      width: 173px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

  }


  .card-info {
    padding: 20px 0 0px;
    margin-top: 20px;

    .item {
      margin-bottom: 10px;
      line-height: 20px;

      &:nth-child(2) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .key {
        font-size: 12px;
        color: #85898C;

        font-family: HelveticaNeueRoman;
      }

      .value {
        font-size: 12px;
        color: #001A5C;
        font-weight: 400;
        font-family: HelveticaNeueRoman;
      }

      .region {
        font-size: 12px;
        background: #E4EAF5;
        color: #013480;
        padding: 2px 10px 0px;
        border-radius: 14px;
        margin-right: 10px;
        display: inline-block;
        transform: translateY(-1px);
        margin-bottom: 5px;
        font-weight: 400;
        font-family: HelveticaNeueRoman;
      }

      .no-data {
        border-radius: 8px;
        padding: 5px 10px 2px;
      }
    }
  }


  .show-request-button {
    background-color: #1E4C90;;
  }


  button:disabled,
  button[disabled] {

    background-color: #AEB0B3 !important;

  }

  .delete-app-button {
    color: $colorred;
    margin-top: 15px;
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    padding: 0;
  }


  .request-message {
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.views {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #34A853;


  & strong {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }


}