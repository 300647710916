@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.modal-title {
    color: #1E4C90;
    font-size: 16px;
    font-family: HelveticaNeue;
    font-weight: 500;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description-wrapper {  
    max-height: 70vh;
    overflow-y: scroll;

    .step-card{
        margin: 10px;
    }
    .company-size-container{
        max-width: 825px;
        margin: 0 auto;
    }
    .line{
        border-top: 1px solid rgba(120, 148, 188, 0.29);;
        margin-top: 25px;
        margin-bottom: 38px;
    }
    .section-title {
        margin-top: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1E4C90;
    }
    
    .section-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #1E4C90;
        margin-top: 7px;
    }
    .input-container{
        max-width: 676px;
        margin: 0 auto; 
        padding: 0;   
        padding-top: 26px;
      }
      .input-container-social{
        padding-top: 38px;
      }
      @media only screen and (min-width: 1200px) {
        .admin-phone-code{
          padding-right: 0 !important;
          min-width: 132px;
        }
        .admin-phone-container{
          padding-left: 10px !important;
          max-width: 215px;
        }
      }

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 3px solid #fff;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: rgba(201, 201, 201, 0.3);
    }
}
.action-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .cancel{
        background-color: white;
        border: none;
        color: #1E4C90;
        font-size: 16px;
        font-weight: 500;
    }
    .submit {
        border-radius: 28px;
        font-size: 16px;
        background-color: $colorDarkBlue;
        color: $colorWhite;
        font-family: HelveticaNeueMedium;
        width: 145px;
        padding: 15px 0 10px;
        border: none;
    
        @media screen and (max-width: 575px) {
            margin-top: 20px;
        }
    
        &:hover {
            color: $colorWhite;
            background-color: $colorDarkBlue;
            border: none !important;
        }
    
        &:focus {
            color: $colorWhite;
            background-color: $colorDarkBlue;
            border: none;
        }
    }
}

.min-h {
    min-height: 80vh;
}

.loading {
    width: 50px;
    height: 50px;
    padding: 0;
}

.loading-box {
    text-align: right;
    height: 23px;
    display: inline-block;
    margin-left: 10px;
    padding-top: 7px;

    .loading {
        width: 23px;
        height: 23px;
    }
}








.step-card {
  background: #FFFFFF;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: rgba(201, 201, 201, 0.3);
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 44px 99px;

  .section-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1E4C90;
  }

  .section-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1E4C90;
    margin-top: 7px;
  }



  .custom-input-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E4C90;
    margin-top: 39px;
    margin-bottom: 14px;
  }
  .custom-input-label-category{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E4C90;
    margin-bottom: 14px;
  }
  .line {
    border: 1px solid rgba(120, 148, 188, 0.29);
    margin-top: 32px;
    margin-bottom: 36px;
  }
  
}

.submit {
  border-radius: 28px;
  background-color: $colorDarkBlue;
  color: $colorWhite;
  font-family: HelveticaNeueRoman;
  width: 190px;
  padding: 14px 0 9px;
  margin-top: 27px;
  border: none;

  @media screen and (max-width: 575px) {
    width: 125px;
  }

  &:disabled {
    background-color: #AEB0B3;
    color: white;
  }

  &:hover {}

  &:focus {}
}

.back {
  color: #1E4C90;
  font-family: HelveticaNeueMedium;
  padding: 10px 0 5px;
  margin-top: 27px;

  img {
    margin-right: 16px;
    margin-top: -3px;
  }

  &:hover {
    color: $colorDarkBlue;
  }

  @media screen and (max-width: 575px) {
    width: 125px;
  }
}

.invoice-form {
  margin-top: 43px;
  text-align: center;
  color: #403F3F;

  .form-check-label {
    font-size: 14px;
  }

  .form-check-input {
    margin-top: 2px;
    border: 2px solid #343A40;
    border-radius: 2px;
    float: none;
    margin-right: 15px;

    &:checked{
      border: none;
      background-color: #1E4C90;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .privacy-terms {
    // font-family: HelveticaNeueItalic;
    color: #1E4C90;
  }

  .invoice-submit {
    margin-top: 15px;
    background-color: $colorDarkBlue;
    border-radius: 50px;
    color: #ffffff;
    display: block;
    padding: 10px 25px 5px;
    font-family: HelveticaNeueMedium;

    &:focus {
      box-shadow: none;
    }
  }
}

.loading-box {
  text-align: right;
  height: 35px;

  .loading {
    width: 30px;
    height: 30px;
    margin-right: 65px;
    margin-top: 5px;
  }
}


.Certificates {
  font-size: 12px;
  margin-bottom: 10px;

  span {
      font-family: HelveticaNeueMedium;
  }
}