@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.section {
  margin-top: 55px;
  margin-bottom: 100px;

  .create-request-card {
    padding: 34px 24px;
    border-top-left-radius: 0px;
    .title {
      font-family: HelveticaNeueMedium;
      position: absolute;
      top: -33px;
      left: -1px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      color: white;
      font-size: 14px;
      padding: 5px 15px;
      width: 180px;
      text-align: center;
      line-height: 20px;
      display: flex;
      justify-content: center;
      height: 33px;
      align-items: end;
      background-color: #4b70a6;
      span {
        line-height: 17px;
      }
    }
  }

  .select-category {
    // margin-top: 30px;
  }

  .DueDate {
    width: 100%;
    max-width: 267px;
    float: right;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      max-width: none;
    }
  }
  .fileInputButton {
    // max-width: 252px;
    // @media screen and (max-width: 768px) {
    //   max-width: none;
    // }
  }
  .request-size-title {
    font-family: HelveticaNeueBold;   
    
    font-size: 18px;
    line-height: 22px;  
    color: #343a40;
    margin-top: 24px;
  }
  .firstText {
    font-family: HelveticaNeueRoman;
    font-size: 14px;
    line-height: 20px;
    color: #5D6166;
    margin-top: 12px;
  }

  .congratulationText {
    font-family: HelveticaNeueRoman;    
    font-size: 12px;
    line-height: 20px;
    color: #343A40;
    margin-top: 24px;
  }

  .submit {
    border-radius: 76px;
    background-color: #1E4C90;
    color: $colorWhite;
    font-family: HelveticaNeueRoman;
    max-width: 180px;
    width: 100%;
    padding: 6px 0 10px;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      max-width: none;
    }
    &:hover {
      color: $colorWhite;
    }
  }
  .draftButton {
    background-color: transparent;
    color: #1E4C90;    
    font-family: HelveticaNeueMedium;
    width: 100%;
    max-width: 160px;
    margin-top: 47px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      max-width: none;
    }
    &:hover {
      // color: $colorWhite;
    }
  }

  .col-lg-equal {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-xs-equal {
    width: 20%;
    float: left;
  }

  @media (min-width: 1200px) {
    .col-lg-equal {
      width: 20%;
      float: left;
    }
  }



}

.min-h {
  min-height: 400px;
}

.loading{
  width: 50px;
  height: 50px;
  padding: 0;
}

.loading-box {
  text-align: right;
  height: 23px;
  display: inline-block;
  margin-left: 10px;
  padding-top: 7px;
  
  .loading {
    width: 23px;
    height: 23px;
    
  }
}
