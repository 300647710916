@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.success-stories {
  text-align: center;
  background: linear-gradient(104.26deg, rgba(30, 76, 144, 0.7) 5.5%, rgba(33, 188, 231, 0.7) 94.5%);

  & h2 {
      font-size: 32px;
      font-weight: 700;
      color: white;
      margin-top: 1%;
      font-family: "HelveticaNeue";
  }

  & p {
      font-size: 20px;
      font-weight: 500;
      color: white;
      margin-bottom: 5%;
      font-family: "HelveticaNeue";
  }

  

  .story-container {
    display: flex;
    justify-content: space-between;
    background: white;
    margin-left: 15%;
    margin-bottom: 5%;
    width: 70%;
    height: 100%;
    border-radius: 15px;
    position: relative;

    .story-background-r{
      width: 3%;
      height: 60%;
      background-color: rgba(32, 75, 144, 0.15);
      right: 98.5%;
      top: 20%;
      // display: flex;
      position: absolute;
      justify-content: left;
      // left: 50%;
      transform: translateX(-50%);
      border-radius: 15px 0 0 15px;
  
    }

    .story-background-l{
      width: 3%;
      height: 60%;
      background-color: rgba(32, 75, 144, 0.15);
      left: 101.5%;
      top: 20%;
      display: flex;
      position: absolute;
      justify-content: right;
      // left: 50%;
      transform: translateX(-50%);
      border-radius: 0 15px 15px 0;
  
    }

    .story-background-l-l{
      width: 15%;
      height: 105%;
      background: linear-gradient(180deg, rgba(47, 207, 111, 0.2) 0%, rgba(30, 188, 232, 0.2) 60.5%);
      left: 92.5%;
      // margin-top: 7%;
      display: flex;
      position: absolute;
      justify-content: right;
      transform: translateX(-50%);
      border-radius: 0 15px 15px 0;
  
    }

    // Center the logo at the top of the story container
    .company-logo {
      width: 15%;
      margin-top: 2%;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      position: absolute;
      // top: -50px; // Position the logo on top of the story container
      left: 50%;
      transform: translateX(-50%);
    }

    .story-left {
      // flex: 1;
      // padding: 10px;
      margin-top: 5%;
      height: 100%;
      width: 25%;

      blockquote {
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        // width: 110%;
        // height: 110%;
        // padding-top: 15%;
        justify-content: center;
        font-family: "HelveticaNeue";
        // justify-items: center;
        
      }

      blockquote:after,
      blockquote:before {
        color: green;
        font-size: 80px;
        line-height: 1;
        position: absolute;
        // padding-top: 15%;
      }

      blockquote:after {
        content: close-quote;
        vertical-align: text-top;
        margin: 2%;
      }

      blockquote:before {
        content: open-quote;
        vertical-align: top;
        margin-left: -15%;
      }
    }

    .story-right {
      // flex: 2;
      max-width: 60%;
      // padding: 10px;
      text-align: left;
      // color: #445267;
      margin-top: 5%;
      top: 40%;
      padding-right: 8%;

      & p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
        margin-top: 20px;
        color: rgba(68, 82, 103, 1);
        font-family: "HelveticaNeue";
      }

      & ul {
        list-style-type: disc;
        
    }
    & li{
      color: rgba(68, 82, 103, 1);
      font-size: 16px;
        font-weight: 400;
    }
    }
  }

  .story-pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    // background: rgba(30, 76, 144, 1);
    // border: 1px solid rgba(255, 255, 255, 1);

    input[type='radio'] {
      margin: 0 5px;
      cursor: pointer;
      background: inherit;
      

    }
  }
}
