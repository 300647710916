.section-container {
    background: url(../../../assets/images/register-background.svg) no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    &>.form-container {
        width: 793px;
        max-width: 100vw;
        min-height: 523px;
        margin: 76px auto;
        background: rgba(255, 255, 255, 0.5);
        border-width: 1px 0px 0px 1px;
        border-style: solid;
        border-color: rgba(201, 201, 201, 0.3);
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(2px);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;

        &>.icon {
            position: relative;
            width: 76px;
            height: 76px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;


        }

        &>.title {
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            font-family: HelveticaNeueBold;
            color: #1f4d91;
            margin-bottom: 12px;
            margin-top: 25px;
        }

        &>.desc {
            font-size: 16px;
            letter-spacing: 0.005em;
            color: #343a40;
            margin-bottom: 39px;
            text-align: center;
        }

        &>.home-btn {
            padding: 12px 24px 8px;
            width: 275px;
            height: 48px;
            color: #fff;
            background: #1e4c90;
            border-radius: 76px;
            margin-bottom: 24px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
    }
}