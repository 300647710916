.card {
  border-top-left-radius: 0px;
  margin-top: 33px;
}

.title {
  font-family: HelveticaNeueMedium;
  position: absolute;
  top: -33px;
  left: -1px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: white;
  font-size: 14px;
  padding: 10px 15px 6px;
  width: 114px;
  text-align: center;
  line-height: 20px;
  display: flex;
  justify-content: center;
  height: 33px;
  align-items: end;
  background-color: #4B70A6;

  span {
    line-height: 17px;
  }
}

.body {
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  margin: 24px;
  padding-top: 10px;
}

.form-check {
  margin-top: 24px;
}
  .form-check-label {
    font-size: 14px;
    font-family: HelveticaNeueRoman;

    @media screen and (max-width: 767px) {
      font-size: 14px;

    }
  }

  .form-check-input {
    margin-top: 2px;
    border-color: #343A40;
    border-width: 2px;
    border-radius: 2px;

    &:focus {
      box-shadow: none;
    }
  }

  .form-check input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-check label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #343A40;
  }

  .form-check label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #343A40;
    border-radius: 3px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    transform: translateY(-2px);
  }

  .form-check input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 9px;
    border: solid #343A40;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .privacy-terms {
    font-family: HelveticaNeueRoman;
    font-size: 14px;
    color: #902F65;
  }
