@import "../../styles/colors.scss";

.form-comment {
  margin-top: 20px;
  text-align: right;
  .input {
    padding: 10px 15px;
    border:1px solid #1E4C90;
    font-size: 14px;
    min-height: 67px;
    &:focus {
      box-shadow: none;
      border-color: $colorDarkBlue;
    }
  }
  .submit {
    color: $colorWhite;
    background-color: #1E4C90;
    border-radius: 56px;
    padding: 14px 30px 10px;
    font-size: 18px;
    line-height: 18px;
    margin-top: 20px;
    &:hover {
      color: $colorWhite;
      // background-color: $colorGreen;
    }
    &:focus {
      color: $colorWhite;
      // background-color: $colorGreen;
      box-shadow: none;
    }
  }
}
