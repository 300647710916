.section-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(../../../assets/images/register-background.svg) no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	& > .form-container {
		width: 793px;
		max-width: 100vw;
		height: 508px;
		padding: 53px;
		background: rgba(255, 255, 255, 0.5);
		border-width: 1px 0px 0px 1px;
		border-style: solid;
		border-color: rgba(201, 201, 201, 0.3);
		box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
		// justify-content: space-between;

		& > .title {
			margin-bottom: 46px;
			// font-family: HelveticaNeueBold;
			// display: flex;
			// align-items: center;
			// gap: 7px;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			text-align: center;
			color: #1f4d91;
			& > .pam {
				margin-bottom: 0;
				font-weight: 700;
				font-size: 24px;
				background-image: linear-gradient(
					90deg,
					rgba(31, 77, 145, 1),
					rgba(34, 189, 232, 1)
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}

		& > form {
			& > .submit {
				padding: 12px 24px;
				width: 275px;
				height: 48px;
				color: #fff;
				background: #1e4c90;
				border-radius: 76px;
				margin-bottom: 24px;
				font-weight: 700;
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
}

.dont-account {
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	text-align: right;
	color: #5d6166;
	margin-right: 8px;
}

.register-link {
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #1e4c90;
}

.loading{
	max-width: 30px;
}