@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.sort-box {
    .sort {
      border-bottom: 1px solid #000000;
      margin-top: 50px;
    }
    .total-result {
      font-size: 16px;
      font-weight: 600;
      line-height: 16.7px;
      color: #5D6166;
    }
    .status {
      color: $colorNavyBlue;
      font-size: 14px;
      font-family: HelveticaNeueRoman;
      padding-left: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }