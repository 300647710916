@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.nav-link {
	width: 140px;
	height: 45px;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
	border: 1px solid !important;
	border-image-source: linear-gradient(
		269.38deg,
		#1f4d91 0.36%,
		#22bde8 93.54%
	);
	border-radius: 27px;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #1e4c90;
	padding: 11px 0 6px;
	@media screen and (max-width: 767px) {
		margin-right: 15px;
	}
	@media screen and (max-width: 575px) {
		padding: 14px 5px 7px;
		margin-right: 10px;
	}
	@media screen and (max-width: 374px) {
		margin-right: 0px;
	}
	&:hover {
		background: #f3f8ff;
		color: rgba(33, 188, 231, 1);
		border: 1px solid !important;
		border-image-source: linear-gradient(
			275.63deg,
			#22bde8 5.22%,
			#1f4d91 47.74%,
			#22bde8 69.52%,
			#22bde8 89.3%,
			#22bde8 98.68%
		);
	}
}
.nav-link[aria-selected="true"] {
	background: linear-gradient(268.8deg, #204b90 7.35%, #22bde8 109.57%);
	box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
	// background: linear-gradient(100.94deg, #22bde8 -38.22%, #1e4c90 72.52%);
	// box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
	border-radius: 27px;
	color: #fff;
}
