.card {
    margin-top: 24px;
    padding: 39px 75px 39px 45px;
    border-radius: 20px;

    .card-title {
        font-weight: 500;
        font-size: 16px;
        color: #1E4C90;
    }

    .edit-btn-container {        
        text-align: right;       

        .edit-btn {
            border: none;
            font-weight: 400;
            font-size: 14px;
            color: #801D54;
            background-color: transparent;
        }
    }

    .wrapper-experties {
        margin-left: 44px;

        .title {
            font-weight: 400;
            font-size: 16px;
            color: #85898C;
            margin-top: 50px;
        }

        .value {
            margin-left: 50px;
            margin-top: 25px;
            @media only screen and (max-width: 768px){
                margin-left: 10px;
            }

            div {
                padding: 7px 10px 2px;
                border-radius: 20px;
                color: #000;
                font-weight: 400;
                font-size: 14px;
                margin-right: 12px;
                margin-bottom: 12px;
                display: inline-block;
            }

            
        }
        .certificate-btn {
            text-align: center;
            padding: 16px 14px 9px;
            border: 1px solid #1E4C90;
            border-radius: 20px;
            font-weight: 400;
            font-size: 14px;
            max-width: 190px;
            margin-left: 50px;
            color: #1E4C90;
            margin-top: 23px;
            img{
                margin-bottom: 12px;
            }
        }
    }
}