@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";
@import "../../../styles/devicesize.scss";

.banner-section {
  flex: 0 0 !important;
  background: url("../../../assets/images/solution/banner_bg.png");
  background-size: 100% 100%;
  padding-bottom: 50px;

  @media screen and (max-width: 980px) {
    box-shadow: 0 8px 13px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135.66deg, #1E4C90 40.96%, #21BCE7 84.62%);
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    height: 100%;


  }


  & > .banner-container {
    //position: relative;

    & > svg {
      display: block;
      width: 100vw;
      padding: 0;
    }

    & > .title {
      font-family: 'HelveticaNeue';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      //position: absolute;
      max-width: 854px;
      margin: 50px 8px 0;
      height: 20%;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;

      & > h1 {
        color: #ffffff;
        font-weight: 700;
        font-size: 40px;
        line-height: 61px;
        text-align: center;
        @media screen and (max-width: $widthTabletMax) {
          font-weight: 700;
          font-size: 2rem;
          line-height: 41px;
        }
        @media screen and (max-width: $widthMobileMax) {
          font-weight: 700;
          font-size: 1rem;
          line-height: 18px;
        }

        & > span {
          display: block;
          color: #22bde8;
        }
      }

      & > p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        max-width: 700px;
        margin: 0 auto;
        @media screen and (max-width: $widthTabletMax) {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        @media screen and (max-width: $widthMobileMax) {
          font-weight: 700;
          font-size: 9px;
          line-height: 16px;
        }
      }
    }
  }

  & > .link {
    position: relative;
    z-index: 1;
    background: linear-gradient(115.28deg, #21bce7 28.97%, #1e4c90 164.19%);
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 8px #21bce7;
    border-radius: 76px;
    font-weight: 700;
    font-size: 20px;
    padding: 20px 50px;
    color: #ffffff;
    margin-top: -5%;
    @media screen and (max-width: $widthTabletMax) {
      font-weight: 700;
      font-size: 16px;
      padding: 10px 25px;
    }
    @media screen and (max-width: $widthMobileMax) {
      font-weight: 700;
      font-size: 12px;
      padding: 10px 25px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(268.8deg, #204b90 7.35%, #22bde8 109.57%);
      box-shadow: 0px 4px 4px rgba(34, 189, 232, 0.3);
      border-radius: 76px;
      color: #fff;
      opacity: 0;
      transition: opacity 0.4s;
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
    }
  }
}
