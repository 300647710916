.eudramed-card {
  padding:  20px;
  margin-bottom: 20px;
  .company-name {
    font-size: 16px;
    color: #1E4C90;
    font-family: HelveticaNeueMedium;;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .eudramed-date {
    font-size: 12px;
    color: rgba(0, 26, 92, 0.4);
    line-height: 20px;
    padding-top: 5px;
  }
}
