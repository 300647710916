@import "../../../styles/colors.scss";

.form-group {
  display: block;
  padding: 7px 9px 0px 19px;
  margin: 3px 0;

  &.true {
    background: #E8EDF4;
    border-radius: 10px;
  }

  &.false {
    border: none;
  }
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #1E4C90;
  font-family: HelveticaNeue;
  margin-top: 2px;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  transform: translateY(-2px);
}

.form-group input:checked+label:after {
  content: url(../../../assets/images/Icons/Filter-check.svg);
  display: block;
  position: absolute;
  top: -1px;
  left: 5px;
}

.form-group .label-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;  
}
.input-label{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  font-size: 14px;
  color: #85898C;
  font-family: HelveticaNeue;
  font-weight: 400;
  &.true{
    color: #1E4C90;
    font-weight: 500;
  }
}