@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";


.section{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.paymentBanner {
  background-image: url("../../assets/images/paymentResult.jpg");
  background-position: center;
  background-size: cover;
  height: 350px;
}

.congraculations {
  font-size: 36px;
  padding-top: 32px;
  color: #0F9946 !important;
  font-family: "HelveticaNeueBold";
}

.failed {
  font-size: 36px;
  padding-top: 32px;
  color: $colorFailed !important;
  font-family: "HelveticaNeueBold";
}

.congText {
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  color: #343A40;
  font-family: "HelveticaNeueBold";
}

.paymentMessage {
  background: white;
  border: 1px solid #D6D8D9;
  border-radius: 8px;
  margin-top: 24px;
  padding: 24px;
  margin-bottom: 30px;

  & h4 {
    font-weight: bold;
    margin-bottom: 30px;
  }

  & .text {
    font-size: 24px;
    //   border-bottom: 1px solid $colorDarkBlue;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  & .text-link {
    font-size: 14px;
    margin-bottom: 10px;

    & a {
      font-weight: bold;
      // color: $colorNavyBlue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .cancel {
    //   color: $colorred;
    font-size: 14px;

    & b {
      font-weight: bold;
    }
  }
}

.congBtn {
  background-color: $colorDarkBlue !important;
  color: $colorWhite !important;
  border-radius: 76px;
  text-transform: capitalize;
  font-size: 16px;
  padding: 14px 24px 9px;
  margin-top: 80px; 
  border: none !important;
  font-family: "HelveticaNeueMedium";
}