@import "../../styles/fonts.scss";

.card {
    padding: 39px 72px 34px 43px;
    border-radius: 20px;

    .card-title {
        font-weight: 500;
        font-size: 16px;
        color: #1E4C90;
    }

    .edit-btn-container {        
        text-align: right;  
        min-height: 20px;     

        .edit-btn {
            border: none;
            font-weight: 400;
            font-size: 14px;
            color: #801D54;
            background-color: transparent;
        }
    }

    .info-wrapper {
        // margin-left: 44px;

        .company-logo {
            max-width: 147px;
            border-radius: 8px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .view-web {
            background-color: #ffffff;
            display: block;
            border: 2px solid #1E4C90 !important;
            border-radius: 75px;
            color: #1E4C90;
            height: 48px;
            padding: 13px 25px 12px;
            width: 150px;
            margin: 0 auto;
            margin-top: 50px;
        }

        .social-media {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .linkdin {
                margin-right: 10px;
            }

            .twitter {
                margin-left: 10px;
            }
        }

        .title {
            font-weight: 400;
            font-size: 16px;
            color: #85898C;

            &.mt {
                margin-top: 30px;
            }
        }

        .company-name {
            font-weight: 700;
            font-size: 16px;
            color: #000000;
            margin-top: 18px;
        }

        .company-introduction {
            margin-top: 19px;

            & * {
                font-size: 14px !important;
                font-weight: 400;
                color: #000000 !important;
                font-family: 'HelveticaNeue' !important;
            }

            strong {
                font-weight: 600;
            }

            ul {
                list-style-type: revert;
            }
        }

        .company-size {
            font-weight: 400;
            font-size: 14px;
            color: #403F3F;
            margin-top: 19px;
        }

    }

}