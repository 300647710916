@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.my-company {
    padding: 50px 40px 125px;
    .company-logo {
        width: 170px;
        height: 60px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media screen and (max-width: 767px) {
         margin: 0 auto 15px;
        }
      }
      .company-name {
          font-size: 18px;
          font-family: HelveticaNeueBold;
          color: #343A40;
          margin-right: 30px;
          
      }
      .edit-company {
        border: 2px solid #1E4C90;
        font-size: 16px;
        color: #1E4C90;
        border-radius: 48px;
        height: 48px;
        padding: 14px 25px 9px;
        font-family: HelveticaNeueRoman;
        @media screen and (max-width: 767px) {
        margin-top: 15px;
         }
      }
      .company-address {
          font-size: 14px;
          color: #343A40;
          margin-top: 12px;
          font-family: HelveticaNeueRoman;
      }
      .view-profile {
          
          font-size: 16px;
          color: #1E4C90;
          border-radius: 48px;
          height: 48px;
          padding: 12px 25px 0px;
          font-family: HelveticaNeueRoman;
          @media screen and (max-width: 767px) {
          margin-top: 15px;
           }
      }
}
