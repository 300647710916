@import "../../../styles/colors.scss";

.menus {
  width: 100%;
  border: 1px solid $colorDarkBlue;
  top: -2px !important;
  max-height: 150px;
  overflow-y: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate(0, 40px) !important;
  padding: 0;
  .items {
    font-size: 14px;
    color: $colorNavyBlue;
    padding: 10px 6px;
    line-height: 16.7px;
    cursor: pointer;
    white-space: normal;
    &:active {
      background-color: inherit;
    }
    span {
      display: flex;
      transform: translateY(3px);
    }
  }
}
.dropdown {
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  .dropdown-button {
    border: 1px solid #D6D8D9 !important;
    padding: 0.575rem 0.75rem 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    height: 38px;
    color: #85898C;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: #85898C;
    }
    &:after {
      margin-left: 5px;
      color: #85898C;
      border: none;
      content: url(../../../assets/images/arrow/arrow-down-gray.svg);
    }
  }
  .dropdown-button[aria-expanded="true"] {
    &:after {
      transform: rotate(180deg);
      width: 13px;
      height: 13px
    }
  }
}
