.carousel-indicators {
  border: 1px solid #4dd0e1;
  background: white;
  border-radius: 49px;
  width: 92px;
  height: 27px;
  margin: auto;
  margin-bottom: 8px;
  align-items: center;
  & > li {
    display: block;
    margin-bottom: 0px;
    border-radius: 50%;
    border-top: none !important;
    border-bottom: none !important;
    width: 9px !important;
    height: 9px !important;
    background: #204b90 !important;
    transition: all ease 0.6s;
    opacity: 1 !important;
    &.active {
      background-color: aqua !important;
      border-radius: 103px;
      width: 33px !important;
      height: 9px !important;
      transition: all ease 0.6s;
    }
  }
  @media screen and (max-width: 769px) {
    border: none;
    background: none;
    border-radius: 49px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    right: 0px;
    left: auto;
    width: auto;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    &>li{
      width: 10px !important;
      height: 10px !important;
      &.active {
        width: 20px !important;
      }
    }
  }
}
