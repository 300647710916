@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.company-users-card {
  padding: 20px;
  margin-top: 55px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .company-users-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    background-color: #4B70A6;
    font-size: 14px;
    padding: 5px 15px;
    width: 180px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;

    span {
      line-height: 17px;
    }
  }

  .title-border {
    margin: 24px 0;
    .categories-title {     
      font-size: 18px;
      line-height: 22px;      
      color: #343A40;
      font-family: HelveticaNeueBold;
    }
  }

  .approved-users-box {
    max-height: 435px;
    min-height: 75px;
    overflow-y: auto;

    .approved-users-table {
      table-layout: fixed;

      @media screen and (max-width: 767px) {
        table-layout: auto;
      }

      tr {
        border-style: none;
        border-color: $colorWhite;

        &:nth-child(2n+1) {
          background-color: #F5F6F6;
        }

        td {
          font-size: 14px;
          color: #343A40;;
          vertical-align: baseline;
          padding: 0.5rem 0.5rem 0.6rem;
          font-family: HelveticaNeueRoman;
          &:first-child{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          &:last-child{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          &:nth-child(1) {
            width: 3%;
            font-family: HelveticaNeueBold;
          }

          &:nth-child(2) {
            width: 30%;
            font-family: HelveticaNeueBold;
          }

          &:nth-child(3) {
            width: 30%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(4) {
            width: 20%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(5) {
            width: 5%;
          }

          &:nth-child(6) {
            width: 12%;
            //  position: absolute;
            margin-top: 2px;
          }

          .row-number {
            display: block;
            //   transform: translate(0px, 50%);
            line-height: 13px;
          }

          .show-more-btn{
            border: none !important;
          }
          .admin-menu {
            height: 96px;
            width: 207px;
            right: -20px !important;
            margin-left: auto !important;
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border: 1px solid #ececec;

            .close-btn {
              width: 12px;
              cursor: pointer;
              float: right;
            }

            .remove-admin {
              font-size: 14px;
              color: $colorDarkBlue;
              font-family: HelveticaNeueMedium;
              padding: 0 0 10px 0;
              line-height: 17px;
            }

            .delete-user {
              font-size: 14px;
              font-family: HelveticaNeueMedium;
              color: $colorred;
              padding: 0;
            }
          }

          .deactive-company {
            color: $colorred;
            font-size: 14px;
            font-family: HelveticaNeueRoman;
          }
        }
      }
      .image-email{
        transform: translateY(8px);
      }
      .user-picture {
        display: inline-block;
        margin-right: 10px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        // transform: translateY(5px);
      }

      .user-name {
        white-space: nowrap;
        vertical-align: baseline;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 5px;
      }

      .star {
        width: 19px;
        height: auto;
      }
    }
  }

  .request-users-box {
    max-height: 215px;
    overflow-y: auto;

    .request-users-table {
      table-layout: fixed;
      
      @media screen and (max-width: 767px) {
        table-layout: auto;
      }

      tr {
        border-style: none;
        border-color: $colorWhite;

        &:nth-child(2n+1) {
          background-color: #F5F6F6;
        }

        td {
          font-size: 14px;
          color: $colorNavyBlue;
          vertical-align: baseline;
          padding: 0.8rem 0.5rem 0.7rem ;
          color: #343A40;
          font-family: HelveticaNeueRoman;
          &:first-child{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          &:last-child{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          @media screen and (max-width: 767px) {
            vertical-align: middle;
          }

          &:nth-child(1) {
            width: 3%;
          }

          &:nth-child(2) {
            width: 30%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(3) {
            width: 30%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(4) {
            width: 20%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(5) {
            width: 17%;

            @media screen and (max-width: 1199px) {
              width: 30%;
            }
          }

          .row-number {
            display: block;
            line-height: 13px;
          }

          .deny-btn {
            color: $colorred;
            font-size: 14px;
            font-family: HelveticaNeueMedium;
            padding: 0;
          }

          .grant-btn {
            color: #1E4C90;
            font-size: 14px;
            font-family: HelveticaNeueMedium;
            padding: 0;
            margin-left: 20px;

            @media screen and (max-width: 767px) {
              margin-left: 0;
              margin-top: 5px;
              white-space: nowrap;
            }
          }

          .user-name {
            white-space: nowrap;
            vertical-align: baseline;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}