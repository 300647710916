@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";


.main-background{
  width: 100%;
  background-image: url(../../assets/images/static-background.svg);
  background-position: center -180px;
  background-repeat: repeat-y;
}
.news-container {
 // background-image: url(../../assets/images/subtract.svg);
  padding-top: 50px;
  .loading {
    width: 60px;
    height: 36px;
  }
  .latest-news {
    margin-bottom: 90px;
    justify-content: center;
    h4 {
      font-family: HelveticaNeueBold;
      font-size: 24px;
      margin-bottom: 0;
      color: #343A40;
    }
    .explore-All {
      font-size: 16px;
      font-family: HelveticaNeueRoman;
      width: 140px;
      height: 30px;
      display: inline-block;
      background-color: $colorWhite;
      border-radius: 50px;
      padding: 5px 15px;
      color: $colorDarkBlue;
      @media screen and (max-width: 575px) {
       margin-top: 10px;
      }
    }
  }
}
.min-h {
  min-height: 400px;
}