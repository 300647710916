@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  padding: 24px;
  margin-top: 40px;
  .sent-app-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sent-app-image {
      background-size: cover;
      height: 59px;
      width: 168px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 8px;
    }
    .sent-app-header-box {
      float: right;
      font-size: 12px;
      font-weight: 400;
      direction: rtl;
      .sent-app-status {
        font-size: 14px;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 3px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 14px;
        line-height: 14px;
        width: max-content;
      }
      .sent-app-date {
        color: #85898C;
        font-size: 12px;
        margin-top: 8px;
        text-align: center;
      }
    }
  }
  .title {
    font-size: 16px;
    font-family: HelveticaNeueRoman;
    margin-bottom: 10px;
    margin-top: 12px;
    height: 21px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $colorStaticTitle;
  }
  .decription {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    padding-top: 0px;
    overflow: hidden;
    margin: 16px 0px 0px 0px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-box-align: start;
    color: $colorStaticTitle;
    height: 48px;
    position: relative;
  }
  .detail-box {
    border-top: 1px solid #99a3be;
    .show-detail {
        border: 1px solid $colorDarkBlue;
        color: $colorDarkBlue;
        border-radius: 8px;
        width: 100%;
        padding: 7px 10px 0px;
        height: 35px;
        margin-top: 15px;
      }
  }
  .card-info {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #D6D8D9;
    min-height: 90px;
  
    .key-value-box {
     // font-family: HelveticaNeueRoman;
      font-size: 12px;
      margin-bottom: 10px;
      .regions{
        color: #013480;
        background-color: #E4EAF5;
        border-radius: 14px;
        padding: 5px 10px 3px 10px;
        font-weight: 400;
      }
      .key {
        color: #85898C;
      }
      .value {
        color: #343A40;
        font-weight: 600;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .more-value {
        color: rgba(0, 26, 92, 0.4);
      }
    }
  }

}
