@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/devicesize.scss";

.top-categories {
	// background-color: #F5F6F6;
	background: linear-gradient(
			180deg,
			rgba(12, 56, 105, 0.5) 0%,
			rgba(23, 141, 173, 0.5) 100%
		),
		url("../../assets/images/home/category.svg") no-repeat;
	position: relative;
	&::after {
		content: "";
		background: url("../../assets/images/home/category-overlay.svg");
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	background-position: center center;
	background-size: cover;
	padding-top: 69px;
	padding-bottom: 47px;
	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		position: relative;
		z-index: 1000;
		& > p {
			max-width: 800px;
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			color: #fff;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}
		& > a {
			margin-top: 20px;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #1e4c90;
			&:hover {
				color: #22bde8;
			}
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}
	}
	.title {
		font-weight: 700;
		font-size: 32px;
		background: linear-gradient(90deg, #1f4d91 -82.05%, #22bde8 32.2%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		text-align: center;
		@media screen and (max-width: $widthMobileMax) {
			font-size: 20px;
		}
	}

	.categories-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: unset;
		gap: 1%;
		margin: 0 8px;
		& > li {
			width: 15%;
			padding: 0;
		}
		@media screen and (max-width: $widthTabletMax) {
			gap: 1%;
			& > li {
				width: 32%;
				margin-bottom: 16px;
			}
		}
		@media screen and (max-width: $widthMobileMax) {
			gap: 1%;
			& > li {
				width: 49%;
				margin-bottom: 16px;
			}
		}
	}
}
