.dateFilterBox {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }
  
  .dateFilterBox h3 {
    width: 272px;
    font-size: 16px;
    font-weight: 500;
    // margin-bottom: 10px;
    color:#204B90;
    line-height: 19.54px;
  }
  
  .dateItem {
        display: flex;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: #FBF9F9;
    width: 249px;
    color: #801D54;
    font-weight: 500;
    font-size: 12px;
    align-self: center;
    margin-top: 8px;
    padding: 12px 24px 12px 24px;
    border-radius: 76px;
    }

    .selected {
        background-color: #007bff;
        color: #ffffff;
      }

      .showMore {
        font-size: 14px;
        color: #007bff;
        cursor: pointer;
        margin-top: 10px;
        text-align: center;
      
        &:hover {
          text-decoration: underline;
        }
      }