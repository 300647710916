@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";


.step-title {
    color: #1E4C90;
    font-size: 16px;
    font-family: HelveticaNeue;
    font-weight: 700;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description-wrapper {
    padding: 32px 32px 0 32px;

    @media screen and (max-width: 768px) {
        padding-left: 12px;
        padding-right: 12px;
    }

    @media screen and (max-width: 768px) {
        padding-left: 12px;
        padding-right: 12px;
    }

    .description {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.005em;
        font-family: HelveticaNeue;
        color: #801D54;
        font-weight: 400;
    }

    .detail-description {
        margin-top: 13px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        font-family: HelveticaNeue;
        color: #1E4C90;
    }

    .keyservices-wrapper {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .select-category {
        padding: 25px;
        max-height: 50vh;
        overflow-y: scroll;

        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border: 3px solid #fff;
            background-clip: padding-box;
            border-radius: 9999px;
            background-color: rgba(201, 201, 201, 0.3);
        }
    }

    .request-detail-scroll {
        padding-top: 5px;
        margin-top: 33px;
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border: 3px solid #fff;
            background-clip: padding-box;
            border-radius: 9999px;
            background-color: rgba(201, 201, 201, 0.3);
        }
    }

    .request-details-wrapper {
        margin: 0px 28px 0 28px;
    }

    .fileInputButton {
        margin-top: 10px;
    }

    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-next {
            border: none;
            padding: 14px 24px 11px;
            color: white;
            background-color: #1E4C90;
            border-radius: 76px;

            &:disabled {
                background-color: #AEB0B3;
                color: white;
            }
        }
    }

    .submit {
        border-radius: 76px;
        background-color: #1E4C90;
        color: $colorWhite;
        font-family: HelveticaNeueMedium;
        font-size: 16px;
        max-width: 180px;
        width: 100%;
        padding: 6px 0 10px;
        margin-top: 43px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
            max-width: none;
        }

        &:hover {
            color: $colorWhite;
        }
    }

    .draftButton {
        border: 1px solid #1E4C90;
        border-radius: 76px;
        background-color: transparent;
        color: #1E4C90;
        font-family: HelveticaNeueMedium;
        width: 100%;
        padding: 14px 0 9px;
        max-width: 160px;
        margin-top: 43px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
            max-width: none;
        }

        &:hover {
            // color: $colorWhite;
        }
    }

    .back {
        color: #1E4C90;
        font-family: HelveticaNeueMedium;
        padding: 10px 0 5px;

        img {
            margin-right: 16px;
            margin-top: -3px;
        }

        &:hover {
            color: $colorDarkBlue;
        }

        @media screen and (max-width: 575px) {
            width: 125px;
        }
    }

    .last-back {
        margin-top: 46px;
    }
}

.min-h {
    min-height: 400px;
}

.loading {
    width: 50px;
    height: 50px;
    padding: 0;
}

.loading-box {
    text-align: right;
    height: 23px;
    display: inline-block;
    margin-left: 10px;
    padding-top: 7px;

    .loading {
        width: 23px;
        height: 23px;
    }
}