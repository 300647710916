
@import "../../../styles/colors.scss";

.single-input-box {

  min-height: 200px;
  position: relative;
  display: grid;
  .single-input {
    display: none;
    font-family: HelveticaNeueRoman;
    height: 105px;
    min-height: 105px;
    width: 100%;
    border: none;
    border: 1.5px solid #1E4C90;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 16px;
  //  margin-bottom: 35px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: transparent;
    }
  }
  .info {
    display: none;
  }
  .single-input-label,
  .single-input:focus ~ .single-input-label,
  .single-input:not(:placeholder-shown) ~ .single-input-label {
    position: absolute;
    top: -7px;
    font-size: 12px;
    color: #1E4C90;
    background-color: $colorWhite;
    pointer-events: visible;
    cursor: pointer;
    z-index: 10;
    left: 11px;
    .info {
      display: inline-block;
    }
  }

  .single-input:-internal-autofill-selected {
    background-color: $colorWhite;
  }

  .single-input-label {
    // color: #85898C;
    // font-size: 16px;
    // font-weight: normal;
    // position: absolute;
    // pointer-events: none;
    // left: 15px;
    // top: 10px;
    // transition: 300ms ease all;
    // background-color: $colorWhite;
    // height: 16px;
    // padding: 0 5px;

  }
  .single-input-error {
    margin-top: 5px;
    font-size: 10px;
    color: $colorred;
  }
  .word-count-notif{
    font-size: 12px;
    line-height: 20px;   

    .red{
        color: $colorred;
        margin-bottom: 30px;
    }
    .yellow{
        color: $colorYellow1;
        margin-bottom: 30px;
    }
    .green{
        color: $colorGreen;
        margin-bottom: 30px;
    }
  }
}
