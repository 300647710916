@import "../../../../styles/fonts.scss";
@import "../../../../styles/colors.scss";

.slide-one {
  height: 232px;
  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  & > h2 {
    position: relative;
    font-family: "HelveticaNeueMedium";
    padding: 65px 0px 20px 30px;
    font-size: 24px;
    line-height: 29px;
  }
  & > p {
    position: relative;
    font-family: "HelveticaNeueRoman";
    font-size: 16px;
    line-height: 137.99%;
    padding: 0px 28% 0px 30px;
  }
}

.slide-two {
  height: 232px;
  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  & > h2 {
    position: relative;
    font-family: "HelveticaNeueMedium";
    color: $colorNavyBlue;
    padding: 30px 5% 11px 24%;
    font-size: 1.4rem;
    line-height: 29px;
  }
  & > p {
    position: relative;
    font-family: "HelveticaNeueRoman";
    font-size: 0.9rem;
    line-height: 137.99%;
    padding: 0px 5% 0px 24%;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
  & > .button-wrapper {
    position: absolute;
    bottom: 13px;
    right: 1%;
    margin: 0px 5%;
    & > a {
      width: 116px;
      margin-left: 30px;
      background-color: $colorWhite;
      border-radius: 8px;
    }
  }
  @media screen and (max-width: 767px) {
    & > h2 {
      padding: 30px 5% 2px 24%;
    }
  }
}