@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  border: none;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  padding: 50px 20% 10px 20%;

  @media screen and (max-width: 767px) {
    padding: 40px 20px 20px;
  }

  @media screen and (max-width: 575px) {
    padding: 20px 20px 20px;
  }

  margin-top: 50px;
  margin-bottom: 100px;

  .blog-header {
    margin-bottom: 20px;

    .header-title {
      text-align: center;
      padding-right: 10px;
      padding-bottom: 10px;
    }

    .header-title-bottom {
      border-bottom: 1px solid #D9D9D9;
      margin-bottom: 50px;
    }

    .image-wrapper {
      width: 100%;

      .blog-image {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        min-width: 300px;
        min-height: 393px;
        max-width: 715px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: auto;
        @media screen and (max-width: 767px) {
          width: 110px;
          height: 110px;
        }

        @media screen and (max-width: 575px) {
          width: 135px;
          height: 135px;
          margin-bottom: 20px;
        }
      }
    }

    .blog-title {
      font-size: 24px;
      font-family: HelveticaNeueBold;
      margin-bottom: 25px;
      color: #343A40;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .views-col{
      align-content: end;
    }
    .views {
      text-align: left;
    }

    .blog-source {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      font-family: HelveticaNeueMedium;
      color: #21BCE7;
      display: block;
      text-decoration: underline;
    }

    .media-box {
      text-align: right;
      @media screen and (max-width: 991px) {
        border-left: none;
      }

      .media-title {
        font-family: HelveticaNeueRoman;
        color: #85898C;
        font-size: 12px;
      }
    }

    .media-list {
      padding-top: 10px;
      display: inline-block;
      margin-bottom: 0;
      padding-left: 0;

      .item {
        display: inline-block;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #343A40;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .blog-date {
      font-size: 12px;
      font-family: HelveticaNeueMedium;
      color: #85898C;
    }

    .blog-tags {
      color: $colorNavyBlue;
      background: rgba(32, 75, 144, 0.1);
      border-radius: 47px;
      font-size: 12px;
      padding: 6px 19px 4px 19px;
      margin-left: 10px;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  .description {
    white-space: pre-wrap;
    font-size: 16px;
    margin-bottom: 60px;
    font-weight: 500;
    color: #343A40;
    // padding: 50px 20% 10px 20%;
    text-align: justify;
    // border-bottom: 1px solid #99a3be;
    line-height: 25.2px;
  }

  .content {
    white-space: pre-wrap;
    font-size: 16px;
    color: #343A40;
    // padding: 50px 20% 10px 20%;
    text-align: justify;
    // border-bottom: 1px solid #99a3be;
    line-height: 25.2px;
    margin-bottom: 0;
  }

  .blog-footer {
    margin-top: 20px;
    align-items: center;
    font-family: HelveticaNeueRoman;

    @media screen and (max-width: 991px) {
      justify-content: right;
    }

    .key {

      font-size: 12px;
      color: #85898C;
      margin-right: 5px;
    }

    .value {
      font-size: 12px;
      font-weight: 400;
      color: #902F65;
      line-height: 15.7px;
    }


    .feedback {
      display: flex;

      .thank {
        font-size: 14px;
        font-weight: 400;
        margin-left: 10px;
        line-height: 14.7px;
        align-self: center;
        padding-top: 5px;

        @media screen and (max-width: 767px) {
          line-height: 16.7px;
        }
      }

      .question {
        font-family: HelveticaNeueRoman;
        font-size: 14px;
        font-weight: 400;
        color: #343A40;
        line-height: 14.7px;
        padding-top: 3px;
      }

      button {
        line-height: 16.7px;
        padding: 0;
        font-size: 14px;
        font-family: HelveticaNeueMedium;

        &:focus {
          box-shadow: none;
        }
      }

      .yes {
        color: #1E4C90;
        margin-right: 25px;
        margin-left: 25px;
        padding-top: 3px;

        @media screen and (max-width: 374px) {
          margin-left: 0;
        }
      }

      .no {
        color: $colorred;
        padding-top: 3px;
      }
    }

    .blog-source {
      justify-content: right;
      padding-top: 4px;
      display: flex;

      span {
        line-height: 14.7px;
      }
    }
  }
}