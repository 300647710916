@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.section {
  margin-top: 50px;
  margin-bottom: 50px;
  .card {
    background: url(../../../assets/images/login-bg.svg);
    background-position: right;
    background-color: white;
    background-repeat: no-repeat;
    padding: 63px 50px 63px 63px;
    min-height: 600px;
    display: grid;
    align-items: center;
    //---------------------------
    @media screen and (max-width: 1199px) {
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 40px 40px 40px 40px;
      min-height: auto;
    }
    @media screen and (max-width: 991px) {
      text-align: center;
      background: $colorWhite;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
    @media screen and (max-width: 374px) {
      padding: 40px 10px 40px 10px;
    }
    .verifiaction-title {
        color: #343A40;
        font-family: HelveticaNeueBold;
        font-size: 20px;
        margin-bottom: 15px;
    }
    .verification-desc {
      color: #343A40;
        font-size: 14px;
        font-family: HelveticaNeueRoman;
    }
    .submit {
        background: #1E4C90;;
        border-radius: 86px;
        width: 100%;
        color: $colorWhite;
        font-family: HelveticaNeueMedium;
        font-size: 18px;
        padding-bottom: 8px;
        padding-top: 12px;
      }
      .loading-box {
        height: 25px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        .loading {
          margin: 0px auto;
          width: 25px;
          height: 25px;
          display: block;
        }
        
      }
  }

}
