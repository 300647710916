.section-container {
	background: url(../../../assets/images/register-background.svg) no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	& > .form-container {
		width: 793px;
		min-height: 523px;
		margin: 76px auto;
		background: rgba(255, 255, 255, 0.5);
		border-width: 1px 0px 0px 1px;
		border-style: solid;
		border-color: rgba(201, 201, 201, 0.3);
		box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px;

		& > .icon {
			position: relative;
			width: 44px;
			height: 44px;
			padding: 10px 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;

			&::after {
				content: "";
				border-radius: 50px;
				position: absolute;
				inset: 0;
				border: 3px solid transparent;
				background: linear-gradient(140.29deg, #1e4c90 12.27%, #22bde8 86.87%)
					border-box;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box,
					linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
			}
		}

		& > .title {
			font-family: HelveticaNeueBold;
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			color: #1f4d91;
			margin-bottom: 44px;
		}

		& > .desc {
			font-size: 16px;
			color: #343a40;
			margin-bottom: 23px;
			// max-width: 37%;
		}

		& > .counter {
			font-size: 16px;
			letter-spacing: 0.005em;
			color: #343a40;
			margin-bottom: 150px;

			& > .countdown {
				color: rgba(33, 188, 231, 1);
				font-family: HelveticaNeueBold;
			}
		}

		& > .resend {
			display: flex;
			align-items: center;
			gap: 8px;
			font-size: 16px;
			font-family: HelveticaNeueBold;
			margin-bottom: 150px;
			& > span {
				color: #343a40;
			}
			& > .btn {
				color: #1e4c90;
			}
		}

		& > .account {
			& > span {
				color: #343a40;
				font-size: 16px;
				font-family: HelveticaNeueBold;
			}
			& > a {
				color: #1e4c90;
				font-size: 16px;
				font-family: HelveticaNeueBold;
				margin-left: 4px;
			}
		}
	}
}
