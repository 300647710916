@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";


.header-card {
  position: relative;

  .company-logo {
    border-radius: 8px;
    position: absolute;
    top: -110px;
    left: 0;
    width: 288px;
    height: 97px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .company-status {
    font-size: 12px;
    font-weight: 300;
    background-color: #5FBF79;
    border-radius: 15px;
    padding: 7px 9px 5px;
    color: #ffffff;
    position: absolute;
    left: 223px;
    top: -123px;
    line-height: 13.8px;

    span {
      margin-left: 3px;

      @media screen and (max-width: 374px) {
        display: block;
      }

    }
  }
}

.title {
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #99a3be;
  display: flex;
  align-items: center;
  line-height: 16.7px;
}

.card-info {
  padding: 20px 0 5px;
  border-bottom: 1px solid #99a3be;

  .item {
    margin-bottom: 15px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 10px;
    }

    &:nth-child(3) {
      margin-bottom: 10px;
    }

    .key {
      font-size: 14px;
      color: $colorNavyBlue;
      font-weight: 300;
    }

    .value {
      font-size: 14px;
      color: $colorNavyBlue;
      font-weight: 400;
      font-family: HelveticaNeueRoman;
    }

    .region {
      font-size: 14px;
      background: #e9edf4;
      color: $colorNavyBlue;
      padding: 2px 10px 0px;
      border-radius: 47px;
      margin-right: 10px;
      display: inline-block;
      transform: translateY(-1px);
      margin-bottom: 5px;
      font-weight: 400;
      font-family: HelveticaNeueRoman;
    }

    .no-data {
      border-radius: 8px;
      padding: 5px 10px 2px;
    }
  }
}

.social-media {
  padding-top: 10px;

  .list {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin-right: 30px;

      a {
        display: block;

        .log {
          width: 30px;
          height: 30px;
          border-radius: 4px;
        }
      }
    }
  }
}