.section-container {
	background: url(../../../assets/images/register-background.svg) no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	& > .form-container {
		width: 793px;
		min-height: 523px;
		margin: 76px auto;
		background: rgba(255, 255, 255, 0.5);
		border-width: 1px 0px 0px 1px;
		border-style: solid;
		border-color: rgba(201, 201, 201, 0.3);
		box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;

		& > .title {
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			font-family: HelveticaNeueBold;
			color: #1f4d91;
			margin-bottom: 12px;
			margin-top: 25px;
		}

		& > .desc {
			font-size: 16px;
			letter-spacing: 0.005em;
			color: #343a40;
			margin-bottom: 64px;
		}

		& > form {
			min-width: 275px;
			& > .submit {
				width: 100%;
				margin-bottom: 47px;
				background: #1e4c90;
				border-radius: 76px;
				color: #fff;
				padding: 12px 24px 6px;
				font-weight: 500;
				margin-top: 75px;
			}
		}

		& > .link {
			font-size: 16px;
			line-height: 20px;
			color: #5d6166;
			font-family: HelveticaNeueBold;
			display: flex;
			align-items: center;
			gap: 4px;
			& > a {
				color: #1e4c90;
			}
		}
	}
}
