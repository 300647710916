@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";


.solution-button {
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .select-btn {
    &:hover {
      transform: scale(0.9);
    }

    transition-duration: .3s;
    transition-property: transform;
    cursor: pointer;
    margin: 24px;
    padding: 12px;
    padding-top: 16px;
    width: 70%;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    background: linear-gradient(115.28deg, #21BCE7 28.97%, #1E4C90 164.19%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 8px #21BCE7;
    border-radius: 76px;

    a {
      width: 100%;
    }

    &.deselect {
      background-color: #D2DBE9;
    }
  }

  .disable {
    background: #AEB0B3;
    box-shadow: none;
    cursor: auto;
  }
}
