@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";
@import "../../styles/devicesize.scss";

.section-container {
	background: url("../../assets/images/home/latest-letters-background.svg")
		no-repeat;
	background-size: cover;
	// padding: 100px inherit;
	// height: 705px;
	& > div {
		padding-top: 100px;
		padding-bottom: 80px;
		display: flex;
		flex-direction: column;
		gap: 30px;
		align-items: center;
		& > .title {
			font-weight: 700;
			font-size: 32px;
			line-height: 39px;
			background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			font-family: HelveticaNeueBold;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 32px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 20px;
			}
		}
		& > p {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
				text-align: justify;
			}
		}

		& > .tab-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 24px;
			padding: 0;
			margin: 0;
			margin-top: 30px;
			& > .tab-list-item {
				& > button {
					font-size: 20px;
					width: 140px;
					@media screen and (max-width: $widthTabletMax) {
						font-size: 16px;
						width: 100px;
					}
					@media screen and (max-width: $widthMobileMax) {
						font-size: 12px;
						width: 80px;
					}
				}
			}
		}

		& > .loading-box {
			height: 315px;
			display: flex;
			align-items: center;
			.loading {
				margin: 0px auto;
				width: 36px;
				height: 36px;
				display: block;
			}
		}

		& > .explore-link {
			font-family: HelveticaNeueBold;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #1e4c90;
			&:hover {
				color: #22bde8;
			}
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}

		& > .tab-content {
			& > div {
				max-width: 350px;
			}
			& > div > .content-card {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				gap: 40px;
				// width: 352px;
				// height: 338px;
				background: #ffffff;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 20px;
				padding: 30px;
				height: 100%;

				& > .subject {
					color: #204b90;
				}

				& > .company-name {
					color: #902f65;
				}
				& > h2 {
					font-family: HelveticaNeueBold;
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					@media screen and (max-width: $widthTabletMax) {
						font-size: 14px;
					}
					@media screen and (max-width: $widthMobileMax) {
						font-size: 12px;
					}
				}

				& > .date {
					font-weight: 400;
					font-size: 14px;
					color: #85898c;
				}
			}
		}
	}
}
