@import "../../../styles/colors.scss";

.dropdown {
  // margin-bottom: 34px;
}

.dropdown-button {
  border: 1px solid #1E4C90 !important;
  padding: 0.575rem 0.75rem 0.575rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  color: #85898C !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: white;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:active {
    border: 1px solid #1E4C90 !important;
  }

  &:hover {
    border: 1px solid #1E4C90 !important;
  }

  &:focus {
    border: 1px solid #1E4C90;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid #1E4C90;
    color: #85898C;
  }

  &:after {
    margin-left: 5px;
    color: #85898C;
    display: inline-block;
    vertical-align: 0.255em;
    content: url(../../../assets/images/arrow/arrow-down.svg);
  }

  .noselect-label {
    margin-top: 5px;
  }
}

.dropdown-button[aria-expanded="true"] {
  &:after {
    transform: rotate(180deg);
    width: 13px;
    height: 13px
  }
}

.dropdown-list {
  // position: absolute;
  max-height: 500px;
  background: #ffffff;
  overflow: hidden;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  z-index: 1;
  // padding: 38px 0 38px 29px;
  width: 100%;


  @media screen and (max-width: 767px) {
    left: 0;
    right: 0;
    min-width: auto;
  }

  form {
    position: relative;
  }

  .search-box {
    padding: 21px;
    display: flex;
    position: sticky;
    justify-content: space-between;
    top: 0;
    z-index: 9;
    background: $colorWhite;

    @media screen and (max-width: 374px) {
      display: block;
      text-align: center;
    }

    .search-input {
      border: 2px solid #343A40;
      border-radius: 4px;
      height: 41px;
      width: 100%;
      padding-left: 10px;
      padding-top: 5px;
      color: $colorNavyBlue;
      font-size: 14px;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 374px) {
        width: 100%;
      }
    }


  }

  .input-box {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    padding: 7px 20px;

    &:hover {
      background-color: #f8f9fa;
    }

    .checkbox {
      margin-right: 10px;
      margin-top: 2px;
      transform: translateY(2px);
      cursor: pointer;
    }

    .checkbox-label {
      font-size: 14px;
      font-weight: 300;
      color: $colorNavyBlue;
      line-height: 14px;
      cursor: pointer;
      background-color: inherit;

      &:hover {
        background-color: #f8f9fa;
      }
    }

  }

  .category-title-container {
    margin-top: 27px;
    position: relative;

    hr {
      margin: unset;
      opacity: 1;
      position: absolute;
      width: 100%;
      top: 10px;
      color: rgba(120, 148, 188, 0.29);
    }

    .category-title {
      letter-spacing: 0.005em;
      color: #21BCE7;
      font-weight: 700;
      font-size: 14px;
      position: relative;
      background-color: white;
      width: fit-content;
      padding-left: 8px;
      padding-right: 8px;
      margin-left: 40px;
    }
  }

  .key-service-items {
    height: 340px;
    overflow-y: auto !important;

    .key-service-category-wrap {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-flow: wrap;

      @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }

      .key-item {
        width: 50%;
        padding-top: 5px;
        padding-bottom: 5px;
        @media screen and (max-width: 574px) {
          width: 100%;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 21px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 8px solid #fff;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: rgba(201, 201, 201, 0.3);
    }
  }

  .footer {
    height: 85px;
    background-color: white;
    padding: 21px;
    display: flex;
    position: sticky;
    justify-content: space-between;
    bottom: 0;
    z-index: 9;

    @media screen and (max-width: 374px) {
      display: block;
      text-align: center;
    }

    .line {
      position: absolute;
      width: 80%;
      border-top: 1px solid #D6D8D9;
      left: 50%;
      transform: translate(-50%, 0);
      top: 7px;
    }

    .apply {
      width: 20%;
      background: #1E4C90;
      color: $colorWhite;
      border-radius: 23px;
      margin: 0 9px;
      font-size: 14px;
      padding: 0.375rem 0.75rem 0.175rem 0.75rem;

      &:focus {
        box-shadow: none;
      }

      @media screen and (max-width: 374px) {
        margin: 20px 5px 0 0;
        width: 40%;
      }
    }

    .reset {
      text-align: left;
      box-sizing: border-box;
      border-radius: 25px;
      width: 30%;
      color: #1E4C90;
      font-size: 16px;
      padding: 0.375rem 0.75rem 0.175rem 0.75rem;

      &:focus {
        box-shadow: none;
      }

      @media screen and (max-width: 575px) {
        width: 30%;
      }

      @media screen and (max-width: 374px) {
        margin-top: 20px;
        width: 50%;

      }
    }
  }
}

.single-input-error {
  font-size: 10px;
  color: $colorred;
  margin-left: 14px;
}

.life-science-custom-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #5D6166;
  margin-top: 4px;
  margin-left: 14px;
}