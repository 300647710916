@import "../../../styles/colors.scss";

.single-input-box {
	height: 83px;
	position: relative;
	display: grid;
	& > .append {
		position: absolute;
		top: 11px;
		color: rgba(133, 137, 140, 1);
		right: 13px;

		&.password {
			&::before {
				content: "";
				position: absolute;
				width: 23px;
				height: 1px;
				top: 8px;
				left: -2px;
				background-color: rgba(133, 137, 140, 1);
				transform: rotate(45deg);
			}
		}
	}
	.single-input {
		font-family: HelveticaNeueRoman;
		height: 41px;
		width: 100%;
		border: none;
		border: 1.5px solid #1E4C90;
		border-radius: 8px;
		padding-left: 10px;
		padding-top: 4px;
		font-size: 16px;
		//  margin-bottom: 35px;
		&:focus {
			outline: none;
		}
		&::placeholder {
			color: transparent;
		}
	}
	.info {
		display: none;
	}
	.single-input:focus ~ .single-input-label,
	.single-input:not(:placeholder-shown) ~ .single-input-label {
		top: -7px;
		font-size: 12px;
		color: #1E4C90;
		background-color: $colorWhite;
		pointer-events: visible;
		cursor: pointer;
		left: 11px;
		.info {
			display: inline-block;
		}
	}

	.single-input:-internal-autofill-selected {
		background-color: $colorWhite;
	}

	.single-input-label {
		color: #85898c;
		font-size: 16px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		left: 15px;
		top: 10px;
		transition: 300ms ease all;
		background-color: $colorWhite;
		height: 16px;
		padding: 0 5px;
	}
	.single-input-error {
		font-size: 10px;
		color: $colorred;
	}
	.toopltip {
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		border-radius: 4px;
		color: #fff;
		background-color: #369cbb;
		font-family: HelveticaNeueMedium;
		text-align: center;
		width: 500px;
		max-width: 500px;
	}
	.verify-icon {
		color: $colorGreen;
		width: 9px;
		height: 9px;
		position: absolute;
		right: 10px;
		top: 16px;
		background-color: $colorWhite;
	}
}
