@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  padding: 24px;
  margin-top: 40px;

  .draft-box {
    position: relative;
    height: 25px;

    .draft-image {
      background-size: cover;
      height: 45px;
      width: 130px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -20px;
      border-radius: 8px;
    }

    .draft-header-box {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;

      .draft-status {
        font-size: 12px;
      }

      .draft-date {
        color: #85898C;
        margin-bottom: 10px;
        font-size: 12px;
        font-family: HelveticaNeueRoman;

        .date-text {
          @media screen and (max-width: 1300px) and (min-width: 1200px) {
            display: none;
          }

          @media screen and (max-width: 1023px) and (min-width: 992px) {
            display: none;
          }
        }
      }
    }

    .badge {
      border-radius: 2px;
      background-color: $colorred;
      padding: 0px 5px 0px;
      color: white;
      height: 20px;
      right: 0;
      position: absolute;
      top: -22px;
    }
  }

  .title {
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    margin-bottom: 10px;
    padding-bottom: 6px;
    margin-top: 0px;
    height: 27px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #343A40;
  }

  .decription {
    font-family: HelveticaNeueRoman;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-top: 3px;
    overflow: hidden;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    // -webkit-box-align: center;
    color: #343A40;
    margin-bottom: 10px;
    height: 49px;
    position: relative;
    border-bottom: 1px solid #D6D8D9;
  }

  .card-detail {
    font-family: HelveticaNeueMedium;
    font-size: 12px;
    color: #343A40;
    div{
      margin-top: 10px;
    }
    .key {
      font-family: HelveticaNeueRoman;
      font-size: 12px;
      line-height: 16px;
      color: #85898C;
    }

    .value {
      font-family: HelveticaNeueMedium;
      font-size: 12px;
      line-height: 16px;
      color: #343A40;
    }
    .regions{
      .value{
        font-family: HelveticaNeueRoman;
        color: #013480;
        background-color: #E4EAF5;
        border-radius: 14px;
        padding: 5px 10px 3px 10px;
        font-weight: 400;
      }
    }
  }

  .edit-link {
    .show-detail {
      font-size: 16px;
      border: 2px solid #1E4C90 !important;
      color: #1E4C90;
      border-radius: 76px;
      width: 100%;
      padding: 14px 10px 9px;
      font-family: HelveticaNeueMedium;
      margin-top: 30px;
    }
  }

  .delete-btn {
    text-align: center;
    .show-detail {
      font-family: HelveticaNeueMedium;
      color: #E10000;
      border-radius: 8px;
      padding: 7px 10px 0px;
      margin-top: 15px;
      border: none;
    }
  }
}