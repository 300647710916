@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.menu-container {
  background-color: $colorWhite;
  padding: 24px;
  background-color: $colorWhite;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #D6D8D9;
  .user-info {
    display: flex;
    margin-bottom: 10px;
    .user-name {
      font-size: 18px;
      color: #343A40;
      font-family: HelveticaNeueMedium;
    }
    .user-picture {
      width: 64px;
      height: 64px;
      margin-right: 24px;
      margin-bottom: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
    .logout-box {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0;
      margin-top: 10px;
      .logout-txt {
        font-family: HelveticaNeueRoman;
        color: #E10000;
        margin-left: 10px;
        padding-top: 5px;
        font-size: 16px;
      }
    }
  }
  .seprate-line {
    border: 0.5px solid #99a3be;
  }
  .link-list {
    .link {
      color: $colorNavyBlue;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px;
      padding: 0;
      position: relative;
      .icon-box {
        background-color: #E4EAF5;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }
      .badge {
        border-radius: 4px;
        background-color: #8f2e64;
        padding: 0px 5px 0px;
        color: white;
        height: 20px;
        font-family: HelveticaNeueRoman;
      }
      .text {
        line-height: 17px;
        padding-top: 4px;
        font-size: 16px;
        color: #343A40;
        font-family: HelveticaNeueRoman;
      }
    }
  }
}
