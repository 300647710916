@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.card {
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0535948);
    position: relative;
    border-radius: 15px;
    border: 1px solid #21BCE7;
    cursor: pointer;
    padding-top: 13px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
    margin-right: 7px;
    margin-bottom: 19px;

    .cardContext {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .title {
        color: #1E4C90;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 0 auto;
    }
}

.selected {
    background-color: #21BCE7;

    .radio-icon {
        background-color: #21BCE7;
        border: 1px solid white;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -13px;
        left: calc(50% - 10px);
        text-align: center;

        img {
            position: absolute;
            left: 4px;
            top: 6px;
        }
    }

    .title {
        color: white;
    }
}