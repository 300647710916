@import "../../../styles/colors.scss";

.btn-filter {
  font-size: 16px;
  font-family: HelveticaNeueRoman;
  color: #013480;
  padding: 12px 10px 8px;
  line-height: 16px;
  margin-right: 20px;
  border: none;
  background-color: white;
}

.active {
  background: #d2dbe9;
  border-radius: 8px;
}
.filter-title{
  font-size: 16px;
  font-family: HelveticaNeueRoman;
  color: #343A40;
  margin-right: 20px;
}
