@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.card {
  padding: 30px 20px;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  @media screen and (max-width: 576px) {
    padding: 25px 15px;
  }

  .title-border {
    border-bottom: 1px solid $colorGray2;
    position: relative;
    margin: 35px 0;
    .categories-title {
      font-size: 14px;
      color: $colorLightBlue;
      background-color: $colorWhite;
      left: 0;
      top: -11px;
      padding-right: 10px;
      position: absolute;
    }
  }
  .line {
    background-color: $colorGray2;
    width: 100%;
    height: 1px;
  }
  .email-txt {
    font-size: 16px;
    color: #343A40;
    line-height: 21px;
    margin-bottom: 0;
    font-family: HelveticaNeueMedium;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 576px) {
     margin-top: 0;
     }
  }
  .card-email-alert{
    padding: 28px 24px;
    background: #FFF8E6;
    display: flex;
    align-items: center;
    .email-alert-image{
      padding-right: 24px;
    }
  }
  .confirm-btn {
    background: white !important;
    color: #1E4C90;
    border: 2px solid #1E4C90 !important;
    
  }
  .single-input-box {
    height: 70px;
    position: relative;
    display: grid;
    .single-input {
      height: 38px;
      width: 100%;
      border: none;
      border: 1.5px solid #1E4C90;
      border-radius: 8px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 14px;
      //  margin-bottom: 35px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: transparent;
      }
    }
    .info {
      display: none;
    }
    .single-input:focus ~ .single-input-label,
    .single-input:not(:placeholder-shown) ~ .single-input-label {
      top: -7px;
      font-size: 12px;
      color: #343A40;
      background-color: $colorWhite;
      pointer-events: visible;
      cursor: pointer;
      left: 11px;
      .info {
        display: inline-block;
      }
    }

    .single-input:-internal-autofill-selected {
      background-color: $colorWhite;
    }

    .single-input-label {
      color: #85898C;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 10px;
      transition: 300ms ease all;
      background-color: $colorWhite;
      height: 16px;
      padding: 0 5px;
    }
    .single-input-error {
      font-size: 10px;
      color: $colorred;
    }
    .verify-icon {
      color: $colorGreen;
      width: 9px;
      height: 9px;
      position: absolute;
      right: 10px;
      top: 16px;
      background-color: $colorWhite;
    }
  }
  .avatar-box {
    @media screen and (max-width: 576px) {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      }
   
  }
}

.button {
  border-radius: 28px;
  height: 48px;
  font-family: HelveticaNeueMedium;
  font-size: 16px;
  padding: 14px 28px 9px;
}
.back {
  color: $colorDarkBlue;
  border: 1px solid $colorDarkBlue;
  width: 170px;
  margin-top: 30px;
  &:hover {
    color: $colorDarkBlue;
  }
}

.card-margin {
  margin-bottom: 30px;
}
