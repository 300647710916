.card {
    border-radius: 20px;
    margin-top: 48px;

    .header {
        padding: 32px;
        // display: flex;
        // flex-grow: 1;
        // justify-content: space-between;

        .wrapper-title {
            display: flex;
            justify-content: start;

            @media only screen and (max-width: 992px) {
                justify-content: center;
                margin-bottom: 30px;
            }

            align-items: center;

            .title {
                color: #1E4C90;
                font-size: 16px;
                font-weight: 500;
            }

            .view-all {
                margin-left: 23px;
                font-weight: 400;
                font-size: 14px;
                color: #801D54;
                cursor: pointer;
            }
        }

        .wrapper-assets {
            display: flex;
            justify-content: end;

            @media only screen and (max-width: 992px) {
                justify-content: center;
            }

            .asset-wrapper {
                text-align: center;
                margin-left: 50px;

                @media only screen and (max-width: 600px) {
                    margin-left: 20px;
                }

                .asset-count {
                    font-size: 24px;
                    font-weight: 500;
                }

                .asset-title {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    .body {
        .table-requests {
            width: 100%;

            thead tr th {
                border-bottom: 1px solid #D6D8D9;
                font-weight: 400;
                font-size: 14px;
                color: #B4B4B4;
                padding-top: 13px;
                padding-bottom: 13px;
            }

            td,
            th {
                padding-left: 38px;
                text-align: center;
            }

            tr td:last-child,
            tr th:last-child {
                padding-right: 38px;
            }

            td {
                padding-top: 21px;
                padding-bottom: 21px;
                font-weight: 400;
                font-size: 14px;
                color: #343A40;
            }

            tr td:first-child {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 300px;
            }

            tr td:first-child,
            tr th:first-child {
                text-align: left;
            }

            .request-status {
                font-size: 14px;
                text-align: center;
                padding-top: 8px;
                padding-bottom: 3px;
                padding-right: 10px;
                padding-left: 10px;
                border-radius: 14px;
                line-height: 16px;
                width: max-content;
                margin: 0 auto;
            }
            
        }
    }
    .not-found{
        margin: 100px 0 ;
    }
    .footer {
        border-top: 1px solid #D6D8D9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 32px;

        .btn-see-draft {
            font-weight: 400;
            font-size: 14px;
            color: #801D54;
        }


        .btn-create-request {
            background-color: #ffffff ;
            border: 2px solid #1E4C90 !important;
            border-radius: 75px;
            color: #1E4C90;
            width: 210px;
            height: 48px;
            padding: 12px 25px 9px;
        }
    }

}