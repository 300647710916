
@import "../../../styles/fonts.scss";
.container{
    margin-top: 28px;
    display: flex;
    align-items: center;
    .text{
        margin-left: 8px;
        font-family: HelveticaNeueMedium;
        font-size: 16px;
        line-height: 24px;
        .title{
            color: #E10000;
            padding-top: 5px;
        }
        .content{
            color: #001A5C;
            .link{
                color: #902F65;
            }
        }
    }
}