@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.filter-box {
  

  .filter-title {
    font-size: 14px;
    color: #1E4C90;
    font-weight: 500;
    padding: 0.575rem 0 0.175rem;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .filter-btn {
    font-size: 14px;
    width: 100%;
    text-align: left;
    font-family: HelveticaNeueRoman;
    color: #013480;
    border-radius: 8px;
    border: none;
    padding: 0.475rem 0.85rem 0.275rem;

    @media screen and (max-width: 575px) {
      margin: 5px 0;
    }
  }
}

.filter-item {
  padding: 16px 0;
}


.accordion-button {
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #1E4C90 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  height: 25px;

  &:focus-visible {
    border: none !important;
    outline: none;
  }

  .has-selected {
    width: 100%;
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    margin-right: 14px;

    .selected-count {
      position: relative;
      color: #1E4C90;
      padding: 5px 11px 0 7px;
      background: #E8EDF4;
      border-radius: 16px;

      img {
        position: absolute;
        right: -5px;
        top: 5px;
      }
    }

    .filter-name {
      padding-top: 4px;
    }
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:active {
    border: none !important;
  }

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    color: #85898C;
  }

  &:after {
    margin-top: 4px;
    margin-left: 5px;
    color: #85898C;
    display: inline-block;
    vertical-align: 0.255em;
    content: url(../../assets/images/arrow/accordion-arrow.svg);
  }
}

.accordion-button[aria-expanded="true"] {
  &:after {
    transform: rotate(180deg);
    width: 13px;
    height: 13px
  }
}