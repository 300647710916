@import "../../styles/colors.scss";
.slick-dots {
    display: inline-block;
    width: auto;
    position: absolute;
    top: -55px;
    height: 30px;
    right: 115px;
    background-color: #EDFAFC;
    border-radius: 50px;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 1199px) {
        right: 80px;
      }
      @media screen and (max-width: 767px) {
        right: 100px;
       
      }
      @media screen and (max-width: 374px) {
        right: 80px;
      }
}
.slick-dots li {
    height: 24px;
    width: 24px;
}
.slick-dots li button {
    height: 24px;
    width: 24px;
    padding: 0;
}

.slick-dots li button:before {
    position: absolute;
    transform: translate(80%,80%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    width: 9px;
    height: 9px;
    background-color: $colorDarkBlue;
    opacity: 1;
    border-radius: 50%;
}

.slick-dots li.slick-active button:before {
    border-radius: 50px;
    opacity: 1;
    width: 24px;
    transform: translate(0%,80%);
    background-color: $colorLightBlue;
}