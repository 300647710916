@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.selected-items-box {
  // max-height: 120px;
  min-height: 60px;
  transition: all 0.3s ease-in-out;

  overflow: hidden;
  position: relative;
  transition: height 0.15s ease-out;
  transition: height 0.3s;
  .selected-item {
    display: inline-block;
    font-size: 14px;
    color: $colorNavyBlue;
    border-radius: 50px;
    margin-right: 20px;
    margin-bottom: 30px;
    padding: 6px 10px 2px;
  }
}
.opened-box {
  height: auto;
  transition: all 0.3s ease-in-out;
}

.show-more {
  display: block;
  height: 40px;
  padding: 0.385rem 0.75rem 0.15rem;
  color: #1E4C90;
  border-radius: 56px;
  font-family: HelveticaNeueRoman;
  margin: 0 auto 30px;
  border: none !important;
  &:hover {
    color: $colorDarkBlue;
  }
  img {
    margin-left: 5px;
  }
}
