@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  padding: 20px;
  @media screen and (max-width: 991px) {
    margin-bottom: 35px;
  }
  .header-card {
    position: relative;
    
    .company-logo {
      border-radius: 8px;
      
      width: 173px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .company-status {
      font-size: 12px;
      font-weight: 300;
      position: absolute;
      right: 0;
      top: 10px;
      line-height: 13.8px;
      text-align: center;

      span {
        display: block;
        font-size: 12px;
        font-weight: 300;
        border-radius: 14px;
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 3px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 14px;
        line-height: 14px;
        width:fit-content;
        margin: 0 auto;
        &:last-child {
          font-size: 10px;
          padding-top: 0px;
          color: #85898C;;
          font-weight: 400;
          margin-top: 4px;
          @media screen and (max-width: 374px) {
            display: none;
          }
        }
      }
    }
  }
  .title {
    font-size: 18px;
    font-family: HelveticaNeueBold;
    color: #343A40;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    line-height: 18.7px;
    span {
      height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 20px;
    max-height: 48px;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #343A40;
    font-family: HelveticaNeueRoman;
  }
  .card-info {
    padding: 20px 0 0px;
    margin-top: 20px;
    .item {
      margin-bottom: 10px;
      line-height: 20px;
      &:nth-child(2) {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .key {
        font-size: 12px;
        color: #85898C;
       
        font-family: HelveticaNeueRoman;
      }
      .value {
        font-size: 12px;
        color: #001A5C;
        font-weight: 400;
        font-family: HelveticaNeueRoman;
      }
      .region {
        font-size: 12px;
        background: #E4EAF5;
        color: #013480;
        padding: 2px 10px 0px;
        border-radius: 14px;
        margin-right: 10px;
        display: inline-block;
        transform: translateY(-1px);
        margin-bottom: 5px;
        font-weight: 400;
        font-family: HelveticaNeueRoman;
      }
      .no-data {
        border-radius: 8px;
        padding: 5px 10px 2px;
      }
    }
  }
  .buttons {
    text-align: center;
    margin-top: 15px;
    button,
    a {
      padding: 0.55rem 0.75rem 0.25rem 0.75rem;
      color: $colorWhite;
      border-radius: 45px;
      font-size: 16px;
      font-family: HelveticaNeueRoman;
      width: 80%;
      height: 48px;
      line-height: 33px;
      &:focus {
        box-shadow: none;
      }
    }
    .app-remain {
      font-size: 12px;
      display: block;
      margin-top: 10px;
      color: #902F65;
      font-family: HelveticaNeueMedium;
    }

  }
  .show-request-button {
    background-color: #1E4C90;;
  }
  .send-button {
    background-color: #902F65;
  }  
  button:disabled,
  button[disabled]{
  
  background-color: #AEB0B3  !important;
  
}
  .delete-app-button {
    color: $colorred;
    margin-top: 15px;
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    padding: 0;
  }
  .recieved-app {
    font-size: 12px;
    font-family: HelveticaNeueMedium;
    color: #902F65;
    text-align: center;
    margin-top: 15px;
  }
  .request-message {
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
  }  
}
