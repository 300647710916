@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.company-invites-card {
  padding: 24px;
  margin-top: 55px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .company-invites-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    background-color: #4B70A6;
    font-size: 14px;
    padding: 5px 15px;
    width: 180px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;

    span {
      line-height: 17px;
    }
  }

  .invite-txt {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 10px;
    font-family: HelveticaNeueRoman;

    .invite-key {
      color: #902F65;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
      margin-top: 0;
    }
  }

  .no-subscription {
    font-size: 14px;
    color: $colorred;

    .link-to-solution {
      color: $colorDarkBlue;
      font-family: HelveticaNeueRoman;
    }
  }

  .submit {
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 4px;
    background-color: #1E4C90;
    padding: 4px;
    padding-top: 2px !important;
    border: none !important;
    //  padding: 2px 11px;
    height: 32px;

    &:focus {
      box-shadow: none;
    }

    span {
      color: $colorWhite;
      padding-left: 10px;
      padding-right: 10px;
      font-family: HelveticaNeueMedium;
    }
  }

  .seprate-line {
    border-bottom: 1px solid $colorGray2;
    margin-bottom: 30px;
  }

  .invites-table {
    table-layout: fixed;

    @media screen and (max-width: 767px) {
      table-layout: auto;
    }

    tr {
      border-style: none;
      border-color: $colorWhite;

      &:nth-child(2n+1) {
        background-color: #F5F6F6;
      }

      td {
        font-size: 14px;
        color: $colorNavyBlue;
        white-space: nowrap;
        vertical-align: baseline;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #343A40;
        font-family: HelveticaNeueRoman;
        padding: 0.8rem 0.5rem 0.7rem;

        &:first-child{
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:nth-child(1) {
          width: 3%;
          font-family: HelveticaNeueMedium;
        }
        &:nth-child(2) {
          width: 54%;
          font-family: HelveticaNeueMedium;
        }

        &:nth-child(3) {
          width: 17%;
        }

        &:nth-child(4) {
          width: 17%;
        }

        .cencel-btn {
          font-size: 14px;
          font-family: HelveticaNeueMedium;
          color: $colorred;
          padding: 0;
        }
      }
    }
  }
}