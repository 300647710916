@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.wrapper {
  padding: 0;
  .card {
    padding: 24px;
    margin-bottom: 20px;
    background-image: url(../../assets/images/background/request-card-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;

    // height: 262px;
    .request-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .request-image {
        background-size: cover;
        height: 59px;
        width: 168px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 8px;
      }

      .request-header-box {
        float: right;
        font-size: 12px;
        font-weight: 400;

        

        .request-date {
          color: #85898C;
          font-size: 12px;
          margin-top: 8px;
          text-align: center;
        }
      }
    }

    .title {
      font-size: 16px;
      font-family: HelveticaNeue;
      font-weight: 500;
      margin-bottom: 40px;
      margin-top: 12px;
      height: 21px;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000000;
    }
    .title-label{
      color: #85898C;
      font-size: 12px;
      text-align: left;
    }

    .decription {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      padding-top: 0px;
      overflow: hidden;
      margin: 0;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      -webkit-box-align: start;
      color: #343A40;
      height: 48px;
      position: relative;
      text-align: left;
    }

    .card-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding-top: 16px;

      .key-value-box {
        // font-family: HelveticaNeueRoman;
        text-align: left;
        font-size: 12px;    

        .key {
          color: #85898C;
          font-weight: 400;
        }

        .value {
          margin-top: 7px;
          font-size: 14px;
          color: #1E4C90;;
          font-weight: 500;
        }
      }
      .request-status {
        font-size: 14px;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 3px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 14px;
        line-height: 16px;
        width: max-content;
      }
    }
  }
}