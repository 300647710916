

.content-box {
    // padding-left: 220px;
    min-height: 600px;
    @media screen and (max-width: 991px) {
        padding-left: 70px;
       }
       @media screen and (max-width: 767px) {
        padding-left: 0;
       }
}