@import "../../styles//colors.scss";

.selected-items-box {
  //  min-height: 60px;
  transition: all .3s ease-in-out;
  text-align: left;
  overflow: hidden;
  position: relative;
  transition: height 0.15s ease-out;
  animation-duration: 0.3s;
  animation-name: lineInserted;
  transition: height 0.3s;

  .inner-search-input{
    // border: 1px solid red;
    border: none;
    height: 15px;
    width: 100px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
    &:active, &:focus, &:focus-visible, &:visited{
      border: none;
      outline: none;
    }
  }
  .selected-item {
    display: inline-flex;
    font-size: 14px;
    color: white;
    border-radius: 50px;
    margin-right: 24px;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 7px 20px 6px 12px;
    border: 1px solid #21BCE7;
    border-radius: 15px;
    position: relative;

    .selected-item-title {
      padding-top: 3px;
    }

    .remove-selected-item {
      margin-left: 10px;
      cursor: pointer;
      position: absolute;
      right: -10px;
      top: 8px;
    }
  }
}

.opened-box {
  height: auto;
  transition: all .3s ease-in-out;
}

.show-more {
  display: block;
  height: 40px;
  padding: 0.385rem 0.75rem 0.15rem;
  background: none;
  color: #1E4C90;
  border-radius: 56px;
  font-family: HelveticaNeueRoman;
  margin: 0 auto 30px;
  border: none;

  &:hover {
    color: #1E4C90;
    border: none;
  }

  img {
    margin-left: 5px;
  }
}




