@import "../../styles/fonts.scss";

.wrapper {
  border: 1px solid #D6D8D9;
  border-radius: 20px;
  padding: 30px 36px;

  &.second {
    margin-top: 20px;
  }

  .title-file {
    display: flex;
    justify-content: space-between;
  }

  .file {

  }

  .title {
    color: #1E4C90;
    font-size: 16px;
  }

  .description {
    margin-top: 18px;
    color: #343A40;
    letter-spacing: 0.005em;
    font-weight: 400;
    font-size: 14px;

    .application-desc {
      overflow-y: hidden;
    }

    ul {
      list-style: disc;
    }

    & * {
      font-family: 'HelveticaNeue' !important;
    }
  }

  .show-more {
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    color: #4dd0e1;
  }

  .more-less {
    color: #1E4C90;
    display: block;
    margin-top: 50px;
  }

  .details-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .requester-wrapper,
  .duedate-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .duedate-wrapper {
    @media only screen and (max-width: 1200px) {
      margin-top: 20px;
    }
  }

  .company-logo {
    border-radius: 8px;
    width: 116px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-left: 5px;
  }

  .duedate-label,
  .requester-label {
    font-family: 'HelveticaNeue';
    font-weight: 500;
    font-size: 14px;
    color: #85898C;

  }

  .requester-name {
    font-size: 14px;
    color: #343A40;
    margin-left: 10px;
    font-weight: 500;
  }

  .duedate-date {
    color: #1E4C90;
    font-size: 14px;
    margin-left: 10px;
    font-weight: 500;
  }
}

.expertise {
  margin-top: 20px;
}

.requestDescription {
  min-height: 310px;
}

.anon-wrapper {
  h4 {
    font-size: 18px;
    font-weight: 500;
    color: #21BCE7;
    line-height: 17.09px;
    text-align: left;

  }

  p {
    color: #85898C;
    max-width: 500px;
  }
}