@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";
@import "../../styles/devicesize.scss";
@import "../../components/SolutionButton/SolutionButton.module.scss";


.table-section {
  margin-top: 50px;

  table {
    width: 100%;
  }

  .selected {
    border: 3px solid #21BCE7;
    //box-shadow: 0px 0px 4px 3px #B1DEEB;
    //position: relative;

    //&::before {
    //  content: "";
    //  height: 7px;
    //  width: 100%;
    //  position: absolute;
    //  top: -7px;
    //  left: 0;
    //  background: white;
    //  z-index: 2;
    //}
    //
    //&::after {
    //  content: "";
    //  height: 7px;
    //  width: 100%;
    //  position: absolute;
    //  bottom: -7px;
    //  left: 0;
    //  background: white;
    //  z-index: 2;
    //}
  }


  th.selected {
    border-bottom: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: block;
    // margin: 0 -0.5px;

    &::before {
      content: "";
      height: 7px;
      width: 100%;
      position: absolute;
      top: -7px;
      left: 0;
      background: none;
      z-index: 2;
    }

  }

  .last-item {
    padding: 0;
    border-bottom: 3px solid #21BCE7 !important;    
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: block;
    // margin: 0 -1px;

    &::after {
      content: "";
      height: 7px;
      width: 100%;
      position: absolute;
      bottom: -7px;
      left: 0;
      background: none;
      z-index: 2;
    }

  }

  td.selected {
    border-bottom: none;
    border-top: none;
    display: block;
  }

  table {
    margin: 50px auto 50px auto;
    max-width: 1136px;

    tr:nth-child(4) td{
      // background-color: red;
      min-height: 90px;
      // padding-top: 30px;
      &.selected{
        padding-top: 35px;
      }
    }
  }

  tbody {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  th,
  td {
    padding: 25px 30px;
    @media screen and (max-width: $widthTabletMax) {
      padding: 25px 10px;
    }
    @media screen and (max-width: $widthMobileMax) {
      padding: 25px 5px;
    }
  }

  th {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1E4C90;
    @media screen and (max-width: $widthMobileMax) {
      font-size: 15px;

    }
  }

  tbody tr td:first-child {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1E4C90;
    @media screen and (max-width: $widthMobileMax) {
      font-size: 10px;
    }
  }

  tbody tr td:not(:first-child) {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #801D54;
    @media screen and (max-width: $widthMobileMax) {
      font-size: 9px;
    }
  }

  .solution-table-btn {
    @media screen and (max-width: $widthMobileMax) {
      padding: 0px 14px;
      font-size: 9px;
      border-radius: 14px;
      width: 80%;
      margin: 0 0 20px;

    }


  }

  .relevant-label{
    max-width: 150px;
  }
}



