@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.main-background{
    width: 100%;
    background-image: url(../../../assets/images/static-background.svg);
    background-position: center -180px;
    background-repeat: repeat-y;
}

.static-page {
    font-family: "HelveticaNeueBold";
    .title {
        margin-top: 32px;
        h1 {
            font-size: 36px;
            line-height: 44px;
            color: $colorStaticTitle;
            text-align: center;
        }
        h3 {
            font-size: 20px;
            line-height: 24px;
            color: $colorStaticTitle;
            text-align: center;
            margin-bottom: 32px;
        }
    }

    .content {
        margin-bottom: 80px;
        .section {
            .title {
                font-size: 18px;
                line-height: 24px;
                color: $colorStaticTitle;
                margin-bottom: 12px;
                span {
                    color: #902f65;
                }
            }
            .inner-title {
                font-size: 14px;
                line-height: 24px;
                color: $colorStaticTitle;
                margin-bottom: 8px;
                span {
                    color: #902f65;
                }
            }
            .text {
                margin-bottom: 32px;
                font-size: 16px;
                font-family: "HelveticaNeueLight";
                color: $colorStaticTitle;
                span {
                    color: #902f65;
                }
            }
            p {
                color: $colorStaticTitle;
            }
            ol li {
                color: #343a40;
                font-weight: 400;
                font-size: 16px;
                line-height: 35px;
                font-family: "HelveticaNeueLight";
            }
        }
    }
}
