@import "../../../styles/colors.scss";
@import "../../../styles/devicesize.scss";

.footer-section {
	width: 100%;
	background: linear-gradient(
		88.97deg,
		rgba(32, 75, 144, 0.07) -25.13%,
		rgba(204, 249, 255, 0.17) 62.96%,
		rgba(255, 255, 255, 0.17) 105.27%
	);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

	& > .version {
		padding-top: 7px;
		padding-bottom: 3px;
		& > p {
			font-style: normal;
			font-weight: 300;
			font-size: 10px;
			line-height: 12px;
			margin-bottom: 0;
			text-align: center;
			color: #879DBF;
		}
		background: rgba(32, 75, 144, 0.2);
	}

	& > .footer-container {
		margin: auto;
		display: flex;
		align-items: center;
		padding-top: 30px;
		padding-bottom: 30px;
		gap: 10px;
		& > .info {
			display: flex;
			flex-direction: column;
			gap: 21px;

			& > .desc {
				font-family: HelveticaNeueLight;
				font-weight: 300;
				font-size: 10px;
				line-height: 12px;
				color: #204b90;
				margin-bottom: 0;
				max-width: 60%;
			}

			& > .social {
				padding-left: 0;
				display: flex;
				align-items: flex-start;
				gap: 10px;
				margin-bottom: 0;
			}
		}

		& > .links {
			padding-left: 0;
			flex: 1 1 0;
			display: grid;
			grid-template-columns: repeat(4, 25%);
			row-gap: 30px;
			margin-bottom: 0;
			@media screen and (max-width: $widthTabletMax) {
				grid-template-columns: repeat(3, 33%);
			}
			@media screen and (max-width: $widthMobileMax) {
				grid-template-columns: repeat(2, 50%);
			}

			& > li {
				text-align: left;
				& > a {
					font-family: HelveticaNeueBold;
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					color: #204b90;
				}
			}
		}
	}
}
