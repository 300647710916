@import "../../../../../styles/colors.scss";
@import "../../../../../styles/fonts.scss";


      .child-wrp {
        float: right;
        width: 450px;
        padding-left: 10px;
        padding-top: 10px;
        @media screen and (max-width: 992px) {
         display: none;
         }
       
        & .show-all {
          font-family: HelveticaNeueMedium;
          font-size: 14px ;
          font-weight: 500;
          color: $colorDarkBlue ;
      
          & img {
            width: 8px;
            margin-top: -3px;
            margin-left: 3px;
          }
        }
      
        & .item {
          width: 50%;
          float: left;
        }
      
        & .link-item {
          font-size: 12px ;
          font-weight: 300 ;
          color: $colorNavyBlue ;
          &:hover {
            color: $colorLightBlue !important;
          }
        }
      }