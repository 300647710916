@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.welcome-msg {
  display: flex;
  padding : 0 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  & > h3 {
    font-family: "HelveticaNeueMedium";
    padding-left: 20px;
    margin-bottom: 0px;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
