@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";
.section {
  margin-top: 24px;
  margin-bottom: 50px;
  .profile-banner {
    background: url(../../../assets/images/profile-background.jpeg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 236px;
    @media screen and (max-width: 991px) {
      height: 150px;
    }
    @media screen and (max-width: 767px) {
      background: none;
      height: auto;
    }
  }
  .card {
    padding: 25px 45px;
    margin-bottom: 54px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    @media screen and (max-width: 575px) {
      padding: 25px 15px;
    }

    .image-edit{
      padding-left: 30px;
      padding-top: 15px;
      @media only screen and (max-width: 1200px) {
        padding-left: 20px;
      }
    }
    .user-name {      
      font-size: 18px;
      color: #343A40;
      font-family: HelveticaNeueBold;
    }
    .user-email {
      font-size: 16px;
      color: #343A40;
      font-family: HelveticaNeueRoman;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
      .verify-icon {
        color: $colorGreen;
        font-size: small;
      }
    }
  }
  .intersting-card {
    padding-top: 45px;
    @media screen and (max-width: 424px) {
      padding-right: 15px;
      padding-left: 15px;
    }
    .left-0px {
      left: 0;
      @media screen and (max-width: 767px) {
        right: 0;
      }
    }

    .right-0px {
      right: 0;
      @media screen and (max-width: 767px) {
        left: 0;
      }
    }
    .intersting-title {
      font-family: HelveticaNeueMedium;
      position: absolute;
      top: -15px;
      border-radius: 8px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      padding: 5px 15px;
      width: 180px;
      text-align: center;
      line-height: 20px;
      display: flex;
      justify-content: center;
      height: 30px;
      align-items: end;
      background: linear-gradient(90deg, #10497d -43.21%, #4dd0e1 171.6%);
      span {
        line-height: 17px;
      }
    }
  }
  .save-btn {
    background-color: $colorGreen;
    color: $colorWhite;
    border-radius: 56px;
    font-family: HelveticaNeueMedium;
    padding: 8px 15px 4px;
    margin-top: 30px;
  }
  .skip-btn {
    color: $colorDarkBlue;
    font-family: HelveticaNeueMedium;
    padding: 8px 15px 4px;
    margin-top: 30px;
  }
  .categories-title {
    font-size: 14px;
    color: $colorLightBlue;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 25px;
  }
  .title-border {
    background-color: $colorGray2;
    height: 1px;
    width: 100%;
    margin-bottom: 25px;
  }
}
