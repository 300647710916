.card {
    margin-top: 20px;
    
    border: none;
    box-shadow: none;
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
     
    }
}
.warning-letter-filter-wrapper{
  margin-bottom: 14px;
}