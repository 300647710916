@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.step-card {
  background: #FFFFFF;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: rgba(201, 201, 201, 0.3);
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 44px 99px;
}

.section-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1E4C90;
}

.section-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #1E4C90;
  margin-top: 7px;
}

.input-container{
  max-width: 696px;
  margin: 0 auto; 
  padding: 0;   
  padding-top: 26px;
}
.input-container-social{
  padding-top: 38px;
}
@media only screen and (min-width: 1200px) {
  .admin-phone-code{
    padding-right: 0 !important;
    min-width: 132px;
  }
  .admin-phone-container{
    padding-left: 10px !important;
    max-width: 215px;
  }
}

.line{
  border: 1px solid rgba(120, 148, 188, 0.29);
  margin-top: 25px;
  margin-bottom: 38px;
}
.submit {
  border-radius: 28px;
  background-color: $colorDarkBlue;
  color: $colorWhite;
  font-family: HelveticaNeueMedium;
  width: 115px;
  padding: 15px 0 10px;
  margin-top: 27px;
  border: none;

  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 575px) {
    width: 125px;
  }

  &:hover {
    color: $colorWhite;
    background-color: $colorDarkBlue;
  }

  &:focus {
    color: $colorWhite;
    background-color: $colorDarkBlue;
  }
}

.back {
  color: #1E4C90;
  font-family: HelveticaNeueMedium;
  padding: 10px 0 5px;
  margin-top: 27px;
  border: none;
  background-color: transparent;

  img {
    margin-right: 16px;
    margin-top: -3px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 575px) {
    width: 125px;
  }

  &:hover {
    border: none;
  }
}

.btn:hover {
  border: none !important;
}

.social-icon {
  // width: 32px !important;
  // height: 32px;
  // transform: translateY(2px);
  // color: #1c88b4;
  border: none;
}

.btn:hover {
  border: none !important;
}

.btn {
  border: none !important;
}

.nav-link {
  padding: 0;
  margin-right: 20px;
  border: none;

  @media screen and (max-width: 575px) {
    margin-right: 15px;
  }
}

.nav-link[aria-selected="true"] {
  :first-child {
    color: #14c9ce;
  }
}

.icon-border {
  // border: 1px solid #1c88b4;
  // padding: 3px;
  // width: 32px;
  // height: 30px;
  // margin-top: 3px;
  // border-radius: 4px;
  // padding-top: 0px;
}

.icon-border[aria-selected="true"] {
  // border: 1px solid #14c9ce;
  border: none;
}