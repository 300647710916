@import "../../../styles/colors.scss";

.form-group {
  display: block;
  padding: 7px 20px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #1E4C90;
  font-family: HelveticaNeue;
  margin-top: 2px;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #1E4C90;
  border-radius: 3px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  transform: translateY(-2px);
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 9px;
  border: solid #1E4C90;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group .label-wrapper{
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #1E4C90;
  font-family: HelveticaNeue;
}