@import '../../styles/colors.scss';

.title {
  font-weight: 500;
  font-size: 16px;
  color: #1E4C90;
  padding-left: 49px;

}

.radio-button[type="radio"] {
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  border: 1px solid $colorDarkBlue;
  border-radius: 50%;
  outline: none;
  margin-right: 10px;
  transform: translateY(3px);
  display: inline-block;
}

.radio-button[type="radio"]:before {
  content: "";
  display: block;
  width: 65%;
  height: 65%;
  margin: 17% auto;
  border-radius: 50%;
}

.radio-button[type="radio"]:checked:before {
  background: $colorLightBlue;
}


.bill-info {
  padding: 55px 37px 0 125px;
  @media only screen and (max-width: 992px) {
    padding-left: 0;
  }
}

.edit-bill {
  font-weight: 400;
  font-size: 14px;
  color: #801D54;
  padding: 0 30px;
  float: right;
  cursor: pointer;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #21BCE7;
  }
}

.info-wrapper {
  padding-left: 100px;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  margin-top: 25px;

  @media only screen and (max-width: 992px) {
    padding-left: 50px;
  }

  .key {
    color: #85898C;
    min-width: 200px;
  }

  .val {
    color: #1E4C90;
  }
}

.line {
  border-bottom: 1px solid #D6D8D9;
  padding: 42px 0 32px 0;
}