@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {

  padding: 24px;
  margin-bottom: 20px;

  .received-title {
    font-size: 16px;
    font-family: "HelveticaNeueMedium";
    color: #343A40;
  }

  .title-border {
    // border-bottom: 1px solid $colorGray2;
    // position: relative;
     margin: 18px 0 8px 0;

    .categories-title {
      font-size: 18px;
      color: #343A40;
      font-family: "HelveticaNeueBold";
      background-color: $colorWhite;      
    }
  }

  .application-desc {
    font-family: "HelveticaNeueRoman";
    color: #343A40;
    font-size: 14px;
  }

  .more-less {
    color: $colorLightBlue;
    font-size: 14px;
  }

  .seprate-line {
    border: 0.5px solid $colorGray2;
    margin: 20px 0px;
  }

  .attached-file {
    color: #BC82A3;
    font-size: 14px;
    letter-spacing: 0.005em;
  }

  .show-detail {
    font-size: 16px;
    font-family: "HelveticaNeueRoman";
    color: white;
    background-color: #1E4C90;
    padding: 14px 24px 10px;
    border-radius: 76px;
    
  }
}