@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.counter-project {
  background: url("../../assets/images/home/kpi-background.webp") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 99;
  min-height: 410px;
  display: flex;
  align-items: center;
  padding-top: 30px;

  .loading {
    margin: 30px auto;
    width: 36px;
    height: 36px;
    display: block;
  }

  & > div {
    position: relative;
    z-index: 999;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .counter-title {
    color: #FFF;
    font-family: "HelveticaNeue";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 50px;
    text-align: center;
  }
}
