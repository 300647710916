.section {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #343A40;
  }
}

.loading {
  width: 60px;
  height: 36px;
  margin-top: 60px;
}

.min-h {
  min-height: 400px;
}

.mt-50 {
  margin-top: 50px;
}

.title_col {
  @media screen and (max-width: 840px) {
    & {
      width: 50%;
    }
  }

}

.search {
  margin-top: -25px;
  @media screen and (max-width: 840px) {
    & {
      width: 50%;
    }
  }

}