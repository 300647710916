@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.upload-image-box {
  display: grid;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 31px;
  margin-top: 27px;
  .upload-picture {
    display: flex;
    color: $colorDarkBlue;
    font-family: HelveticaNeueMedium;
    align-items: flex-end;
    padding-right: 0;
    padding-left: 0;
    &:hover {
      color: $colorDarkBlue;
    }
    span {
      line-height: 15px;
    }
    .picture {
      margin-right: 10px;
    }
  }
  
  .dv-upload{
    position: relative;
    display: none;
    width: 250px;
    height: 105px;
    padding: 30px 36px;
    border-radius: 8px;
    overflow: hidden;
    background: rgba(30, 76, 144, 0.1);
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .change{
      font-size: 10px;  
      line-height: 12px;    
      color: #1E4C90;
      font-family: HelveticaNeueLight;
      padding-top: 6px;      
      text-align: center;
    }
    &:hover {
      & .change {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .preview-box {
    position: relative;
    display: none;
    width: 250px;
    height: 105px;
    border-radius: 8px;
    overflow: hidden;
    // border: 1px solid #ced3df;
    display: block;
  
    .logo-preview {
      // object-fit: contain;
      height: 100%;
      width: 100%;
    }
  
    &:hover {
      & .change {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    .change {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      color: white;
      font-size: 16px;
      cursor: pointer;
      padding-top: 10px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      text-align: center;
      font-family: HelveticaNeueRoman;
    }
  }
  
  .single-input-error {
    font-size: 10px;
    color: $colorred;
  }
}

