

$colorLightDark: #fcfcfc;
$colorNavyBlue: #001a5c;
$colorDarkBlue: #204b90;
$colorLightBlue: #4dd0e1;
$colorLightBlue2: #e4f8fb;
$colorGray: #e9f3fa;
$colorGray2: #99a3be;
$colorred: #e10000;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorGreen: #2fcf6f;
$colorGreen2: #e0f8e9;
$colorYellow: #f8bd26;
$colorYellow1: #FFB800;
$colorYellow2: #fff4d9;
$colorLightPink: #FFF5F5;
$colorSalmon: #FA7A7A;
$colorRequestBlue: #465f9f;
$colorApplicationBlue: #4e8ebb;
$colorGreen: #2fcf6f;
$colorFailed: #F8384E;
$colorStaticTitle: #343A40;