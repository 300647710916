.back-to-dashboard {
    display: flex;
    justify-content: start;
    align-items: center;

    img {
        margin-top: -3px;
        margin-right: 17px;
    }
}

.card {
    border-radius: 20px;
    margin-top: 30px;

    .title {
        color: #1E4C90;
        font-size: 16px;
        font-weight: 500;
        margin: 32px;
    }
}

.loading-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;

    .loading {
        width: 50px;
    }
}

.not-found {
    margin: 100px 0;
}