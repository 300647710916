@import "../../../styles/colors.scss";

.section {
  padding-top: 50px;
  padding-bottom: 50px;
  .title-card {
    padding: 70px 0;
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: rgba(201, 201, 201, 0.3);
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    .line {
      display: inline;
      height: 138px;
      background-color: #C4C4C4;
      width: 1px;

    }
  }

  .step-card {
    box-shadow: none;
    border: 1px solid #D6D8D9;
    padding: 20px 48px;
  }
}