@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.intersting-card {
  padding: 24px;
  border-top-left-radius: 0;

  @media screen and (max-width: 424px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  .left-0px {
    left: 0;

    @media screen and (max-width: 767px) {
      right: 0;
    }
  }

  .right-0px {
    right: 0;

    @media screen and (max-width: 767px) {
      left: 0;
    }
  }

  .intersting-title {
    font-family: HelveticaNeueMedium;
    position: absolute;
    top: -30px;
    left: -1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    font-size: 14px;
    padding: 5px 5px;
    width: 125px;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: end;
    background-color: #4B70A6;

    span {
      line-height: 17px;
    }
  }

  .warning-letter-title {
    font-size: 16px;
    font-family: HelveticaNeueRoman;
    color: $colorNavyBlue;
    margin-bottom: 40px;
  }

  .selected-warning-letter {
    span {
      display: inline;
      color: #013480;
      font-size: 14px;
      font-family: HelveticaNeueRoman;
      margin-top: 24px;
      margin-right: 24px;
    }
  }

  .title-border {
    border-bottom: 1px solid $colorGray2;
    position: relative;
    margin-bottom: 35px;
    margin-top: 10px;

    .categories-title {
      font-size: 14px;
      color: $colorLightBlue;
      background-color: $colorWhite;
      left: 0;
      top: -10px;
      padding-right: 10px;
      position: absolute;
    }
  }

  .title-section {
    font-family: HelveticaNeueBold;
    font-size: 18px;
    line-height: 22px;
    color: #343A40;
    margin-bottom: 24px;
  }

  .add-remove {
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    border: 2px solid #1E4C90 !important;
    border-radius: 76px;
    padding: 14px 24px 9px;
    color: #1E4C90;

    &:hover {
      color: $colorDarkBlue;
    }
  }
}