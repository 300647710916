


.table-requests {
  margin-top: 20px;
  width: 100%;

  thead tr th {
    border-bottom: 1px solid #D6D8D9;
    font-weight: 400;
    font-size: 14px;
    color: #B4B4B4;
    padding-top: 13px;
    padding-bottom: 13px;
    // cursor: pointer;
    img{
      margin-left: 4px;
    }
  }

  thead tr th button {
    font-weight: 400;
    font-size: 14px;
    color: #B4B4B4;

  }

  td,
  th {
    padding-left: 38px;
    text-align: center;
  }

  tr td:last-child,
  tr th:last-child {
    padding-right: 38px;
  }

  td {
    padding-top: 21px;
    padding-bottom: 21px;
    font-weight: 400;
    font-size: 14px;
    color: #343A40;
  }

  tr td:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
    color: #204B90;
  }

  tr td:first-child,
  tr th:first-child {
    text-align: left;
  }

  .request-status {
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 3px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 14px;
    line-height: 16px;
    width: max-content;
    margin: 0 auto;
  }
}