@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card {
  padding: 24px;
  margin-bottom: 20px;
  min-height: 180px;
  border: none;
  border-bottom: 1px solid #D6D8D9;
  border-radius: 0;
  max-width: 844px;


  .blog-box {
    //position: relative;
    //height: 48px;
    //display: flex;
    .blog-image {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
      background-size: cover;
      height: 257px;
      width: 257px;
      //min-width: 48px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
      @media screen and (max-width: 840px) {
        & {
          height: 200px;
          width: 200px;
        }
      }
      @media screen and (max-width: 760px) {
        & {
          height: 100px;
          width: 100px;
        }
      }
    }

  }

  .title {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    font-family: HelveticaNeueMedium;
    line-height: normal;
    margin-top: 6px;
    //padding-left: 12px;
    //max-width: 85%;
    //height: 40px;
    overflow: hidden;
    color: #343A40;
    @media screen and (max-width: 760px) {
      & {
        font-size: 17px;
      }
    }

    span {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .blog-header-box {
    color: #801D54;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;

    .blog-date {
    }

    .blog-category {

    }
  }

  .decription {
    font-size: 14px;
    padding-top: 8px;
    height: 68px;
    font-family: HelveticaNeueRoman;
    color: #343a40;
    line-height: 20px;
    overflow: hidden;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 500px;
  }

  .blog-details {
    padding-left: 30px;
  }

}
