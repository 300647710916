@import "../../styles/devicesize.scss";
@import "../../styles/colors.scss";

.fast-section {
	& > .fast-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > div {
			padding: 98px 0;
			@media screen and (max-width: $widthMobileMax) {
				padding: 4px 0;
			}
		}
		& > .info {
			@media screen and (max-width: $widthMobileMax) {
				text-align: center;
			}
			& > h2 {
				background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
				font-weight: 700;
				font-size: 40px;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-family: "HelveticaNeueBold";
				text-fill-color: transparent;
				@media screen and (max-width: $widthTabletMax) {
					font-size: 32px;
				}
				@media screen and (max-width: $widthMobileMax) {
					font-size: 20px;
				}
			}

			& > p {
				max-width: 436px;
				font-weight: 500;
				font-size: 24px;
				line-height: 29px;
				text-align: justify;
				color: #1e4c90;
				@media screen and (max-width: $widthTabletMax) {
					font-size: 16px;
				}
				@media screen and (max-width: $widthMobileMax) {
					max-width: unset;
					width: 100%;
					font-size: 12px;
					text-align: center;
				}
			}
			& > a {
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;
				color: $colorNavyBlue;
				margin-top: 20px;
				&:hover {
					color: #22bde8;
				}
				@media screen and (max-width: $widthTabletMax) {
					font-size: 16px;
				}
				@media screen and (max-width: $widthMobileMax) {
					font-size: 12px;
				}
			}
		}

		& > .image {
			position: relative;
			z-index: 999;
			& > img {
				width: 100%;
			}
			@media screen and (max-width: $widthMobileMax) {
				margin: 16px 0;
			}
		}
	}
}
