@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  z-index: 10000;
  overflow: hidden;

  @media screen and (max-width: 575px) {
    width: 90%;
  }
  .header-modal {
    padding: 20px 20px 15px;
    color: $colorred;
    font-family: HelveticaNeueMedium;
    font-size: 16px;
    background-color: #fce5e5;
    @media screen and (max-width: 575px) {
        padding: 20px 10px 15px;
      }
  }
  .modal-message {
    font-size: 14px;
    color: $colorNavyBlue;
    padding: 20px;
    margin: 0;
    font-family: HelveticaNeueRoman;
    @media screen and (max-width: 575px) {
        padding: 10px;
      }
  }
  .close-form {
    padding: 0px 40px 20px;
    @media screen and (max-width: 575px) {
      padding: 0px 10px 20px;
    }
    .radio-button[type="radio"] {
      -webkit-appearance: none;
      width: 17px;
      height: 17px;
      border: 1px solid $colorDarkBlue;
      border-radius: 50%;
      outline: none;
      margin-right: 10px;
      transform: translateY(3px);
      display: inline-block;
    }
    .radio-button[type="radio"]:before {
      content: "";
      display: block;
      width: 65%;
      height: 65%;
      margin: 17% auto;
      border-radius: 50%;
    }
    .radio-button[type="radio"]:checked:before {
      background: $colorLightBlue;
    }
    .buttons-box {
      text-align: right;
      margin-top: 40px;
      .close-req {
        font-size: 18px;
        font-family: HelveticaNeueRoman;
        min-width: 118px;
        padding: 7px 0px 4px;
        margin-right: 20px;
        color: $colorred;
      }
      .cancel {
        border: 1px solid $colorDarkBlue;
        border-radius: 21px;
        color: $colorDarkBlue;
        font-size: 18px;
        min-width: 118px;
        padding: 9px 0px 4px;
        font-family: HelveticaNeueRoman;
      }
    }
  }
}
