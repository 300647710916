@import "../../../../styles/colors.scss";
@import "../../../../styles/fonts.scss";

.invoice-table{
    &>thead>tr>th{
        background-color: #F7FDFE;
        font-family: "HelveticaNeueMedium";
        color: $colorDarkBlue;
        font-size: 14px;
        line-height: 20px;
    }
    &>.invoices-body{
        &>tr{
            font-family: "HelveticaNeueLight";
            font-size: 14px;
            line-height: 17px;
            color: $colorDarkBlue;
            &:nth-of-type(even){
                background-color: #F7FDFE;
            }
        }
    }
}
