@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";
@import "../../styles/devicesize.scss";

.card {
  height: 650px;
  max-width: 331px;
  border-radius: 27px;
  border: 3px solid #EDEDED;
  position: relative;
  box-shadow: 0px 2px 10px 1px rgba(30, 188, 232, 0.22);
  margin: auto;
  @media screen and (max-width: $widthTabletMax) {
    margin: 40px auto;

  }
  @media screen and (max-width: 374px) {
    // height: 485px;
  }

  .sign {
    font-size: 12px;
    position: relative;
    right: 5px;
    bottom: 25px;
    color: #1E4C90;
  }

  .line {
    border: 1px solid #EDEDED;
    margin: auto;
    width: 75%;
  }


  .icon {
    text-align: center;
    position: relative;
    bottom: 30px;
  }

  .titleprice {
    font-family: 'HelveticaNeue';
    min-height: 65px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    color: #000000;

    .month {
      font-family: "HelveticaNeue";
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #7894BC;

    }
  }

  .solution-title {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    bottom: 13px;
    align-items: center;
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
    @media screen and (max-width: 374px) {
      font-size: 14px;
    }

    .off {
      position: absolute;
      right: 5px;
      top: -10px;
      background-color: #fa7a7a;
      border-radius: 2px;
      font-size: 14px;
      color: $colorWhite;
      padding: 4px 3px 0px;
      line-height: 16.7px;
    }

    .title {
      font-family: "HelveticaNeue";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      width: fit-content;
      padding: 0 5px;
      text-align: center;
      background-image: linear-gradient(to top, white 65%, transparent 35%);
      margin: auto;
      color: #1E4C90;

    }

    .cancel {
      position: absolute;
      left: 5%;
      top: 30%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -40%);
      width: 23px;
      height: 23px;
    }
  }

  .solution-box {
    padding: 20px 24px 24px;
    @media screen and (max-width: 374px) {
      padding: 15px;
    }
  }


  .list {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    color: #000000;
    min-height: 173px;

    .item {
      color: #85898C;
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 30px;
      @media screen and (max-width: 575px) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .txt {
        color: #1EBCE8;
        line-height: 16.7px;
        margin: auto 15px auto;
        text-align: left;

      }

      .txt-normal {
        color: #85898C;
        line-height: 16.7px;
        margin: auto 15px auto;
        text-align: left;

      }

      .txt-disable {
        color: #E2E2E2;
        line-height: 16.7px;
        margin: auto 15px auto;
        text-align: left;

      }
    }

  }

  .price {
    font-family: "HelveticaNeue";
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E4C90;


  }

}

.plan-btn-card {
  background: white !important;
  color: #1E4C90 !important;
  font-family: "HelveticaNeue" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border: 1px solid !important;
  box-shadow: 0px 2px 8px 0px #21BCE7 !important;
}

