.section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.loading {
  width: 60px;
  height: 36px;
  margin-top: 60px;
}

.min-h {
  min-height: 400px;
}

.not-found{
  margin-top: 100px;
  margin-bottom: 50px;
}

.search-wrapper{
  max-width: 263px;
  margin-bottom: 21px;
  @media only screen and (max-width: 1200px){
    max-width: 100%;
  }
}
