


.search-input-wrapper{
    border-bottom: 1px solid #1E4C90;
    padding-bottom: 7px;
    position: relative;
    .img-search{

    }
    .search-input{
        font-size: 12px;
        font-weight: 400;
        color: #1E4C90;
        margin-left: 8px;
        border: none;
        &:focus, &:focus-visible{
            border: none;
            outline: none;
        }
        &::placeholder{
            color: #1E4C90;
        }
    }
    .remove-button{
        border: none;
        background-color: transparent;
        position: absolute;
        right: 10px;
        top: 3px;
        & img{
            width: 10px;
        }
    }
}