@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";


.step-title-box {
  // border: 2px solid #C4C4C4;
  color: #C4C4C4;
  font-size: 16px;
  font-family: HelveticaNeueRoman;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;

  // padding: 16px 16px 12px;
  @media screen and (max-width: 991px) {
    width: auto;
  }

  span {
    line-height: 25px;
  }

  .check-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}

.step-item {
  display: flex;
  flex-direction: column;
  .step-index {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 17px;
    border-radius: 50%;
    padding: 8px 10px 4px;
    font-weight: 700;
    &.active {
      background: #FFFFFF;
      border: 3px solid #1E4C90;      
      color: #1E4C90;
    }

    &.pass {
      background: #1E4C90;
      border: 3px solid #1E4C90;
      color: #FFFFFF;
    }

    &.next {
      background: #FFFFFF;
      border: 3px solid #7894BC; 
      color: #7894BC;
    }
  }
  .step-title{
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    &.pass{
      color: #1E4C90;
      font-weight: 700;
    }
  }
}