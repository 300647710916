.section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .min-h {
    min-height: 400px;
  }

  .loading {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }