.section {
  // width: 200px;
  max-width: 300px;
  height: 147px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  align-items: center;
  gap: 8px;
  border: solid 3px #fff;
  border-top: none;
  border-radius: 27px;
  color: #fff;
  position: relative;

  & > .icon {
    position: absolute;
    top: -30px;
    width: 61px;
    height: 61px;
    color: #fff;
    padding: 5px;
    border: solid 2px #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > p {
    font-weight: 700;
    font-size: 36px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0;
  }

  & > h2 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
