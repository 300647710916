@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.card {
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0535948);
    position: relative;
    border-radius: 15px;
    border: 1px solid #21BCE7;
    max-width: 132px;
    margin: 0 auto;
    cursor: pointer;
    padding-top: 17px;
    padding-bottom: 13px;

    .cardContext {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }   

    .title {
        color: #1E4C90;
        font-family: HelveticaNeueMedium;
        font-size: 14px;
        line-height: 16px;
        max-width: 94px;
        text-align: center;
        margin: 0 auto;
    }
}
.selected {    
    background-color: #21BCE7;
    
    .radio-icon{
        background-color: #21BCE7;
        border: 1px solid white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -10px;
        left: calc(50% - 10px);
        img{
            position: absolute;
            left: 4px;
            top: 6px;
        }
    }
    .title{
        color: white;
    }
}
