@import "../../styles/devicesize.scss";

.section-container {
	padding: 90px 0px;
	position: relative;
	& > .section-overlay {
		position: absolute;
		width: 222px;
		height: 200px;
		top: 260px;
		right: 180px;
		z-index: -1;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg width='218' height='162' viewBox='0 0 218 162' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_808_20796)'%3E%3Cpath d='M199.072 44.6709C244.892 107.846 160.089 152.378 122.081 156.165C84.0739 159.952 8.41773 103.756 5.27476 72.2128C2.13179 40.6697 58.674 9.88497 96.6815 6.09789C134.689 2.3108 172.62 8.20123 199.072 44.6709Z' fill='%234C6EA6' fill-opacity='0.2'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_808_20796' x='0.14917' y='0.107666' width='217.163' height='161.239' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='2.5' result='effect1_foregroundBlur_808_20796'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
	}
	&::after {
		content: "";
		position: absolute;
		width: 222px;
		height: 200px;
		top: 220px;
		left: 155px;
		z-index: -1;
		background-image: url("data:image/svg+xml,%3Csvg width='220' height='203' viewBox='0 0 220 203' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_438_24986)'%3E%3Cpath d='M199.688 86.7811C250.492 170.027 171.981 195.357 131.106 199.43C90.2297 203.503 7.42925 128.668 3.33202 87.5477C-0.765207 46.4275 59.478 7.63792 100.354 3.56502C141.23 -0.507866 170.36 38.7251 199.688 86.7811Z' fill='%2322BDE8' fill-opacity='0.07'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_438_24986' x='0.133545' y='0.273926' width='219.119' height='202.315' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_438_24986'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
	}
	&::before {
		content: "";
		position: absolute;
		width: 400px;
		height: 300px;
		top: 20px;
		right: 90px;
		z-index: -1;
		background-image: url("data:image/svg+xml,%3Csvg width='410' height='302' viewBox='0 0 410 302' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_438_24974)'%3E%3Cpath d='M381.15 80.8999C470.335 203.863 305.275 290.538 231.298 297.91C157.321 305.281 10.0646 195.902 3.94718 134.507C-2.17024 73.112 107.883 13.1932 181.86 5.82206C255.837 -1.54906 329.666 9.91597 381.15 80.8999Z' fill='%2322BDE8' fill-opacity='0.07'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_438_24974' x='0.702759' y='0.894531' width='409.219' height='300.369' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_438_24974'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
	}
	& > .items {
		z-index: 10;
		margin: auto;
		max-width: 829px;
		width: 80%;
		height: 339px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 0 0 1px #e1e2e3;
		background: linear-gradient(
			255.83deg,
			rgba(255, 255, 255, 0.3) 3.26%,
			rgba(255, 255, 255, 0) 103.69%
		);
		backdrop-filter: blur(15px);
		-webkit-backdrop-filter: blur(15px);
		filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.05));
		border-radius: 20px;
		padding: 65px 0;
		@media screen and (max-width: $widthTabletMax) {
			width: 80%;
		}
		@media screen and (max-width: $widthMobileMax) {
			width: 94%;
		}
		& > h2 {
			font-weight: 700;
			font-size: 32px;
			font-family: HelveticaNeueBold;
			line-height: 39px;
			background: linear-gradient(90deg, #1f4d91 1.44%, #22bde8 102.87%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
			margin-bottom: 25px;
			@media screen and (max-width: $widthMobileMax) {
				font-size: 20px;
			}
		}

		& > p {
			width: 60%;
			font-size: 20px;
			line-height: 24px;
			color: #1e4c90;
			text-align: center;
			margin-bottom: 29px;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}

		& > a {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #1e4c90;
			font-family: "HelveticaNeueBold";
			@media screen and (max-width: $widthTabletMax) {
				font-size: 16px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-size: 12px;
			}
		}
	}
}
