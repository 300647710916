@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";


.card {
  padding: 30px 20px;
  margin-bottom: 57px;
  border-radius: 20px;
  ;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    padding: 25px 15px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #1E4C90;
      font-size: 16px;
    }

    .edit {
      color: #801D54;
      font-size: 14px;
      font-weight: 400;
    }

  }

  .profile-avatar {
    background: url(../../assets/images/Icons/profile-user.svg);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 576px) {
      margin: 0 auto 20px;
    }
  }

  .edit-avatar {
    padding: 14px 24px 9px;
    border: 2px solid #1E4C90 !important;
    border-radius: 76px;
    font-size: 16px;
    font-family: HelveticaNeueMedium;
    color: #1E4C90;
  }

  .user-info-wrapper {
    max-width: 300px;
    margin-top: 5px;

    .info-title {
      color: #85898C;
      font-size: 16px;
      font-weight: 400;
      margin-top: 38px;
    }

    .user-name {
      font-size: 16px;
      color: #000;
      font-weight: 700;
      font-family: HelveticaNeueBold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .user-email {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #000;
      white-space: nowrap;
      font-family: HelveticaNeueBold;

      .verify-icon {
        color: $colorGreen;
        font-size: small;
      }
    }

    .btn-wrapper {
      margin-top: 120px;
      text-align: center;
      // justify-content: center;
    }
  }


  .line {
    background-color: #99a3be;
    height: 1px;
    margin: 15px 0;
  }

  .company-logo {
    width: 122px;
    height: 46px;
    border-radius: 8px;
    margin-right: 12px;
  }

  .company-name {
    font-size: 14px;
    color: #343A40;
    font-family: HelveticaNeueBold;
  }

  .freelancer-txt {
    font-size: 14px;
    color: #343A40;
    width: 75%;

    @media screen and (max-width: 991px) {
      width: 65%;
    }

    @media screen and (max-width: 767px) {
      width: auto;
      margin-bottom: 15px;
    }
  }

  .pending-txt {
    font-family: HelveticaNeueMedium;
    font-size: 14px;
    color: #ffc634;
    margin-left: 13px;
  }

  .join-create {
    font-family: HelveticaNeueMedium;
    font-size: 14px;
    color: $colorDarkBlue;
    width: 20%;
    text-align: right;

    @media screen and (max-width: 1199px) {
      width: 25%;
    }

    @media screen and (max-width: 991px) {
      width: 35%;
    }

    @media screen and (max-width: 767px) {
      width: auto;
    }
  }

  .revoke-company {
    text-align: right;

    button {
      text-align: right;
      padding: 0;
      font-family: HelveticaNeueMedium;
      font-size: 14px;
      color: $colorred;
    }

    @media screen and (max-width: 991px) {
      width: 45%;
    }

    @media screen and (max-width: 767px) {
      width: auto;
      margin-top: 20px;
    }
  }
}