@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.upload-btn {
  border: none;
  background-color: #E8EDF4;
  border-radius: 12px;
  color: $colorDarkBlue;
  font-family: HelveticaNeueMedium; 
  width: 100%;
  font-size: 16px;
  color: #1E4C90;
  padding: 15px 20px;
  min-height: 96px;
  .attach-description{
    font-family: HelveticaNeueRoman; 
    color: #343A40;
    font-size: 14px;
    padding-top: 5px;
    
  }
  .picture {
    margin-right: 8px;
  }
  &:hover {
  
  }
}

.uploaded-file {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #E8EDF4;
  border-radius: 12px;
  color: $colorDarkBlue;
  font-family: HelveticaNeueMedium; 
  width: 100%;
  font-size: 16px;
  color: #1E4C90;
  min-height: 96px;
  text-align: center;
  .uploaded-file-item{
    position: relative;
    display: flex;
    font-family: HelveticaNeueRoman;
    border: 1px solid #21BCE7;
    border-radius: 15px;
    margin-left: 10px;
    color: #1E4C90;
    font-size: 14px;
    padding: 8px 24px 8px 12px;
    .uploaded-file-name{
      padding-top: 3px;
    }
  }
  @media screen and (max-width: 374px) {
    padding: 15px;
  }
  .delete-box {
    display: inline-block;
    position: absolute;
    right: -10px;
    top: 9px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.single-input-error {
  font-size: 10px;
  color: $colorred;
  display: block;
  margin-top: 5px;
}
