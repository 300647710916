@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.section {
  padding: 50px 0;
  background: url(../../../assets/images/background/join-company-bg.svg) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;


  .card {
    background: rgba(255, 255, 255, 0.3);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: rgba(201, 201, 201, 0.3);
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 20px;
    padding: 63px 30px 63px 30px;
    min-height: 530px;
    display: grid;
    // align-items: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 1199px) {
      background-size: auto;
      border-radius: 8px;
    }

    @media screen and (max-width: 991px) {
      background: $colorWhite;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 30px;
    }

    @media screen and (max-width: 575px) {
      padding: 15px;
    }

    .title {
      font-size: 18px;
      color: $colorNavyBlue;
      font-weight: 700;
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 60px;
    }

    .text {
      font-size: 14px;
      color: rgba(0, 26, 92, 0.58);
    }

    .join-box {
      padding: 20px;
      margin-top: 50px;
    }

    .join-form {
      margin-top: 25px;

      .apply {
        background: $colorDarkBlue;
        border-radius: 76px;
        width: 100%;
        color: $colorWhite;
        font-family: HelveticaNeueMedium;
        font-size: 16px;
        padding-bottom: 1px;
        height: 48px;
      }

      .loading {
        width: 25px;
        margin-top: 5px;
        margin: 3px auto;
        display: block;
      }
    }

    .back-box {
      margin-top: 20px;

      .back-link {
        color: $colorDarkBlue;
        font-size: 18px;
        line-height: 16px;
        margin: 0;
        padding: 0;
        font-family: HelveticaNeueMedium;

        &:hover {
          color: $colorDarkBlue;
        }
      }
    }
  }
}