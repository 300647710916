.section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.min-h {
  min-height: 400px;
}

.loading {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not-found {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.page-title {
  font-family: HelveticaNeueBold; 
  font-size: 24px;
  line-height: 29px;
  color: #343A40;
  margin-bottom: 24px;
}