@import "../../styles/devicesize.scss";

.world-section {
  background: radial-gradient(267.43% 87.45% at 73.51% 25.39%, rgba(34, 189, 232, 0.10) 0%, rgba(30, 76, 144, 0.10) 100%);
  // margin-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  // padding: 120px 0;
  // @media screen and (max-width: $widthMobileMax) {
  // 	padding: 60px 0;
  // }
  padding: 40px 0;
  @media screen and (max-width: $widthMobileMax) {
    padding: 20px 0;
  }


  & > .world-container {
    background: url("../../assets/images/home/helpful-bg.svg");
    background-repeat: no-repeat;
    padding: 80px 0;
    background-position: 0 50%;
    
    @media screen and (max-width: $widthMobileMax) {
      padding: 40px 0;
    }
    @media screen and (max-width: 1200px) {
      background-size: 200px;
      
    }

    & .world-container-title {
      @media screen and (max-width: 840px) {
        padding: 0;
      }

      & h2 {
        color: #1F4D91;
        font-family: "HelveticaNeue";
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transform: translate(182px, 138px);
        @media screen and (max-width: 1400px) {
          transform: translate(182px, 138px);
        }
        @media screen and (max-width: 1200px) {
          transform: translate(68px, 160PX);
          font-size: 28px;
        }
        @media screen and (max-width: 980px) {
          transform: translate(0px, 160px);
          font-size: 25px;
        }
        @media screen and (max-width: 770px) {
          transform: translate(0, 0);
          text-align: center;
        }
      }
    }

    & > p {
      width: 60%;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      color: #1f4d91;
      @media screen and (max-width: $widthTabletMax) {
        font-size: 16px;
      }
      @media screen and (max-width: $widthMobileMax) {
        font-size: 12px;
        text-align: justify;
      }
    }
  }

}

.world-container-content-item {
  &:hover {
    transform: scale(1.04);
    cursor: pointer;
  }

  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #21BCE7;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  width: 253px;
  height: 170px;
  padding: 20px;
  text-align: center;
  margin: 20px;


  & h3 {
    font-family: "HelveticaNeue";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #1F4D91 1.44%, #22BDE8 102.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & p {
    text-align: center;
    margin-bottom: 0;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(90deg, #1F4D91 1.44%, #22BDE8 102.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 980px) {
    margin: 10px;
    width: 233px;
    height: 150px;
    & h3 {
      font-size: 18px;
    }
    & p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 550px) {
    width: 180px;
    height: 140px;

    & h3 {
      font-size: 14px;
    }
    & p {
      font-size: 11px;
      line-height: 15px;
    }
  }

}

.world-container-content-item-left {
  float: right;
}
