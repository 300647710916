@import "../../styles/colors.scss";


.single-input-box {
  height: 70px;
  position: relative;

  .single-input {
    height: 41px;
    width: 100%;
    border: none;
    border: 1.5px solid #1E4C90;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 4px;
    font-size: 16px;

    //  margin-bottom: 35px;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: transparent;
    }
  }

  .info {
    display: none;
  }

  .single-input:focus~.single-input-label,
  .single-input:not(:placeholder-shown)~.single-input-label {
    top: -7px;
    font-size: 12px;
    color: $colorDarkBlue;
    background-color: $colorWhite;
    pointer-events: visible;
    cursor: pointer;
    left: 11px;

    .info {
      display: inline-block;
    }
  }

  .single-input:-internal-autofill-selected {
    background-color: $colorWhite;
  }

  .single-input-label {
    color: #85898c;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 10px;
    transition: 300ms ease all;
    background-color: $colorWhite;
    height: 16px;
    padding: 0 5px;
  }
}

.result-box {
  border-radius: 4px;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  text-align: left;

  .loading {
    margin: 30px auto;
    width: 36px;
    height: 36px;
    display: block;
  }

  .result-list {
    padding: 0;
    margin: 0;

    .result-item {
      padding: 10px 15px;
      cursor: pointer;
      line-height: 18px;

      &:hover {
        background-color: #e9ecef;
      }
    }
  }
}