@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.picture{
  float: right;
  margin-top: -33px;
  margin-right: 10px;
}

.single-input-error {
  font-size: 10px;
  color: $colorred;
  display: block;
  margin-top: 5px;
}
