@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";


.main-background{
  width: 100%;
  background-image: url(../../assets/images/static-background.svg);
  background-position: center -180px;
  background-repeat: repeat-y;
}
.blog-container {
 // background-image: url(../../assets/images/subtract.svg);
  padding-top: 50px;
  .loading {
    width: 60px;
    height: 36px;
  }
}
.min-h {
  min-height: 400px;
}